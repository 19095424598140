import { API_KEY_AND_CALENDAR_ID } from '../actions/types';

const initalValues = {
    apiKey: '',
    clientId: '',
    isSave: false
};

const apiKeyAndClientId = (state = initalValues, action) => {
    switch(action.type) {
        case API_KEY_AND_CALENDAR_ID:
            return action.payload;
        default:
            return state;
    }
};

export default apiKeyAndClientId;
