import { CONTACT_REQUEST_LOADING } from '../actions/types';

const crLoading = (state = false, action) => {
    switch (action.type) {
        case CONTACT_REQUEST_LOADING:
            return action.payload;
        default:
            return state;
    }
};

export default crLoading;
