import { STATISTICS_CUSTOMER_FILTER_BUTTONS } from '../actions/types';

const initialState = {
  twelveMonth: true,
  quarterFirst: true,
  quarterSecond: true,
  quarterThird: true,
  quarterFouth: true,
  daysSeven: true,
  daysOne: true,
  n1: true,
  n2: true,
  n3: true,
  inactiveOne: true,
  inactiveThree: true,
  inactiveSix: true,
  inactiveTwelve: true,
};

const statisticsCustomerFilterButtons = (state = initialState, action) => {
  switch(action.type) {
    case STATISTICS_CUSTOMER_FILTER_BUTTONS:
      return action.payload;
    default:
      return state;
  }
};

export default statisticsCustomerFilterButtons;
