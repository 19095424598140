import { CA_GET_CUSTOMER_BY_ID } from '../actions/types';


const caGetCustomerById = (state = {}, action) => {
  switch(action.type) {
    case CA_GET_CUSTOMER_BY_ID:
      return action.payload;
    default:
      return state;
  }
};


export default caGetCustomerById;
