import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
    selectedRow: {
        cursor: 'pointer',
    },
    dialogTitle: {
        textAlign: 'center',
    },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function ActivityFlowShowSelected(props) {
    const classes = useStyles();
    const { item } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <TableRow className={classes.selectedRow} onClick={handleClickOpen}>
                    <TableCell>{item.invoice}</TableCell>
                    <TableCell>{item.customer}</TableCell>
                    <TableCell>{item.time}</TableCell>
                </TableRow>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Selected Activity
                </DialogTitle>
                <DialogContent dividers>
                <Typography
                    className={classes.dialogTitle}
                    variant="h5"
                    gutterBottom
                >
                    {item.invoice}
                </Typography>
                <Typography gutterBottom>
                    <strong>Type: </strong>GENERAL<br />
                    <strong>Request ID: </strong>11-0000000308<br /><br />
                    <strong>Contact: </strong>{item.customer}<br />
                    <strong>Contact phone number: </strong>7856962030<br /><br />
                    <strong>Description: </strong>Hi there,<br /><br />
                    My name is Viktor and I’m with Parcl.com, an international package forwarding community. Your shipping services are popular with a lot of users and I believe we could work together to everyone’s benefit.
                    <br /><br />
                    Want more customers? Parcl gets a lot of delivery requests from Switzerland and we would be happy to see you sign up as a forwarder and start fulfilling some of those requests. You can choose how many orders you want to take and set your own price for your services. The only condition is to process those requests on Parcl without redirecting our shoppers to your website.
                    <br /><br />
                    Sounds interesting? I’ll be happy to answer your questions or walk you through the process anytime.
                </Typography>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    DONE
                </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default ActivityFlowShowSelected;
