import { INVOICE_CREATE_SUCCESS } from '../actions/types';


const invoiceCreateSuccess = (state = false, action) => {
  switch(action.type) {
    case INVOICE_CREATE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};


export default invoiceCreateSuccess;
