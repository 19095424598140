import React, { useContext, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import useToken from '../login/useToken';
import { URL_All } from '../common/Constants';
import { getDataWithAuth } from '../common/Frame';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { CustomerAccountContext } from '../contexts/CustomerAccountContext';


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(0),
    },
    title: {
        marginLeft: '24px !important',
        marginTop: '24px !important',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(0),
      color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    dialogActions: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    addButton: {
        '&:hover': {
            color: '#3bd73b',
        },
        padding: theme.spacing(0),
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textItalic: {
        fontStyle: 'italic !important',
    },
    nativeSelect: {
        marginTop: theme.spacing(2),
        fontStyle: 'italic',
        '& option': {
            fontStyle: 'normal',
        },
    },
    grid: {
        alignSelf: 'flex-end',
    },
    gridAdjustment: {
        alignSelf: 'flex-end',
    },
    textRed: {
        color: 'red',
    },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography className={classes.title} variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

export default function AdjustmentFormAdd(props) {
    const classes = useStyles();
    const { token } = useToken();
    const [open, setOpen] = useState(false);
    const [adjustment, setAdjustment] = useState('');
    const [transporters, setTransporters] = useState([]);
    const [selectedTransporter, setSelectedTransporter] = useState(-1);
    const [ifNaN, setIfNaN] = useState(false);
    const { existCompanies } = useContext(CustomerAccountContext);

    function handleClickOpen() {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    function listTransporters() {
        getDataWithAuth(URL_All.transporters, token)
            .then(json => setTransporters(json))
            .catch(err => console.error(err))
    }

    const errorNaN = (<FormHelperText className={classes.textRed}>
        Numbers Only
    </FormHelperText>);

    const errorMax = (<FormHelperText className={classes.textRed}>
        Can NOT be more than 100.00
    </FormHelperText>);

    const errorMin = (<FormHelperText className={classes.textRed}>
        Can NOT be less than 0.01
    </FormHelperText>);
    
    const errorDecimal = (<FormHelperText className={classes.textRed}>
        Fractional part can NOT be more than 2 digits
    </FormHelperText>);

    return(
    <div>
        <Tooltip title="Add">
            <IconButton
                aria-label="add"
                className={classes.addButton}
                onClick={() => {
                    handleClickOpen();
                    listTransporters();
                }}
            >
                <AddCircleOutlineRoundedIcon />
            </IconButton>
        </Tooltip>
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="form-dialog-title"
            className={classes.root}
        >
            <DialogTitle id="form-dialog-title" onClose={handleClose} >
                New Adjustment:
            </DialogTitle>
            <DialogContent>
                <form id="formAdjustmentAdd" onSubmit={(e) => {
                    e.preventDefault();
                    (adjustment.trim() !== '' && selectedTransporter !== -1) ? props.addAdjustment(selectedTransporter, parseFloat(adjustment)/100) : handleClose();
                    setAdjustment('');
                    setSelectedTransporter(-1);
                }}>
                    <Grid container spacing={2} className={classes.grid}>
                        <Grid item xs={7}>
                            <FormControl className={classes.formControl}>
                                <NativeSelect
                                    className={selectedTransporter === -1 ? classes.nativeSelect : classes.selectEmpty}
                                    value={selectedTransporter}
                                    onChange={(e) => setSelectedTransporter(parseInt(e.target.value))}
                                    margin="dense"
                                    inputProps={{ 'aria-label': 'age' }}
                                >
                                    <option className={classes.textItalic} value="-1" disabled>
                                        Select Transporter
                                    </option>
                                    {transporters.filter(tr => (!existCompanies.includes(tr.id))).map(transporter => (
                                        <option value={transporter.id} key={transporter.id}>{transporter.name}</option>
                                    ))}
                                </NativeSelect>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5} className={classes.gridAdjustment}>
                            {ifNaN ? errorNaN
                                : (adjustment === '') ? null
                                : (parseFloat(adjustment) > 100) ? errorMax
                                : (parseFloat(adjustment) <= 0) ? errorMin
                                : (((parseFloat(adjustment) * 100) % 1) !== 0) ? errorDecimal
                            : null}
                            <TextField
                                className={classes.textField}
                                autoFocus
                                autoComplete="off"
                                margin="dense"
                                id="new-comment-input"
                                type="text"
                                fullWidth
                                error={ifNaN
                                    || (adjustment.trim() === '')
                                    || (parseFloat(adjustment) > 100)
                                    || (parseFloat(adjustment) <= 0)
                                    || (((parseFloat(adjustment) * 100) % 1) !== 0)
                                }
                                value={adjustment}
                                onChange={(e) => {
                                    setAdjustment(e.target.value);
                                    if(isNaN(parseFloat(e.target.value))) {
                                        setIfNaN(true);
                                    } else {
                                        setIfNaN(false);
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        %
                                    </InputAdornment>),
                                }}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    color="primary"
                    type="submit"
                    form="formAdjustmentAdd" 
                    onClick={handleClose}
                    disabled={ifNaN
                        || (adjustment.trim() === '')
                        || (selectedTransporter === -1)
                        || (parseFloat(adjustment) > 100)
                        || (parseFloat(adjustment) <= 0)
                        || (((parseFloat(adjustment) * 100) % 1) !== 0)
                    }
                >
                    Enregistrer
                </Button>
            </DialogActions>
      </Dialog>
    </div>
    );
}
