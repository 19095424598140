import { SNACKBAR } from '../actions/types';


const initialValues = {
  open: false,
  severity: 1,
};

const snackbar = (state = initialValues, action) => {
  switch(action.type) {
    case SNACKBAR:
      return action.payload;
    default:
      return state;
  }
};


export default snackbar;
