import React from 'react'; // , { useState }
import { useLocation } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import { urlExpedismart } from '../common/Constants';
import useToken from '../login/useToken';
import { logOut } from '../common/Frame';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 100,
        background: '#F7F7F7',
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(13),
        '& h1': {               // delete this later (h1)
            color: '#ccc',
            fontSize: theme.spacing(7),
            margin: theme.spacing(1),
        },
    },
    headRightSide: {
        textAlign: 'right',
    },
    search: {
        width: 200,
        "& .MuiIconButton-root": {
            padding: 0,
            color: "gray",
        },
    },
}));

export default function ContactsHeader(props) {
    const classes = useStyles();
    const { setContacts, setIsLoading, searchInput, setSearchInput } = props;
    const { token } = useToken();
    // const [searchInput, setSearchInput] = useState('');
    const location = useLocation();
     
    function searchContacts(input) {
        if(!token) return logOut();
      
        // fetch(`${urlExpedismart}/api/customers/?q=${input}`, {
        fetch(`${urlExpedismart}/api/customers/?q=`, {
            headers: {
                'authorization': `JWT ${token}`
            }
        })
            .then(response => {
                if (response.status === 403) {
                return logOut();
                } else if (response.status === 200) {
                return response.json();
                }
            })
            .then(json => {
                // fitlering if users array is not empty
                // setContacts(json.filter(f => (f.users.length > 0)));
                // setContacts(json.filter(t => (t.company_name !== null)).filter(t => (t.company_name !== '')).filter(f => (f.users.length > 0)));
                setContacts(json.filter(f => (f.id === 1821 || f.id === 2329)));
                setIsLoading(false);
            })
            .catch(error => console.error(error))
    }

    // useEffect(() => {
        // return searchContacts('');
    // }, [searchContacts]);

    return (<Paper className={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={9} container spacing={1}>
                <h1>Contacts</h1>
            </Grid>
            <Grid item xs={3} container className={classes.headRightSide} spacing={1}>
                <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                        <TextField 
                            size="small" 
                            id="outlined-basic" 
                            label="Rechercher…" 
                            variant="outlined" 
                            className={classes.search}
                            helperText='Please click "Enter" to search.'
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    ev.preventDefault();
                                    setContacts([]);
                                    searchContacts(searchInput.trim());
                                    // searchInput.trim() !== '' ? searchContacts(searchInput) : setContacts([]);
                                    setIsLoading(true);
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                    {searchInput.length > 0 ? (<IconButton onClick={() => {
                                        setContacts([]);
                                        searchContacts('');
                                        setSearchInput('');
                                        setIsLoading(true);
                                    }}>
                                        <HighlightOffIcon size="small" />
                                    </IconButton>) : ''}
                                    </InputAdornment>
                                ),
                            }}
                            value={searchInput}
                            onChange={e => {
                                setSearchInput(e.target.value);
                            }}
                        />
                    </form>
                </Grid>
            </Grid>
        </Grid>
    </Paper>);
}
