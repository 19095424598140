// Convert date (2021-04-16) to French Month 
export function getMonthFrench(date) {
  const d = new Date(date);
  const n = getMonthFrenchByNumber(d.getMonth());
  return n;
}

// Conver numbers [0, 1, ... , 11] to French Months
export function getMonthFrenchByNumber(num) {
  let month = [];
  month[0] = "janvier";
  month[1] = "février";
  month[2] = "mars";
  month[3] = "avril";
  month[4] = "mai";
  month[5] = "juin";
  month[6] = "juillet";
  month[7] = "août";
  month[8] = "septembre";
  month[9] = "octobre";
  month[10] = "novembre";
  month[11] = "décembre";

  return month[num];
}

// Convert date (2021-04-16) to French Month Abbreviations 
export function getMonthFrenchAbbr(date) {
  let month = [];
  month[0] = "janv.";
  month[1] = "févr.";
  month[2] = "mars";
  month[3] = "avril";
  month[4] = "mai";
  month[5] = "juin";
  month[6] = "juil.";
  month[7] = "août";
  month[8] = "sept.";
  month[9] = "oct.";
  month[10] = "nov.";
  month[11] = "déc.";

  const d = new Date(date);
  const n = month[d.getMonth()];
  return n;
}

// Issue Card Title
export const issueTitle = {
  0: '',
  1: 'Endommagé',
  2: 'Perdu',
  3: 'Surfacturation',
  4: 'Autre'
};

// Ouvrir pop-up tab names
export const tabNames = {
  0: '',
  1: 'Transporteur',
  2: 'Assurance',
  3: 'Client',
};
