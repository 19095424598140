import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SubjectIcon from '@material-ui/icons/Subject';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import { convertDateToWithDash, convertDateToTimestamp } from '../common/functions';
import { setGoogleEventError } from '../actions';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: 600,

    '&>*': {
      width: 350,
    },
  },
  participants: {
    marginBottom: 0,
  },
  timeFrom: {
    marginRight: theme.spacing(3),
  },
  error: {
    color: 'red',

    '& *': {
      color: 'red',
    },
    
    '& svg': {
      color: 'red',
    },
  },
  fields: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },

    '& .MuiTextField-root': {
      width: 300,
    },

    '& .MuiSvgIcon-root': {
      color: '#555',
    },
  },
  datePickers: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CalendarAddNewPopUp(props) {
  const classes = useStyles();
  const { handleAddEvent,
    isSignedIn,
    dashboardCalendarLoading,
    dashboardEventEmailError,
    setGoogleEventError,
    dayDifference
  } = props;
  const [open, setOpen] = useState(false);
  
  let dateNow = new Date();
  dateNow.setDate(dateNow.getDate() + dayDifference);

  const [newEvent, setNewEvent] = useState({
    addTitle: '',
    dateFrom: convertDateToWithDash(dateNow),
    dateTo: convertDateToWithDash(dateNow),
    timeFrom: '09:00',
    timeTo: '10:00',
    guests: [],
    location: '',
    description: ''
  });
  const [error, setError] = useState({
    addTitle: false,
    date: false,
    guests: false,
    location: false,
    description: false
  });
  const [newGuests, setNewGuests] = useState('');

  let startTime = new Date(newEvent.dateFrom).setHours(parseInt(newEvent.timeFrom.split(':')[0]), parseInt(newEvent.timeFrom.split(':')[1]), 0, 0);
  let endTime = new Date(newEvent.dateTo).setHours(parseInt(newEvent.timeTo.split(':')[0]), parseInt(newEvent.timeTo.split(':')[1]), 0, 0);

  const event = {
    'summary': newEvent.addTitle,
    'location': newEvent.location,
    'description': newEvent.description,
    'start': {
      'dateTime': new Date(startTime).toISOString(),
    },
    'end': {
      'dateTime': new Date(endTime).toISOString(),
    },
    'attendees': newEvent.guests,
    'reminders': {
    'useDefault': false,
    }
  };

  const handleClickOpen = () => {
    setOpen(true);

    setNewGuests('');

    setNewEvent({
      ...newEvent,
      addTitle: '',
      dateFrom: convertDateToWithDash(dateNow),
      dateTo: convertDateToWithDash(dateNow),
      timeFrom: '09:00',
      timeTo: '10:00',
      guests: [],
      location: '',
      description: ''
    });
    
    setError({
      ...error,
      addTitle: false,
      date: false,
      guests: false,
      location: false,
      description: false
    });
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if(!isSignedIn) {
      handleClose();
    }
  }, [handleClose, isSignedIn]);
  
  const handleSave = () => {
    let dateTimeFromCheck = convertDateToTimestamp(new Date(startTime).toISOString());
    let dateTimeToCheck = convertDateToTimestamp(new Date(endTime).toISOString());

    if (dateTimeFromCheck > dateTimeToCheck) {
      setError({ ...error, date: true });

    } else if (dashboardEventEmailError) {
      setError({ ...error, guests: true });

    } else {
      setError({ ...error, addTitle: false, date: false, guests: false });
      
      setOpen(false);
      handleAddEvent(event);
    }
  };

  const handleChangeGuests = event => {
    setNewGuests(event.target.value);
    setGoogleEventError(false);

    const newArr = [];

    event.target.value.split(/[,\s;:/]/g).map(item => {
      if(item.length) {
        const newObject = new Object();
        newObject.email = item;
        return newArr.push(newObject);
      }
      
      return null;
    });
    
    setNewEvent({ ...newEvent, guests: newArr});
  };

  return (
    <div>
      <Button
        disabled={!isSignedIn || dashboardCalendarLoading}
        className={classes.button}
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        Add New Event
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add New Event
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <TextField
            error={error.addTitle}
            autoFocus
            id="outlined-basic"
            label="Add title"
            size="small"
            value={newEvent.addTitle}
            onChange={e => setNewEvent({ ...newEvent, addTitle: e.target.value})}
            helperText={error.addTitle ? 'This area cannot be empty' : ''}
          />
          <br />
          <form className={classes.formDatePicker} noValidate>
            <div className={classes.datePickers}>
              <TextField
                id="dateFrom"
                className={error.date ? classes.error : ''}
                label="Date From"
                type="date"
                value={newEvent.dateFrom}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setNewEvent({ ...newEvent, dateFrom: e.target.value })}
              />
              <TextField
                id="dateTo"
                className={error.date ? classes.error : ''}
                label="Date To"
                type="date"
                value={newEvent.dateTo}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setNewEvent({ ...newEvent, dateTo: e.target.value })}
              />
            </div><br />
            <div>
              <TextField
                id="timeFrom"
                label="From"
                type="time"
                value={newEvent.timeFrom}
                className={`${classes.timeFrom} ${error.date ? classes.error : ''}`}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                onChange={e => setNewEvent({ ...newEvent, timeFrom: e.target.value })}
                // helperText={error.date ? '"Date&Time From" cannot be late than "Date&Time To"' : ''}
                aria-describedby="date-and-time-error-helper"
              />
              <TextField
                id="timeTo"
                label="To"
                type="time"
                value={newEvent.timeTo}
                className={error.date ? classes.error : ''}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                onChange={e => setNewEvent({ ...newEvent, timeTo: e.target.value })}
              />
            </div>
            <div>
              <FormHelperText
                id="date-and-time-error-helper"
                className={error.date ? classes.error : ''}
              >
                "Date{'&'}Time From" cannot be late than "Date{'&'}Time To"
              </FormHelperText>              
            </div>
          </form>
          <br />
          <Grid
            container
            spacing={1}
            className={classes.fields}
            alignItems="flex-end"
          >
            <Grid item>
              <PeopleAltIcon fontSize="small" />
            </Grid>
            <Grid item>
              <TextField
                error={error.guests}
                id="outlined-basic"
                label="Add guests (Only emails)"
                size="small"
                value={newGuests}
                onChange={handleChangeGuests}
                helperText={error.guests ? 'Email error' : ''}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            className={classes.fields}
            alignItems="flex-end"
          >
            <Grid item>
              <LocationOnIcon fontSize="small" />
            </Grid>
            <Grid item>
              <TextField
                id="outlined-basic"
                label="Add location"
                size="small"
                value={newEvent.location}
                onChange={e => setNewEvent({ ...newEvent, location: e.target.value})}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            className={classes.fields}
            alignItems="flex-end"
          >
            <Grid item>
              <SubjectIcon fontSize="small" />
            </Grid>
            <Grid item>
              <TextField
                id="outlined-basic"
                label="Add description"
                size="small"
                value={newEvent.description}
                onChange={e => setNewEvent({ ...newEvent, description: e.target.value})}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">
            Save event
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    dashboardEventEmailError: state.dashboardEventEmailError,
    dashboardCalendarLoading: state.dashboardCalendarLoading
  };
};

export default connect(mapStateToProps, { setGoogleEventError })(CalendarAddNewPopUp);
