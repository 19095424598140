import '../css/TextEditor.css';
import '../../node_modules/draft-js/dist/Draft.css';
import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Editor,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { numberThousands } from '../common/Frame';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    dialogActions: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    inputButton: {
        width: "100%",
        border: "1px dashed #ccc",
        paddingLeft: theme.spacing(1),
    },
    paper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        padding: theme.spacing(.5),
        marginBottom: theme.spacing(1),
        background: '#f6f6f6',
    },
    highlightOffIcon: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        color: '#808080',
    },
    dialogContent: {
        padding: '0px 12px',
    },
}));

function TextEditorNew(props) {
    const { editorState, onChange, focusInput } = props;
  
    useEffect(() => {
        focusInput.current.focus();
    }, []);
  
    const handleKeyCommand = (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        onChange(newState);
        return true;
      }
      return false;
    }
  
    const mapKeyToEditorCommand = (e) => {
      if (e.keyCode === 9 /* TAB */) {
        const newEditorState = RichUtils.onTab(
          e,
          editorState,
          4, /* maxDepth */
        );
        if (newEditorState !== editorState) {
          onChange(newEditorState);
        }
        return;
      }
      return getDefaultKeyBinding(e);
    }
    
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }
  
    return (
      <div className="RichEditor-root" style={{ minHeight: '30vh' }}>
        <div className={className}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onChange}
            placeholder="Write a comment..."
            ref={focusInput}
          />
        </div>
      </div>
    );
}

// Custom overrides for "code" style.
const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
};

function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote': return 'RichEditor-blockquote';
      default: return null;
    }
}
  
class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }
  
    render() {  
      return (
        <IconButton
            className={this.props.style}
            color={this.props.active ? 'secondary' : 'default'}
            onMouseDown={this.onToggle}
        >
          {this.props.label}
        </IconButton>
      );
    }
}

var INLINE_STYLES = [
    {label: 'G', style: 'BOLD'},
    {label: 'I', style: 'ITALIC'},
    {label: 'S', style: 'UNDERLINE'},
];
  
const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) =>
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
};

export default function CommentForm(props) {
  const { customerAccount, byCustomerID, setByCustomerID, postCommentTask } = props;
  const classes = useStyles();
  const [comment, setComment] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const scrollTo = useRef(null);
  const focusInput = useRef(null);
  
  const cleancombinedText = DOMPurify.sanitize(comment, { USE_PROFILES: { html: true } });

  const noHhtml = cleancombinedText.replace(/(<([^>]+)>)/gi, "");
  const noHtmlTrim = noHhtml.trim();

  const handleClickOpen = () => {
      setOpen(true);
      setComment('');
      setSelectedFiles([]);
      // Customer-Account has two kind of add comment
      if(customerAccount) {
          setByCustomerID(byCustomerID);
      }
  };
  
  const handleClose = () => {
      setOpen(false);
  };
    
  const handleSubmit = () => {
    postCommentTask(comment, selectedFiles);
    setEditorState(EditorState.createEmpty());
    handleClose();
  };

  const scrollToBottom = () => {
      scrollTo.current.scrollIntoView({ behavior: 'smooth' });
  };

  const onChangeFile = (files) => {
      setSelectedFiles(selectedFiles.concat(files));
      scrollToBottom();
  }; 

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
      onDrop: onChangeFile,
      onChange: onChangeFile
  });

  const deleteItem = (filt) => {
      setSelectedFiles(selectedFiles.filter(item => item !== filt));
  };

  const handleEditorState = (editorState) => {
      setEditorState(editorState);

      var contentState = editorState.getCurrentContent();
      setComment(draftToHtml(convertToRaw(contentState)));
    };     
  
  const toggleInlineStyle = (inlineStyle) => {
      handleEditorState(
        RichUtils.toggleInlineStyle(
          editorState,
          inlineStyle
        )
      );
  }

  const renderSelectedFiles = selectedFiles.length ? (selectedFiles.map((file, index) => {
      return (
          <Paper className={classes.paper} key={index}>
              <span>{file.name} <span style={{ color: 'grey' }}>({numberThousands(Math.trunc(file.size / 1024))}KB)</span></span>
              <span className={classes.highlightOffIcon}>
                  <HighlightOffIcon onClick={() => deleteItem(file)} />
              </span>
          </Paper>
      );
  })) : null;

  return(
    <div>
        <Tooltip title="Ajouter un commentaire">
            <IconButton aria-label="add" onClick={handleClickOpen}>
                <AddCircleOutlineRoundedIcon />
            </IconButton>
        </Tooltip>
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="form-dialog-title"
            className={classes.root}
        >
            <DialogTitle id="form-dialog-title" onClose={handleClose} >
                Commentaire:
                <InlineStyleControls
                    editorState={editorState}
                    onToggle={toggleInlineStyle}
                />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <TextEditorNew
                    editorState={editorState}
                    onChange={handleEditorState}
                    focusInput={focusInput}
                />
                {renderSelectedFiles}
                <div ref={scrollTo} />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                 <span className={classes.inputButton} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    }
                </span>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                    disabled={noHtmlTrim < 1}
                >
                    Enregistrer
                </Button>
            </DialogActions>
      </Dialog>
    </div>
  );
}
