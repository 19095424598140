import { GET_STATUS_SHIPMENT } from '../actions/types';


// This reducer returns status shipments' shipment ids
const statusShipmentShipmentIds = (state = [], action) => {
  switch (action.type) {
    case GET_STATUS_SHIPMENT:
      return Object.values(action.payload).filter(o => (o.is_actif === 0)).map(p => p.shipment_id);
    default:
      return state;
  }
};

export default statusShipmentShipmentIds;
