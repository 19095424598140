import { CA_INVOICING } from '../actions/types';


const initialValues = {
  currentUser: {},
};

const caInvoicing = (state = initialValues, action) => {
  switch(action.type) {
    case CA_INVOICING:
      return action.payload;
    default:
      return state;
  };
};


export default caInvoicing;
