import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MenuIcon from '@material-ui/icons/Menu';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { URL_All } from '../common/Constants';
import { getDataWithAuth } from '../common/Frame';
import useToken from '../login/useToken';
import { deletedShipment } from '../actions';
import { logOut } from '../common/Frame';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
    '& p': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(0.5),
  },
  dialogContent: {
    padding: theme.spacing(0),
  },
  contentDark: {
    margin: 'auto',
    paddingBottom: theme.spacing(1),
    background: '#ececec',
    width: '100%',
  },
  contentLight: {
    margin: 'auto',
    paddingBottom: theme.spacing(1),
  },
  contentTitle: {
    textAlign: 'center',
  },
  formGroup: {
    margin: 'auto',
    padding: theme.spacing(2),
    width: 'fit-content',
  },
  menuIconGrey: {
    color: grey[700],
  },
  formControl2: {
    margin: theme.spacing(3),
    width: '90%',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  formControlThreeAndFour: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  timeFrom: {
    marginRight: theme.spacing(3),
  },
  timeTo: {
    marginLeft: theme.spacing(3),
  },
  buttomDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  servisInvoice: {
    '& input': {
      textAlign: 'center',
    },
  },
  dialogTitle: {
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  body: {
    textAlign: 'center',
  },
  marginBottomEightPx: {
    marginBottom: theme.spacing(1),
  },
}));

const GreyRadio = withStyles({
  root: {
    color: grey[400],
    '&$checked': {
      color: grey[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function BurgerIcon(props) {
  const classes = useStyles();
  const { token } = useToken();
  const { shipment, setShipment, deletedShipment } = props;
  const [open, setOpen] = useState(false);
  const [doubleCheck, setDoubleCheck] = useState(shipment.has_pickup);
  const [timeFrom, setTimeFrom] = useState(shipment.pickup_request.ready_time || '10:00');
  const [timeTo, setTimeTo] = useState(shipment.pickup_request.close_time || '12:00');
  const [datePickUp, setDatePickUp] = useState(shipment.pickup_date || null);
  const [servisInvoice, setServisInvoice] = useState(shipment.ikompar_invoice ? Math.trunc(shipment.ikompar_invoice.total_amount * 100)/100 : 0);
  const [checked, setChecked] = useState(false);

  const handleClickOpen = () => () => {
    setOpen(true);
    setDoubleCheck(shipment.has_pickup);
    setChecked(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Delete shipment and invoice 
  const handleDelete = () => {
    if(checked) {
      deletedShipment(token, shipment.id);
      handleClose();
    }

    handleClose();
  };
  
  // if click save (not validate / validate)
  function onValidateSave() {
    if (doubleCheck !== shipment.has_pickup) {
      getDataWithAuth(`${URL_All.apiShipment}/${shipment.id}/${doubleCheck ? 'pickup_done' : 'pickup_not_done'}/`, token)
        .then(json => setShipment(json))
        .catch(err => console.error(err));
    }

    handleClose();
  }

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const ifShipmentValidate = (
    <div className={classes.contentLight}>
      <DialogTitle id="customized-dialog-title" className={classes.contentTitle} onClose={handleClose}>
        Is this shipment validate?
      </DialogTitle>
      <Divider />
      <form>
        <FormControl
          component="fieldset"
          className={classes.formControl2}
        >
          <RadioGroup
            aria-label="shipment_done"
            name="shipment_done"
            value={doubleCheck}
            onChange={() => setDoubleCheck(!doubleCheck)}
          >
            <FormControlLabel
              value={false}
              control={<GreyRadio />}
              label="Not validate"
            />
            <FormControlLabel
              value={true}
              control={<GreenRadio />}
              label="Validate"
            />
          </RadioGroup>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={onValidateSave}
          >
            Save
          </Button>
        </FormControl>
      </form>
    </div>
  );

  const ifServiceInvoice = (
    <div className={classes.contentLight}>
      <DialogTitle id="customized-dialog-title" className={classes.contentTitle} onClose={handleClose}>
        <b>Facture prestation</b><br />
        Entrer un montant à ajouter à la facture
      </DialogTitle>
      <Divider />
      <form className={classes.formControlThreeAndFour}>
        <TextField
          id="servis-invoice"
          className={classes.servisInvoice}
          variant="outlined"
          value={servisInvoice}
          onChange={(e) => setServisInvoice(e.target.value)}
        />
        <Divider className={classes.buttomDivider} />
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          Save
        </Button>
      </form>
    </div>
  );

  const ifModifyDateTime = (
    <div className={classes.contentDark}>
      <DialogTitle id="customized-dialog-title" className={classes.contentTitle} onClose={handleClose}>
          <b>Modifier l’enlèvement</b><br />
          Confirmation: {shipment.pickup_request.confirmation_number && shipment.pickup_request.confirmation_number}
      </DialogTitle>
      <Divider />
      <form className={classes.formControlThreeAndFour}>
        <TextField
          disabled={!(shipment.admin_status === 'pickup_error' || shipment.admin_status === 'ok')}
          id="dateFrom"
          className={classes.datePicker}
          label="Date"
          type="date"
          value={datePickUp}
          InputLabelProps={{
              shrink: true,
          }}
          onChange={(e) => setDatePickUp(e.target.value)}
        />
        <br /><br />
        <TextField
          disabled={!(shipment.admin_status === 'pickup_error' || shipment.admin_status === 'ok')}
          id="timeFrom"
          label="From"
          type="time"
          className={classes.timeFrom}
          value={timeFrom}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 900, // 15 min
          }}
          onChange={(e) => setTimeFrom(e.target.value)}
        />
        <TextField
          disabled={!(shipment.admin_status === 'pickup_error' || shipment.admin_status === 'ok')}
          id="timeTo"
          label="To"
          type="time"
          className={classes.timeTo}
          value={timeTo}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 900, // 15 min
          }}
          onChange={(e) => setTimeTo(e.target.value)}
        />
        <Divider className={classes.buttomDivider} />
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          Save
        </Button>
      </form>
    </div>
  );

  const deleteShipmentAndInvoice = (
    <div className={classes.contentLight}>
      <DialogTitle id="customized-dialog-title" className={`${classes.contentTitle} ${classes.marginBottomEightPx}`} onClose={handleClose}>
        Do you want to delete this shipment and invoice?
      </DialogTitle>
      <Divider />
      <form>
        <FormControl row className={classes.formControl2}>
          <FormControlLabel
            className={classes.marginBottomEightPx}
            control={<Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />}
            label="Delete"
          />
          <Button
            disabled={!checked}
            className={`${classes.button} ${classes.marginBottomEightPx}`}
            variant="outlined"
            color="secondary"
            onClick={handleDelete}
          >
            Save
          </Button>
        </FormControl>
      </form>
    </div>
  );

  const username = localStorage.getItem('username');
  if(!username) logOut();

  return (<div>
    <IconButton className={classes.iconButton} onClick={handleClickOpen()}>
      <MenuIcon
        fontSize="large"
        className={classes.menuIconGrey}
      />
    </IconButton>
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle
        id="customized-dialog-title"
        className={classes.dialogTitle}
        onClose={handleClose}
      >
        Que veux-tu faire, {username}?
      </DialogTitle>
      <Divider />
      <div className={classes.body}>
        {shipment.ikompar_invoice ? ifShipmentValidate : ifServiceInvoice}
        {(shipment.admin_status === 'pickup_error' || shipment.admin_status === 'ok') && ifModifyDateTime}
        {(shipment.admin_status === 'pickup_error' || shipment.admin_status === 'ok') && deleteShipmentAndInvoice}
      </div>
    </Dialog>
  </div>);
}

export default connect(null, { deletedShipment })(BurgerIcon);
