import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { editCR, setContactRequest } from '../actions';
import { logOut } from '../common/Frame';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  dialogTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  divButton: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function PopUpDelete({ id, editCR, setContactRequest }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = event => {
    event.preventDefault();

    editCR(id, { is_actif: 0 })
      .finally(() => setContactRequest()); 

    handleClose();
  };
  
  const username = localStorage.getItem('username');
  if(!username) logOut();

  return (
    <div>
        <Button
          size="small"
          variant="outlined"
          onClick={handleClickOpen}
        >
          Supprimer
        </Button>
        <Dialog fullWidth={fullWidth} maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <div className={classes.root}>
              <DialogTitle id="customized-dialog-title" className={classes.dialogTitle} onClose={handleClose}>
                Are you sure, {username} ?
              </DialogTitle>
              <Divider />
              <div className={classes.divButton}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleSubmit}
                >
                  Delete
                </Button>
              </div>
            </div>
        </Dialog>
    </div>
  );
}

export default connect(null, { editCR, setContactRequest })(PopUpDelete);
