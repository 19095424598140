import { STATISTICS_LOADING } from '../actions/types';

const initialState = {
  groupOne: true,
  // groupOnePareto: false,
  n1: false,
  // n1Pareto: false,
  n2: false,
  // n2Pareto: false,
  n3: false,
  // n3Pareto: false,
};

const statisticsLoading = (state = initialState, action) => {
  switch (action.type) {
    case STATISTICS_LOADING:
      return action.payload;
    default:
      return state;
  }
};

export default statisticsLoading;
