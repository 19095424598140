import '../css/TextEditor.css';
import '../../node_modules/draft-js/dist/Draft.css';
import React, { useContext,
    useState,
    useEffect,
    useRef,
} from 'react';
import { Editor,
    EditorState,
  getDefaultKeyBinding,
  RichUtils,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { CustomerAccountContext } from '../contexts/CustomerAccountContext';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(0),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(0),
      color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    formControl: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
        width: "92%",
    },
    toWho: {
        width: "80%",
    },
    dateTimeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dateTime: {
        width: 210,
    },
    inputButton: {
        width: "100%",
    },
    dialogActions: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
    },
    displayNone: {
        display: 'none',
    },
    dialogTitle: {
        marginTop: theme.spacing(1),
        paddingBottom: theme.spacing(0),
    },
    biu: {
        '& .RichEditor-controls': {
            '&>*': {
                fontSize: '1.4rem',
                width: 50,
            },
            
            '& .MuiButtonBase-root:not(last-child)': {
                marginRight: theme.spacing(1),
            },
        },
    },
    dialogContent: {
        '& .public-DraftEditor-content': {
            padding: 0,
        },
    },
    saveCancel: {
        margin: 0,
        padding: 0,
    },
}));

function RichTextEditor(props) {
    const { editorState,
        onChange,
        editor
    } = props;
  
    useEffect(() => {
      editor.current.focus();
    }, []);
  
  
    const handleKeyCommand = (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        onChange(newState);
        return true;
      }
      return false;
    }
  
    const mapKeyToEditorCommand = (e) => {
      if (e.keyCode === 9 /* TAB */) {
        const newEditorState = RichUtils.onTab(
          e,
          editorState,
          4, /* maxDepth */
        );
        if (newEditorState !== editorState) {
          onChange(newEditorState);
        }
        return;
      }
      return getDefaultKeyBinding(e);
    }
    
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }
  
    return (
      <div className="RichEditor-root">
        <div className={className}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onChange}
            placeholder="Write a task..."
            ref={editor}
          />
        </div>
      </div>
    );
}
  
// Custom overrides for "code" style.
const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
};
  
function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote': return 'RichEditor-blockquote';
      default: return null;
    }
}
  
class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }
  
    render() {  
      return (
        <IconButton
            className={this.props.style}
            color={this.props.active ? 'secondary' : 'default'}
            onMouseDown={this.onToggle}
        >
          {this.props.label}
        </IconButton>
      );
    }
}
  
  
var INLINE_STYLES = [
    {label: 'G', style: 'BOLD'},
    {label: 'I', style: 'ITALIC'},
    {label: 'S', style: 'UNDERLINE'},
];
  
const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) =>
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
};

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function TaskCommentEdit(props) {
    const focusEditInput = useRef(null);
    const { id,
        taskStatus,
        dueDate,
        isTask,
        toUsers,
        editTaskComment,
        byCustomerID,
        setByCustomerID,
        oldTask
    } = props;
    const { users } = useContext(CustomerAccountContext);
    const [task, setTask] = useState(oldTask);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [personName, setPersonName] = useState(users.filter(t => toUsers.split(',').includes(t.id.toString())).map(item => item.username));
    const [personIDs, setPersonIDs] = useState([]);
    const [dueTo, setDueTo] = useState((dueDate || dueDate !== 0) ? (dueDate * 1000) : new Date().getTime());
    
    const blocksFromHTML = convertFromHTML(oldTask);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    const [editorState, setEditorState] = useState(EditorState.createWithContent(state));

    
    const handleEditorState = (editorState) => {
        setEditorState(editorState);

        var contentState = editorState.getCurrentContent();
        setTask(draftToHtml(convertToRaw(contentState)));
    };
  
    const toggleInlineStyle = (inlineStyle) => {
        handleEditorState(
        RichUtils.toggleInlineStyle(
          editorState,
          inlineStyle
        )
      );
    }

    const handleChange2 = (event) => {
        setPersonName(event.target.value);
        setPersonIDs(users.filter(r => event.target.value.includes(r.username)).map(item => item.id));
    };

    function handleChange3(e) {
        setDueTo(e.target.value);
    }

    const handleClickOpen = () => {
        setOpen(true);
        setByCustomerID(byCustomerID);
    };
    
      const handleClose = () => {
        setOpen(false);
    };

    return(
    <div>
        <Tooltip title="Edit" onClick={handleClickOpen} placement="top">
            <IconButton aria-label="add">
                <EditOutlinedIcon className="hoverShow" fontSize="small" />
            </IconButton>
        </Tooltip>
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="form-dialog-title"
            className={classes.root}
        >
            <DialogTitle id="customized-dialog-title" className={classes.dialogTitle} onClose={() => handleClose()}>
                Edit {isTask ? 'task' : 'comment'}:
            </DialogTitle>
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
                className={isTask ? classes.formControl : classes.displayNone}
            >
                <Grid item xs={6}>
                    <FormControl className={classes.toWho}>
                        <InputLabel id="task-mutiple-checkbox-label" className={classes.dropDown}>Tâche assignée à:</InputLabel>
                        <Select
                            labelId="users-mutiple-checkbox-label"
                            id="users-mutiple-checkbox"
                            multiple
                            value={personName}
                            onChange={handleChange2}
                            input={<Input />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {users.sort(function(a, b) {
                                let x = a.username.toLowerCase();
                                let y = b.username.toLowerCase();
                                if(x < y) { return -1; }
                                if(x > y) { return 1; }
                                return 0;
                            }).map((item) => (
                                <MenuItem key={item.id} value={item.username}>
                                    <Checkbox checked={personName.indexOf(item.username) > -1} />
                                    <ListItemText primary={item.username} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <form className={classes.dateTimeContainer} noValidate>
                        <TextField
                            id="datetime-task"
                            label="Due to:"
                            type="datetime-local"
                            className={classes.dateTime}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            defaultValue={(new Date(dueTo).getFullYear()).toString()+'-'+(new Date(dueTo).getMonth()+1).toString().padStart(2, '0')+'-'+(new Date(dueTo).getDate()).toString().padStart(2, '0')+'T'+(new Date(dueTo).getHours()).toString().padStart(2, '0')+':'+(new Date(dueTo).getMinutes()).toString().padStart(2, '0')}
                            onChange={handleChange3}
                        />
                    </form>
                </Grid>
            </Grid>
            <DialogContent className={classes.dialogContent}>
                <form id="formTask" onSubmit={(e) => {
                    e.preventDefault();
                    task.trim() !== '' ? editTaskComment(id, taskStatus, task, isTask & personIDs.toString() !== '' ? personIDs.toString() : toUsers, isTask ? dueTo : null) : handleClose();
                    setTask('');
                    setPersonIDs([]);
                    setPersonName([]);
                }}>
                    {/* <TextField
                        autoFocus
                        margin="dense"
                        id="new-task-input"
                        type="text"
                        multiline
                        rows={14}
                        fullWidth
                        value={task}
                        onChange={handleChange}
                    /> */}
                    <RichTextEditor
                        editorState={editorState}
                        onChange={handleEditorState}
                        editor={focusEditInput}
                    />
                </form>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <span className={classes.biu}>
                    <InlineStyleControls
                        editorState={editorState}
                        onToggle={toggleInlineStyle}
                    />
                </span>
                <div className={classes.saveCancel}>
                    <Button
                        color="primary"
                        type="submit"
                        form="formTask" 
                        onClick={handleClose}
                    >
                        Save
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </div>
            </DialogActions>
      </Dialog>
    </div>
    );
}
