import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import { editIssue } from '../actions';
import { currentUserId } from '../common/functions';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  openButton: {
    padding: '0 8px !important',
    color: '#ff6',
    textTransform: 'none',

    '&:hover': {
      backgroundColor: '#ff6',
      color: '#333',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    maxWidth: 760,
    marginBottom: theme.spacing(1),

    '& thead': {
      backgroundColor: '#b8b8b8',

      '& tr th': {
        textAlign: 'center',
        fontWeight: '700',
      },
    },

    '& tbody': {
      '& tr:nth-child(even)': {
        backgroundColor: '#ddd',
      },

      '& tr td:first-child': {
        fontWeight: '700',
        paddingLeft: theme.spacing(1.5),
      },
      
      '& tr td:not(:first-child)': {
        textAlign: 'center',
      },
    },

    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
  empty: {
    fontStyle: 'italic',
    color: '#555',
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function TableEditPopUp(props) {
  const classes = useStyles();
  const { issue, editIssue, users } = props;
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({
    purchase: issue.purchase,
    purchase_to_refund: issue.purchase_to_refund,
    purchase_refunded: issue.purchase_refunded,
    sale: issue.sale,
    sale_to_refund: issue.sale_to_refund,
    sale_refunded: issue.sale_refunded,
    customsvalue: issue.customsvalue,
    customsvalue_to_refund: issue.customsvalue_to_refund,
    customsvalue_refunded: issue.customsvalue_refunded,
    customer_to_refund: issue.customer_to_refund,
    customer_refunded: issue.customer_refunded
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    editIssue(issue.shipment_id, issue.type_issue, currentUserId(users), inputs);
    handleClose();
  };

  return (
    <div>
      <Button
        className={classes.openButton}
        color="primary"
        onClick={handleClickOpen}
      >
        Edit Table&nbsp;<EditIcon fontSize="small" />
      </Button>
      <Dialog
        maxWidth={'md'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Table
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <Table className={classes.table}>
            <colgroup span="1" style={{ width: "50%" }} />
            <colgroup span="1" style={{ width: "25%" }} />
            <colgroup span="1" style={{ width: "25%" }} />
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>A rembourser</TableCell>
                <TableCell>remboursé</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>Prix d’achat</TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    type="number"
                    value={inputs.purchase_to_refund}
                    onChange={(e) => setInputs((prev) => ({ ...prev, purchase_to_refund: e.target.valueAsNumber }))}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    type="number"
                    value={inputs.purchase_refunded}
                    onChange={(e) => setInputs((prev) => ({ ...prev, purchase_refunded: e.target.valueAsNumber }))}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Prix de vente (without insurance amount)</TableCell>
                <TableCell>
                  <TextField
                  variant="outlined"
                  type="number"
                  value={inputs.sale_to_refund}
                  onChange={(e) => setInputs((prev) => ({ ...prev, sale_to_refund: e.target.valueAsNumber }))}
                />
                </TableCell>
                <TableCell>
                  <TextField
                  variant="outlined"
                  type="number"
                  value={inputs.sale_refunded}
                  onChange={(e) => setInputs((prev) => ({ ...prev, sale_refunded: e.target.valueAsNumber }))}
                />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Valeur marchandises</TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    type="number"
                    value={inputs.customsvalue_to_refund}
                    onChange={(e) => setInputs((prev) => ({ ...prev, customsvalue_to_refund: e.target.valueAsNumber }))}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    type="number"
                    value={inputs.customsvalue_refunded}
                    onChange={(e) => setInputs((prev) => ({ ...prev, customsvalue_refunded: e.target.valueAsNumber }))}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Remboursement client</TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    type="number"
                    value={inputs.customer_to_refund}
                    onChange={(e) => setInputs((prev) => ({ ...prev, customer_to_refund: e.target.valueAsNumber }))}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    type="number"
                    value={inputs.customer_refunded}
                    onChange={(e) => setInputs((prev) => ({ ...prev, customer_refunded: e.target.valueAsNumber }))}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return { users: state.users };
};

export default connect(mapStateToProps, { editIssue })(TableEditPopUp);
