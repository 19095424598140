import { CONTACT_REQUEST_DATE } from '../actions/types';
import { convertDateToWithDash } from '../common/functions';

const newDate = new Date();
const dateWithDash = convertDateToWithDash(newDate);

const initialValue = {
  from: '1970-01-01',
  to: dateWithDash,
};

const crDate = (state = initialValue, action) => {
  switch (action.type) {
    case CONTACT_REQUEST_DATE:
      return action.payload;
    default:
      return state;
  }
};

export default crDate;
