import { STATISTICS_GENERAL_GROUP_PARETO } from '../actions/types';

const initialState = {
  groupOneP: true, // 12 mois, quarters
  groupTwoP: false, // n1, n2, n3
};

// Here we group buttons to avoid call same api again and again
const statisticsGeneralGroupPareto = (state = initialState, action) => {
  switch(action.type) {
    case STATISTICS_GENERAL_GROUP_PARETO:
      return action.payload;
    default: 
      return state;
  }
};

export default statisticsGeneralGroupPareto;
