import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import SvgIcon from '@material-ui/core/SvgIcon';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import grey from '@material-ui/core/colors/grey';
import { Pallet } from '../common/icons';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  flipHorizontal: {
    transform: 'scaleX(-1)',
  },
  displayNone: {
      display: 'none',
  },
  iconBlackColor: {
    color: grey[900],
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function PackageIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z" />
      </SvgIcon>
    );
}

export default function PopUpPackage(props) {
    const classes = useStyles();
    const { shipment } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

  return (
    <div>
      <Tooltip
        title={<div>
        {shipment.package_group.packages.map((pkg) => (<div className={(pkg.length || pkg.width || pkg.height) ? '' : classes.displayNone} key={pkg.id}>{`${pkg.length} x ${pkg.width} x ${pkg.height} cm - ${pkg.weight}kg`}<br /></div>))}
        <br />
        {shipment.is_ddp ? 'DDP' : 'DAP'}
        <br /><hr /><br />
        {shipment.package_group.goods.map((i, e) => (<div key={e}>- {i.quantity} {i.description}<br /></div>))}
        <br />Total CHF: {shipment.package_group.customs_total_value} -
        contenu</div>}
        placement="left"
      >
        <IconButton className={shipment.admin_status === 'quote_selected' ? classes.iconBlackColor : ''} onClick={handleClickOpen}>
          <Typography >x{shipment.package_count}</Typography >
          {shipment.is_doc ? (<MailOutlineIcon fontSize="large" />) : 
          shipment.is_pallet ? <Pallet fontSize="large" />
          : (<PackageIcon className={classes.flipHorizontal} fontSize="large" />)}
        </IconButton>
      </Tooltip>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Dimensions, Weight, DDP/DAP, Goods, {`&`} Total:
        </DialogTitle>
        <DialogContent dividers>
            <div>
                {shipment.package_group.packages.map((pkg) => (<div className={(pkg.length || pkg.width || pkg.height) ? '' : classes.displayNone} key={pkg.id}>{`${pkg.length} x ${pkg.width} x ${pkg.height} cm - ${pkg.weight}kg`}<br /></div>))}
                <br /><hr style={{ width: "60%", float: "left" }} /><br />
                {shipment.is_ddp ? 'DDP' : 'DAP'}
                <br /><hr style={{ width: "60%", float: "left" }} /><br />
                {shipment.package_group.goods.map((i, e) => (<div key={e}>- {i.quantity} {i.description}<br /></div>))}
                <br />Total CHF: {shipment.package_group.customs_total_value} -
                contenu
            </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
