import { GET_CHAT_SHIPS } from '../actions/types';


const chatGetShips = (state = [], action) => {
  switch(action.type) {
    case GET_CHAT_SHIPS:
      return action.payload;
    default:
      return state;
  }
};


export default chatGetShips;
