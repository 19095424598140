import { TRACKINGS_FILTER } from '../actions/types';

// Filter Button Names for Functionality
export const BUTTON_NAMES = {
  all: 'all',
  fedex: 'fedex',
  ups: 'ups',
  tnt: 'tnt',
  aramex: 'aramex',
  spring: 'spring',
  transnat: 'transnat',
  fretCargo: 'fretCargo',
  fr: 'fr',
  en: 'en',
  de: 'de',
  export: 'export',
  import: 'import',
  domestic: 'domestic',
  normal: 'normal',
  premium: 'premium',
  gold: 'gold',
};

export const initialValues = {
  [BUTTON_NAMES.all]: true,
  [BUTTON_NAMES.fedex]: false,
  [BUTTON_NAMES.ups]: false,
  [BUTTON_NAMES.tnt]: false,
  [BUTTON_NAMES.aramex]: false,
  [BUTTON_NAMES.spring]: false,
  [BUTTON_NAMES.transnat]: false,
  [BUTTON_NAMES.fretCargo]: false,
  [BUTTON_NAMES.fr]: false,
  [BUTTON_NAMES.en]: false,
  [BUTTON_NAMES.de]: false,
  [BUTTON_NAMES.export]: false,
  [BUTTON_NAMES.import]: false,
  [BUTTON_NAMES.domestic]: false,
  [BUTTON_NAMES.normal]: false,
  [BUTTON_NAMES.premium]: false,
  [BUTTON_NAMES.gold]: false,
};

const trackingsFilter = (state = initialValues, action) => {
  switch (action.type) {
    case TRACKINGS_FILTER:
      return action.payload;
    default:
      return state;
  }
};

export default trackingsFilter;
