import React, { useState, useContext, useRef } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CardMedia from '@material-ui/core/CardMedia';
import GetAppIcon from '@material-ui/icons/GetApp';
import TaskForm from '../common/TaskForm';
import CommentForm from '../common/CommentForm';
import MessageFormChat from '../chat/MessageForm';
import NewsFeed from './NewsFeed';
import { newadmindb, URL_All } from '../common/Constants';
import IssueBodyContext from '../contexts/IssueBodyContext';
import { getTaskComment,
  setRefreshCard,
  setRefresh,  
  downloadFiles,
} from '../actions';
import imagePdf from '../static/images/pdf.png';
import imageVideo from '../static/images/video.jpg';
import imageUndefined from '../static/images/imageUndefined.png';
import { countUndoneTasks, convertDateToWithDots } from '../common/functions';
import { logOut } from '../common/Frame';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
  },
  tooltip: {
    color: "#000000",
  },
  openButtton: {
    textTransform: "none",
    fontSize: ".95rem",
    fontWeight: '700',
    color: '#555',
    letterSpacing: .5,
    
    '& .MuiSvgIcon-root': {
      color: '#777',
      fontSize: '1.6em',
    },
  },
  ifCorrespondence: {
    padding: '2px 32px !important',
  },
  ifNotCorrespondence: {
    margin: '0 0 4px 0 !important',
    padding: '0 8px !important',
    border: 'none',
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  headers1: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: "grey",
  },
  headers2: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  bodyHeight: {
    minHeight: '60vh',
  },
  showMoreInfo: {
    color: 'grey',
    backgroundColor: '#f9f9ff',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  headFiles: {
    fontSize: '0.9em',
    marginLeft: theme.spacing(1),
    display: 'inline-block',
    verticalAlign: 'top',
  },
  diasebledAddMail: {
    margin: theme.spacing(1.5),
    color: 'gainsboro',
  },
  dividerPinned: {
    marginTop: theme.spacing(2),
    color: 'gray',
  },
  displayNone: {
    display: 'none',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialogTitle: {
    paddingTop: 0,

    '& .MuiCardMedia-media': {
      width: '40px',
      textAlign: 'center',
      lineHeight: '50px',
    },
  },
  colorGray: {
    color: 'gray',
  },
  showMore: {
    whiteSpace: 'pre-wrap',
    verticalAlign: 'top',
    width: '36px',
    marginLeft: theme.spacing(.5),
  },
  subHeader: {
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  downloadFiles: {
    marginTop: 5,
    marginBottom: 8,
    margin: '8px 0',
    fontSize: '.8em',
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="subtitle2">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

// const tabNames = {
//   0: 'Tous',
//   1: 'Transporteur',
//   2: 'Assurance',
//   3: 'Client',
// };

function CorrespondencesPopUp(props) {
  const classes = useStyles();
  const goToElement = useRef(null);
  const { // doesExist,
    // trackingId,
    // trackingNumber,
    // pickupContact,
    // deliveriContact,
    // pickupAddress,
    // deliveriAddress,
    // pickupPhone,
    // deliveriPhone,
    // pickupEmail,
    // deliveriEmail,
    // customerID,
    goToTab,
    buttonTitle,
    users,
    countCommentsTasks,
    getTaskComment,
    setRefreshCard,
    goToTask,
    issuesCardRefresh,
    setRefresh,
    refresh,
    downloadFiles,
    downloadFilesLoad,
  } = props;
  const { tracking } = useContext(IssueBodyContext);
  const [comments, setComments] = useState([]);
  const [open, setOpen] = useState(false);
  const [show,setShow] = useState(false);
  const [showMoreFiles, setShowMoreFiles] = useState(false);
  const [tabsValue, setTabsValue] = useState(0);
  const [copyTitle, setCopyTitle] = useState(false);
  
  const history = useHistory();
  
  const username = localStorage.getItem('username');
  if(!username) logOut();
  const currentUser = users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0] === undefined ? 0 : users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0].id;

  function getCommentTask(args) {
    fetch(`${URL_All.CommentTask}/get_shipment?shipment_id=${args}&sort=-id`)
      .then(response => response.json())
      .then(json => setComments(json))
      .catch(error => console.error(error))
  };

  const optionsAxios = {
    headers: { 'enctype': 'multipart/form-data' },
    onUploadProgress: ProgressEvent => {
        console.log(Math.round(ProgressEvent.loaded / ProgressEvent.total * 100))
    }
  };

  const postCommentTask2 = (comment, file, toUsers, dueDate, isTask, isChat) => {
    const dueDateConvert = Math.trunc(new Date(dueDate).getTime()/1000);
    const fd = new FormData();
    if(file.length){
      for (let fN=0; fN<file.length; fN++) {
          fd.append(`file-${fN+1}`, file[fN]);
      }
    }
    fd.append('shipment_id', tracking.id);
    fd.append('customer_id', parseInt(tracking.customer_id));
    fd.append('comment', comment);
    fd.append('created_by', currentUser);
    fd.append('to_users', toUsers);
    fd.append('due_date', dueDateConvert);
    fd.append('tracking_nbr', tracking.tracking_numbers);
    fd.append('is_task', isTask);
    fd.append('with', tabsValue);
    fd.append('is_chat', isChat);
    if(isChat) {
      fd.append('is_new', 1);
      fd.append('contact_email', tracking.user);
    }
    axios.post(URL_All.CommentTask, fd, optionsAxios)
      .then(() => {
        setRefresh(refresh + 1);
        getCommentTask(tracking.id);
        getTaskComment(tracking.id);
        setRefreshCard(issuesCardRefresh + 1);
      });
  }

  function editCommentTask(id, taskStatus, newCommentTask, personName, dueTo, pinned) {
    fetch(`${URL_All.CommentTask}/${id}`, {
      method: 'PUT', // GET, POST, PUT, DELETE
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({due_date: dueTo, comment: newCommentTask, to_users: personName, task_status: taskStatus, updated_by: currentUser, pinned: pinned})
    })
    .then(() => {
      setRefresh(refresh + 1);
      getCommentTask(tracking.id);
      getTaskComment(tracking.id);
      setRefreshCard(issuesCardRefresh + 1);
    })
    .catch(error => console.error(error))
  };

  const editCommentTask2 = (id, taskStatus, newCommentTask, personName, dueTo, file) => {
    const fd = new FormData();
    fd.append('file', file);
    fd.append('task_status', taskStatus);
    fd.append('comment', newCommentTask);
    fd.append('updated_by', currentUser);
    fd.append('to_users', personName);
    fd.append('due_date', dueTo);
    axios.put(`${URL_All.CommentTask}/${id}`, fd, {onUploadProgress: ProgressEvent => {
      console.log(Math.round(ProgressEvent.loaded / ProgressEvent.total * 100))
  }})
    .then(() => {
      setRefresh(refresh + 1);
      getCommentTask(tracking.id);
      getTaskComment(tracking.id);
      setRefreshCard(issuesCardRefresh + 1);
    });
  }

  function deleteComment(id) {
    fetch(`${URL_All.CommentTask}/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({is_actif: 0})
    })
    .then(() => {
      setRefresh(refresh + 1);
      getCommentTask(tracking.id);
      getTaskComment(tracking.id);
      setRefreshCard(issuesCardRefresh + 1);
    })
    .catch(error => console.error(error))
  };

  function scrollToElement() {
    return goToElement.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const handleClickOpen = () => {
    setOpen(true);
    setTabsValue(goToTab);

    if(goToTask) {
      setTimeout(() => scrollToElement(), 500);
      // scrollToElement();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickShow = () => {
    setShow(!show);
  };

  const handleCopyTitle = () => {
    setCopyTitle(false);
  };

  const tabsChange = (e, newValue) => {
    if(newValue !== tabsValue) {
      setTabsValue(newValue);
    }
  };

  let filenames = [];

  const today = (new Date().getFullYear()).toString()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+(new Date().getDate()).toString().padStart(2, '0');
  
  const yesterday = (new Date().getFullYear()).toString()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+(new Date().getDate()-1).toString().padStart(2, '0');

  const forNewsFeed = comments.filter(comment => (tabsValue === 0 ? true : (comment.with === tabsValue))).filter(el => (el.pinned === 0)).reduce((myResult, item) => {
    let current_date = (new Date(item.created_at*1000).getFullYear()).toString()+'-'+(new Date(item.created_at*1000).getMonth()+1).toString().padStart(2, '0')+'-'+(new Date(item.created_at*1000).getDate()).toString().padStart(2, '0');
    if (!myResult[current_date]) myResult[current_date] = [];
    myResult[current_date].push(item);
    return myResult;
  }, {});

  const newsFeedListPinned = comments.filter(comment => (tabsValue === 0 ? true : (comment.with === tabsValue))).filter(el => (el.pinned !== 0)).sort(function(a, b){return a.pinned - b.pinned}).map(pinned => {
    return <div key={pinned.id}>
        <NewsFeed
          id={pinned.id}
          comment={pinned.comment}
          files={pinned.files}
          showFiles={pinned.files ? (pinned.files.slice(0, -1).split(';').map(file => filenames.push({ id: pinned.id, file: file}))) : null}
          toUsers={pinned.to_users !== null ? pinned.to_users : ''}
          isTask={pinned.is_task}
          taskStatus={pinned.task_status}
          createdBy={pinned.created_by}
          updatedBy={pinned.updated_by}
          createdDate={pinned.created_at}
          updatedDate={pinned.updated_at}
          dueDate={pinned.due_date}
          editCommentTask={editCommentTask}
          editCommentTask2={editCommentTask2}
          deleteComment={deleteComment}
          trackingId={tracking.id}
          users={users}
          pinned={true}
          pinnedExist={true}
          tabsValue={tabsValue}
          taskCommentTabValue={pinned.with}
          key={pinned.id}
          goToTask={goToTask}
          goToElement={goToElement}
          isChat={pinned.is_chat}
        />
      </div>;
  });

  const newsFeedList = Object.keys(forNewsFeed).map(groupByDate => {
    return <div key={groupByDate}>
      <h3 style={{textAlign: 'center'}}>{groupByDate === today ? 'Aujourd’hui' : groupByDate === yesterday ? 'Hier' : convertDateToWithDots(groupByDate)}</h3>
      {forNewsFeed[groupByDate].map(item => <NewsFeed
          id={item.id}
          comment={item.comment}
          files={item.files}
          showFiles={item.files ? (item.files.slice(0, -1).split(';').map(file => filenames.push({ id: item.id, file: file}))) : null}
          toUsers={item.to_users !== null ? item.to_users : ''}
          isTask={item.is_task}
          taskStatus={item.task_status}
          createdBy={item.created_by}
          updatedBy={item.updated_by}
          createdDate={item.created_at}
          updatedDate={item.updated_at}
          dueDate={item.due_date}
          editCommentTask={editCommentTask}
          editCommentTask2={editCommentTask2}
          deleteComment={deleteComment}
          trackingId={tracking.id}
          users={users}
          pinned={false}
          pinnedExist={comments.filter(comment => (tabsValue === 0 ? 1 : (comment.with === tabsValue))).filter(el => (el.pinned !== 0)).length === 1}
          tabsValue={tabsValue}
          taskCommentTabValue={item.with}
          key={item.id}
          goToTask={goToTask}
          goToElement={goToElement}
          isChat={item.is_chat}
        />)}
      </div>;
  });

  const showMoreInfo = (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      className={classes.showMoreInfo}
    >
      <Grid item xs={6}>
        <span><b>Expéditeur:</b></span><br />
        <span>{tracking.pickup_contact}</span><br />
        <span>{tracking.pickup_address}</span><br />
        <span>{tracking.pickup_phone}</span><br />
        <span>{tracking.pickup_email}</span>
      </Grid>
      <Grid item xs={6}>
        <span><b>Destinataire:</b></span><br />
        <span>{tracking.delivery_contact}</span><br />
        <span>{tracking.delivery_address}</span><br />
        <span>{tracking.delivery_phone}</span><br />
        <span>{tracking.delivery_email}</span>
      </Grid>
    </Grid>
  );

  const handleDownloadAll = () => {
    downloadFiles(tracking.id, tracking.tracking_numbers);
  }

  const countFile = (history.location.pathname === '/tracking') ? 11 : 12;
  const fileArrayBeforeCut = filenames.length > countFile ? filenames.slice(0, countFile) : filenames;
  const fileArrayAfterCut = filenames.length > countFile ? filenames.slice(countFile) : [];

  function filesArea(array) {
    return (
      array.map((u, i) => (<span key={i} className={classes.inlineFlex}>
        <a
          className={classes.headFiles}
          rel="noopener noreferrer"
          href={`${newadmindb}/basic/web/uploads/${u.id}/${u.file}`}
          target="_blank"
        >
          <CardMedia
            component="img"
            alt={u.file.split('.').pop()}
            height="50"
            width="40"
            image={(u.file.split('.').pop().toLowerCase() === 'jpg' || u.file.split('.').pop().toLowerCase() === 'jpeg' || u.file.split('.').pop().toLowerCase() === 'gif') ? `${newadmindb}/basic/web/uploads/${u.id}/${u.file}`
              : u.file.split('.').pop() === 'pdf' ? imagePdf
              : (u.file.split('.').pop().toLowerCase() === 'mov' || u.file.split('.').pop().toLowerCase() === 'mp4') ? imageVideo
              : imageUndefined        
            }
            title={u.file}
          />
          {u.file.length > 9 ? `${u.file.slice(0, 8)}...` : u.file}
        </a>
      </span>))
    );
  };
  
  function showMore() {
    return (
      <Button
        color="secondary"
        variant="outlined"
        className={fileArrayAfterCut.length > 0 ? classes.showMore : classes.displayNone}
        onClick={() => {
          setShowMoreFiles(!showMoreFiles);
        }}
      >
        {showMoreFiles ? 'Show\nLess' : 'Show\nMore'}
      </Button>
    );
  };

  const downloadAllButton = (
    <Button
      disabled={!filenames.length || downloadFilesLoad}
      className={classes.downloadFiles}
      color="default"
      variant="outlined"
      size="small"
      onClick={handleDownloadAll}
    >
      <GetAppIcon fontSize="small" />
      &nbsp;{downloadFilesLoad ? 'Please wait...' : 'Download All'}
    </Button>
  );

  const gridSize = 4;

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        size="large"
        className={`${classes.openButtton} ${goToTab !== 0 ? classes.ifNotCorrespondence : classes.ifCorrespondence}`}
        onClick={() => {
          handleClickOpen();
          getCommentTask(tracking.id);
        }}
      >
        {buttonTitle}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <div className={classes.headers1}>
          <Button
            className={classes.buttonLowerCase}
            size="medium"
            onClick={handleClickShow}
          >
            N° suivi: {Object.values(tracking).length ? tracking.tracking_numbers.replace(/<br\/>/g, ", ") : ''}
          </Button>
          <ClickAwayListener
            onClickAway={handleCopyTitle}
          >
            <Tooltip
              title="Copié"
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleCopyTitle}
              open={copyTitle}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Button
                className={classes.colorGray}
                onClick={() => {
                  navigator.clipboard.writeText(tracking.tracking_numbers.replace(/<br\/>/g, " "));
                  setCopyTitle(true);
              }}>
                copie
              </Button>
            </Tooltip>
          </ClickAwayListener>
          <br />
        </div>
        {show ? showMoreInfo : null}
        <DialogTitle id="customized-dialog-title" className={classes.dialogTitle}>
            <Grid
              container 
              spacing={1}
              direction="row"
              justifyContent="space-around"
              alignItems="flex-start"
              align="center"
              className={classes.tooltip}
            >
              <Grid item xs={12} className={classes.tabs}>
                <Tabs
                  value={tabsValue}
                  indicatorColor="secondary"
                  textColor="secondary"
                  onChange={tabsChange}
                >
                  <Tab label="Tous" />
                  <Tab label="Transporteur" />
                  <Tab label="Assurance" />
                  <Tab label="Client" />
                </Tabs>
              </Grid>
              <Grid item xs={gridSize} className={tabsValue === 0 ? classes.displayNone : ''}>Ecrire un email</Grid>
              <Grid item xs={gridSize} className={tabsValue === 0 ? classes.displayNone : ''}>Ajouter une tâche</Grid>
              <Grid item xs={gridSize} className={tabsValue === 0 ? classes.displayNone : ''}>Ajouter un commentaire</Grid>
              <Grid item xs={gridSize} className={tabsValue === 0 ? classes.displayNone : ''}>
                <AddCircleOutlineRoundedIcon className={classes.diasebledAddMail} disabled />
              </Grid>
              <Grid item xs={gridSize} className={tabsValue === 0 ? classes.displayNone : ''}>
                <TaskForm postCommentTask={postCommentTask2} />
              </Grid>
              <Grid item xs={gridSize} className={tabsValue === 0 ? classes.displayNone : ''}>
                <CommentForm postCommentTask={postCommentTask2} />
              </Grid>
            </Grid>
          <Divider />
          <div>
            <div className={classes.subHeader}>
              <Typography className={classes.headers2}>
                Tâches à faire ({countUndoneTasks(countCommentsTasks, tracking.id, tabsValue)})
              </Typography>
              {tabsValue === 0 ? downloadAllButton : null}
            </div>
            {filesArea(fileArrayBeforeCut)}
            {showMore()}
            {showMoreFiles && filesArea(fileArrayAfterCut)}
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.bodyHeight}>
            {newsFeedListPinned}
            {comments.filter(comment => (comment.with === tabsValue)).filter(el => (el.pinned !== 0)).length > 0 ? <Divider className={classes.dividerPinned} /> : null}
            {newsFeedList}
          </div>
        </DialogContent>
        {(tabsValue === 3) ? <MessageFormChat postCommentTask={postCommentTask2} /> : null}
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    users: state.users,
    countCommentsTasks: state.countCommentsTasks,
    issuesCardRefresh: state.issuesCardRefresh,
    refresh: state.refresh,
    downloadFilesLoad: state.downloadFilesLoad,
  };
};

export default connect(mapStateToProps, {
  getTaskComment,
  setRefreshCard,
  setRefresh,
  downloadFiles,
})(CorrespondencesPopUp);
