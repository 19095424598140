import { INVOICE_EDIT_RESPONSE } from '../actions/types';


const invoiceEditResponse = (state = {}, action) => {
  switch(action.type) {
    case INVOICE_EDIT_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};


export default invoiceEditResponse;
