import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import ListIcon from '@material-ui/icons/List';
// import SortIcon from '@material-ui/icons/Sort';
import SvgIcon from '@material-ui/core/SvgIcon';
import { URL_All } from '../common/Constants';
// Rien à signaler
import { setRefresh } from '../actions';


const useStyles = makeStyles((theme) => ({
  sortBtn: {
    width: '100%',
    textTransform: "none",
    padding: theme.spacing(.25),
    // textOverflow: 'ellipsis',
    '& .MuiButton-label': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      justifyContent: 'left',
    },
  },
  filterIcon: {
    paddingRight: theme.spacing(1),
    color: '#817c7c',
    // fontWeight: 300,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    // width: 150,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
    //   backgroundColor: theme.palette.action.disabledBackground,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#000', // color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function FilterIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M15,19.88C15.04,20.18 14.94,20.5 14.71,20.71C14.32,21.1 13.69,21.1 13.3,20.71L9.29,16.7C9.06,16.47 8.96,16.16 9,15.87V10.75L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L15,10.75V19.88M7.04,5L11,10.06V15.58L13,17.58V10.05L16.96,5H7.04Z" />
      </SvgIcon>
    );
}

function StatusFilter(props) {
  const { setRefresh,
    refresh,
    filterStatusByID,
    setFilterStatusByID
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [titleName, setTitleName] = useState('Status (All)');
  const [statusList, setStatusList] = useState([]);  

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getStatusList() {
      fetch(URL_All.Statuslists)
        .then(res => res.json())
        .then(json => setStatusList(json))
        .catch(err => console.error(err))
  };

  function getStatusshipments(params) {
    fetch(`${URL_All.getStatusshipments}?status_id=${params}`)
      .then(() => setRefresh(refresh + 1))
      .catch(err => console.error(err))
  }

  return (
    <div>
      <Button
        className={classes.sortBtn}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        color="default"
        fontSize="small"
        onClick={(e) => {
            setAnchorEl(e.currentTarget);
            getStatusList();
        }}
      >
        <FilterIcon className={classes.filterIcon} fontSize="medium" />{titleName}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
            onClick={() => {
              handleClose();
              setTitleName('Status (All)');
              setFilterStatusByID(0);
              getStatusshipments(0);
            }}
            selected={filterStatusByID === 0 ? true : false}
        >
          <ListItemText primary="Status (All)" />
        </StyledMenuItem>
        {statusList.map(status => (
            <StyledMenuItem
                key={status.id}
                onClick={() => {
                  handleClose();
                  setTitleName(status.name);
                  setFilterStatusByID(status.id);
                  getStatusshipments(status.id);
                }}
                selected={filterStatusByID === status.id ? true : false}
            >
            <ListItemText primary={`${status.name}`} />
            </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}

const mapStateToProps = state => {
  return { refresh: state.refresh };
};

export default connect(mapStateToProps, { setRefresh })(StatusFilter);
