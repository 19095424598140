import { DASHBOARD_CALENDAR_LOADING } from '../actions/types';

const dashboardCalendarLoading = (state = false, action) => {
    switch (action.type) {
        case DASHBOARD_CALENDAR_LOADING:
            return action.payload;
        default:
            return state;
    }
};

export default dashboardCalendarLoading;
