import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import SortDate from './SortDate';
import InputAdornment from '@material-ui/core/InputAdornment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import StatusFilter from './StatusFilter';
import { setTrackingsFilter } from '../actions';
import { BUTTON_NAMES } from '../reducers/trackingsFilter';


const useStyles = makeStyles((theme) => ({
  phoneEmail: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "1rem",
  },
  center: {
    textAlign: "center",
  },
  textInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center",
    height: '1.5rem',
    width: "90%",
    borderRadius: "4px",
    border: "1px gray solid",
  },
  addButton: {
      margin: theme.spacing(0),
  },
  search: {
    width: '90%',
    "& .MuiIconButton-root": {
        padding: 0,
        color: "gray",
    },
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  allLeft: {
    justifyContent: "flex-start",
  },
  carriers: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function TrackingHead(props) {
    const classes = useStyles();
    let { searchInput,
        setMySearch,
        setSortByDate,
        handleAllBtn,
        todayCheckedCount,
        trackingsCount,
        isClickedCheckToday,
        setClickedCheckToday,
        isClickedHighlight,
        setClickedHighlight,
        highlightCount,
        filterStatusByID,
        setFilterStatusByID,
        trackingsFilter,
        setTrackingsFilter,
    } = props;
    const history = useHistory();
    const query = useLocation().search;

    const ifNotCheckedToday = ('Not checked today: ' + ((trackingsCount - todayCheckedCount) >= 0 ? (trackingsCount - todayCheckedCount) : 0) + ', Total: ' + trackingsCount);

    const ifCheckedToday = ('Not checked today: ' + trackingsCount);

    function changeUrl() {
        if(query !== '') {
            return history.push('/tracking');
        }
    }

    const handleFilterButtons = props => () => {
        if((props === BUTTON_NAMES.all) && !trackingsFilter[props]) {
            return handleAllBtn();
        }

        const carriersArr = [
            BUTTON_NAMES.fedex,
            BUTTON_NAMES.ups,
            BUTTON_NAMES.tnt,
            BUTTON_NAMES.aramex,
            BUTTON_NAMES.spring,
            BUTTON_NAMES.transnat,
            BUTTON_NAMES.fretCargo,
        ];
        if((props !== BUTTON_NAMES.all) && trackingsFilter.all && carriersArr.includes(props)) {
            return setTrackingsFilter({ ...trackingsFilter,
                [props]: !trackingsFilter[props],
                [BUTTON_NAMES.all]: false,
                [BUTTON_NAMES.fr]: true,
                [BUTTON_NAMES.en]: true,
                [BUTTON_NAMES.de]: true,
                [BUTTON_NAMES.export]: true,
                [BUTTON_NAMES.import]: true,
                [BUTTON_NAMES.domestic]: true,
            });
        }

        const LangArr = [
            BUTTON_NAMES.fr,
            BUTTON_NAMES.en,
            BUTTON_NAMES.de,
        ];
        if((props !== BUTTON_NAMES.all) && trackingsFilter.all && LangArr.includes(props)) {
            return setTrackingsFilter({ ...trackingsFilter,
                [props]: !trackingsFilter[props],
                [BUTTON_NAMES.all]: false,
                [BUTTON_NAMES.fedex]: true,
                [BUTTON_NAMES.ups]: true,
                [BUTTON_NAMES.tnt]: true,
                [BUTTON_NAMES.aramex]: true,
                [BUTTON_NAMES.spring]: true,
                [BUTTON_NAMES.transnat]: true,
                [BUTTON_NAMES.fretCargo]: true,
                [BUTTON_NAMES.export]: true,
                [BUTTON_NAMES.import]: true,
                [BUTTON_NAMES.domestic]: true,
            });
        }

        const expImpArr = [
            BUTTON_NAMES.export,
            BUTTON_NAMES.import,
            BUTTON_NAMES.domestic,
        ];
        if((props !== BUTTON_NAMES.all) && trackingsFilter.all && expImpArr.includes(props)) {
            return setTrackingsFilter({ ...trackingsFilter,
                [props]: !trackingsFilter[props],
                [BUTTON_NAMES.all]: false,
                [BUTTON_NAMES.fedex]: true,
                [BUTTON_NAMES.ups]: true,
                [BUTTON_NAMES.tnt]: true,
                [BUTTON_NAMES.aramex]: true,
                [BUTTON_NAMES.spring]: true,
                [BUTTON_NAMES.transnat]: true,
                [BUTTON_NAMES.fretCargo]: true,
                [BUTTON_NAMES.fr]: true,
                [BUTTON_NAMES.en]: true,
                [BUTTON_NAMES.de]: true,
            });
        }

        // if((props !== BUTTON_NAMES.all) && trackingsFilter.all) {
        //     return setTrackingsFilter({ ...trackingsFilter, [props]: !trackingsFilter[props], [BUTTON_NAMES.all]: false });
        // }

        return setTrackingsFilter({ ...trackingsFilter, [props]: !trackingsFilter[props] });
    };
    // console.log('true: ', Object.values(trackingsFilter).filter(flt => flt).length)
    // console.log('false: ', Object.values(trackingsFilter).filter(flt => !flt).length)
    // console.log(trackingsFilter);

    return (
    <div>
        <Grid 
            container
            spacing={1}
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
        >

            <Grid item xs={2}>
                <form noValidate autoComplete="off" className={classes.center}>
                    <Typography className={classes.phoneEmail} variant="body2" gutterBottom>0848 133 339</Typography>
                    <Link className={classes.phoneEmail} href="mailto:switzerland@fedex.com">switzerland@fedex.com</Link>
                    {trackingsFilter.fedex ? <input className={classes.textInput} type="text" placeholder="Numéros FedEx internat..." /> : undefined}
                </form>
            </Grid>

            <Grid item xs={3}>
                <Grid container spacing={1} className={classes.allLeftb} alignItems="flex-start">
                    <Grid item xs={2} />
                    <Grid item xs={3}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={trackingsFilter.all ? "contained" : "outlined"}
                            color={trackingsFilter.all ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.all)}
                        >
                            Tout
                        </Button>
                    </Grid>
                    <Grid item xs={7} />

                    <Grid item xs={2} />
                    <Grid item xs={3}>
                        <Button
                            size="small"
                            variant={trackingsFilter.fr ? "contained" : "outlined"}
                            color={trackingsFilter.fr ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.fr)}
                        >
                            FR
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            size="small"
                            variant={trackingsFilter.en ? "contained" : "outlined"}
                            color={trackingsFilter.en ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.en)}
                        >
                            EN
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            size="small"
                            variant={trackingsFilter.de ? "contained" : "outlined"}
                            color={trackingsFilter.de ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.de)}
                        >
                            DE
                        </Button>
                    </Grid>
                    <Grid item xs={1} />

                    <Grid item xs={2} />
                    <Grid item xs={3}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={trackingsFilter.export ? "contained" : "outlined"}
                            color={trackingsFilter.export ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.export)}
                        >
                            Export
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={trackingsFilter.import ? "contained" : "outlined"}
                            color={trackingsFilter.import ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.import)}
                        >
                            Import
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={trackingsFilter.domestic ? "contained" : "outlined"}
                            color={trackingsFilter.domestic ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.domestic)}
                        >
                            Domestic
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={5}>
                <Grid 
                    container 
                    spacing={1}
                >
                    <Grid item xs={11} className={classes.carriers}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={trackingsFilter.fedex ? "contained" : "outlined"}
                            color={trackingsFilter.fedex ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.fedex)}
                        >
                            FedEx
                        </Button>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={trackingsFilter.ups ? "contained" : "outlined"}
                            color={trackingsFilter.ups ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.ups)}
                        >
                            UPS
                        </Button>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={trackingsFilter.tnt ? "contained" : "outlined"}
                            color={trackingsFilter.tnt ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.tnt)}
                        >
                            TNT
                        </Button>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={trackingsFilter.aramex ? "contained" : "outlined"}
                            color={trackingsFilter.aramex ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.aramex)}
                        >
                            Aramex
                        </Button>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={trackingsFilter.spring ? "contained" : "outlined"}
                            color={trackingsFilter.spring ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.spring)}
                        >
                            Spring
                        </Button>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={trackingsFilter.transnat ? "contained" : "outlined"}
                            color={trackingsFilter.transnat ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.transnat)}
                        >
                            Transnat
                        </Button>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={trackingsFilter.fretCargo ? "contained" : "outlined"}
                            color={trackingsFilter.fretCargo ? "secondary" : "default"}
                            onClick={handleFilterButtons(BUTTON_NAMES.fretCargo)}
                        >
                            FretCargo
                        </Button>
                    </Grid>
                    <Grid item xs={1} />

                    <Grid item xs={2}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant="contained"
                            disabled
                        >
                            Normal
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant="contained"
                            disabled
                        >
                            Premium
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant="contained"
                            disabled
                        >
                            Gold
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <StatusFilter
                            filterStatusByID={filterStatusByID}
                            setFilterStatusByID={setFilterStatusByID}
                        />
                    </Grid>
                    <Grid item xs={3} />
                    
                    <Grid item xs={6}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={isClickedCheckToday ? "contained" : "outlined"}
                            color={isClickedCheckToday ? "secondary" : "default"}
                            onClick={() => {
                                setClickedCheckToday(!isClickedCheckToday);
                                setClickedHighlight(false);
                            }}
                        >
                            {isClickedCheckToday ? ifCheckedToday : ifNotCheckedToday}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={isClickedHighlight ? "contained" : "outlined"}
                            color={isClickedHighlight ? "secondary" : "default"}
                            onClick={() => {
                                setClickedHighlight(!isClickedHighlight);
                                setClickedCheckToday(false);
                            }}
                        >
                            Highlighted: {highlightCount}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={2}>
                <Grid 
                    container 
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                            <TextField 
                                size="small" 
                                id="outlined-basic" 
                                label="Rechercher…" 
                                variant="outlined" 
                                className={classes.search}
                                // endAdornment={<InputAdornment position="end">$</InputAdornment>}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                        ev.preventDefault();
                                        // if(searchInput.length > 0) {
                                        //     changeUrl(`/tracking?search=${searchInput}`);
                                        // } else if (searchInput.length = 0) {
                                        //     changeUrl('/tracking');
                                        // }
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {searchInput.length > 0 ? (<IconButton onClick={() => {
                                            setMySearch(''); 
                                            changeUrl();
                                        }}>
                                            <HighlightOffIcon size="small" />
                                        </IconButton>) : ''}
                                      </InputAdornment>
                                    ),
                                  }}
                                value={searchInput}
                                onChange={e => {
                                    setMySearch(e.target.value);
                                    changeUrl();
                                }}
                            />
                        </form>
                    </Grid>
                    <Grid item xs={12}>
                        <SortDate
                            setSortByDate={setSortByDate}
                        />
                    </Grid>
                
                </Grid>
            </Grid>
        </Grid>
    </div>
    );
}

const mapStateToProps = state => {
    return {
        trackingsFilter: state.trackingsFilter,
        trackingsCount: state.trackingsCount,
    };
};

export default connect(mapStateToProps, { setTrackingsFilter })(TrackingHead);
