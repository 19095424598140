import { CONTACT_REQUEST } from '../actions/types';

const contactRequest = (state = [], action) => {
    switch (action.type) {
        case CONTACT_REQUEST:
            return action.payload;
        default:
            return state;
    }
};

export default contactRequest;
