import { INVOICE_OVERCHARGING_LOADING } from '../actions/types';


const invoiceOverchargingLoading = (state = false, action) => {
  switch(action.type) {
    case INVOICE_OVERCHARGING_LOADING:
      return action.payload;
    default:
      return state;
  }
};


export default invoiceOverchargingLoading;
