import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { postIssue,
  makeShipmentAnIssue,
  editIssue,
  fromIssuesToTrackings,
  deletedShipment,
} from '../actions';
import { convertDateToTimestamp, currentUserId } from '../common/functions';
import useToken from '../login/useToken';
import { logOut } from '../common/Frame';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        '& form': {
            textAlign: 'center',
        },
    },
    dialogTitle: {
        marginTop: theme.spacing(1),
    },
    formGroup: {
        margin: 'auto',
        padding: theme.spacing(2),
        width: 'fit-content',
    },
    menuIcon: {
      color: theme.palette.grey[700],
    },
    formControl: {
      margin: theme.spacing(3),
    },
    formControlDiv: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      textAlign: 'center',
  
      '& .MuiFormControlLabel-root': {
        marginBottom: theme.spacing(1),
      },
    },
    radioGroup: {
      marginBottom: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1, 1, 0, 0),
    },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function ArchivePopUpRecovery(props) {
  const classes = useStyles();
  const { token } = useToken();
  const { shipmentId,
    recoveryShipment,
    recoveryStatusAndSendToTracking,
    isIssue,
    postIssue,
    makeShipmentAnIssue,
    currentUser,
    refreshManually,
    users,
    editIssue,
    fromIssuesToTrackings,
    deletedShipment,
    deleteShipment,
    deleteStatusAndSendToArvhive,
  } = props;
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [value, setValue] = useState('1');
  const [typeIssue, setTypeIssue] = useState('1');
  const [dateTransporteur, setDateTransporteur] = useState('');
  const [dateClient, setDateClient] = useState('');
  const [dateAssurance, setDateAssurance] = useState('');
  const [radioValue, setRadioValue] = useState('1');
  
  const username = localStorage.getItem('username');
  if(!username) logOut();
  
  const handleClickOpen = () => {
    setOpen(true);

    if(isIssue) {
      setValue('1');
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleRadioChange2 = (event) => {
    setTypeIssue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (value === '1') {
      recoveryShipment('/recover');
      recoveryStatusAndSendToTracking('Recovered');
      
    } else if (value === '2') {
      recoveryShipment('/recover');
      recoveryStatusAndSendToTracking('Recovered');

      postIssue(shipmentId, convertDateToTimestamp(dateClient), convertDateToTimestamp(dateAssurance), convertDateToTimestamp(dateTransporteur), parseInt(typeIssue), currentUser);
      makeShipmentAnIssue(shipmentId, token);
      refreshManually();
    } else if (value === '3') {
      deletedShipment(token, shipmentId);      
    }
  };

  const issueForm = (
    <Grid container className={classes.issueForm}>
      <Grid item xs={6} className={classes.gridDates}>
        <TextField
          error={!(dateTransporteur || dateClient || dateAssurance)}
          id="dateTo"
          className={classes.datePicker}
          label="Client:"
          type="date"
          value={dateClient}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setDateClient(e.target.value)}
        />
        <TextField
          error={!(dateTransporteur || dateClient || dateAssurance)}
          id="dateTo"
          className={classes.datePicker}
          label="Assurance:"
          type="date"
          value={dateAssurance}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setDateAssurance(e.target.value)}
        />
        <TextField
          error={!(dateTransporteur || dateClient || dateAssurance)}
          id="dateTo"
          className={classes.datePicker}
          label="Transporteur:"
          type="date"
          value={dateTransporteur}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setDateTransporteur(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <RadioGroup
          value={typeIssue}
          onChange={handleRadioChange2}
        >
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label="Endommagé"
          />
          <FormControlLabel
            value="2"
            control={<Radio color="primary" />}
            label="Perdu"
          />
          <FormControlLabel
            value="3"
            control={<Radio color="primary" />}
            label="Surfacturation"
          />
          <FormControlLabel
            value="4"
            control={<Radio color="primary" />}
            label="Autre"
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );

  const handleRadioChangeIssues = (event) => {
    setRadioValue(event.target.value);
  };

  // Move back to Tracking/Archive page 
  const handleSave = () => {
    if(radioValue === '1') {
      deleteShipment('/hide/');
      deleteStatusAndSendToArvhive('Hide');
    } else if(radioValue === '2') {
      editIssue(shipmentId, 0, currentUserId(users), {});
      fromIssuesToTrackings(shipmentId, token);
    }

    refreshManually();
    handleClose();
  };

  if(isIssue) {
    return (
      <div>
        <MenuIcon
          fontSize="large"
          onClick={handleClickOpen}
          className={classes.menuIcon}
        />
        <Dialog
          fullWidth={fullWidth}
          maxWidth="sm"
          onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}
        >
          <div className={classes.root}>
            <DialogTitle id="customized-dialog-title" className={classes.dialogTitle} onClose={handleClose}>
              Que veux-tu faire {username} ?
            </DialogTitle>
            <Divider />
            <div className={classes.formControlDiv}>
              <FormControl className={classes.formControl}>
                <RadioGroup
                  className={classes.radioGroup}
                  value={radioValue}
                  onChange={handleRadioChangeIssues}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Close the Case"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Remove from Issues"
                  />
                </RadioGroup>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSave}
                >
                  Valider
                </Button>
              </FormControl>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }

  return (
    <div>
        <MenuIcon
          fontSize="large"
          onClick={handleClickOpen}
          className={classes.menuIcon}
        />
        <Dialog fullWidth={fullWidth} maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <div className={classes.root}>
                <DialogTitle id="customized-dialog-title" className={classes.dialogTitle} onClose={handleClose}>
                    Que veux-tu faire {username} ?
                </DialogTitle>
                <Divider />
                <form onSubmit={handleSubmit}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="quiz"
                    name="quiz"
                    value={value}
                    onChange={handleRadioChange}
                  >
                  <FormControlLabel
                    disabled={isIssue}
                    value="1"
                    control={<Radio />}
                    label="Afficher le tracking"
                  />
                  <FormControlLabel
                    disabled={isIssue}
                    value="2"
                    control={<Radio />}
                    label="Issue"
                  />

                  {(value === '2' ? issueForm : null)}

                  <FormControlLabel
                    disabled={isIssue}
                    value="3"
                    control={<Radio />}
                    label="Delete Shipment and Invoice"
                  />
                  </RadioGroup>
                  <Button
                    disabled={isIssue}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={handleClose}
                  >
                    Valider
                  </Button>
                </FormControl>
                </form>
            </div>
        </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return { users: state.users };
};

export default connect(mapStateToProps, {
  postIssue,
  makeShipmentAnIssue,
  editIssue,
  fromIssuesToTrackings,
  deletedShipment,
})(ArchivePopUpRecovery);
