import { INVOICE_SHIP_ID_LOADING } from '../actions/types';


const invoiceShipIdLoading = (state = false, action) => {
  switch(action.type) {
    case INVOICE_SHIP_ID_LOADING:
      return action.payload;
    default:
      return state;
  }
};


export default invoiceShipIdLoading;
