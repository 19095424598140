import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// last test


const useStyles = makeStyles((theme) => ({
    autocomplete: {
        padding: theme.spacing(0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('md')]: {
          width: '45ch',
        },
        width: '45ch',
        // backgroundColor: '#122230',
        color: 'inherit',
        '& .MuiFormLabel-root': {
          color: 'gray',
          '&:focus': {
            color: '#f3f3f3 !important',
          },
        },
        '& input': {
          color: '#f3f3f3',
        },
        '& .MuiSvgIcon-root': {
          color: 'gray',
        },
        '& .Mui-focused': {
          '& .MuiSvgIcon-root': {
            color: '#fff',
          },
        },
        '& fieldset': {
          border: 'none',
        },
        '& .MuiInputLabel-shrink': {
          color: '#f3f3f3',
        },
        '& .MuiAutocomplete-clearIndicatorDirty': {
          '& .MuiSvgIcon-root': {
            color: '#f3f3f3',
          },
        },
        '& .MuiAutocomplete-popupIndicator': {
          '& .MuiSvgIcon-root': {
            '&:hover': {
              color: '#f3f3f3',
            },
          },
        },
    },
}));

export default function GeneralSearch(props) {
  const classes = useStyles();
  const { customerList,
    customerListError,
    customerListLoading,
    sestSearchTerm,
    autocompleteValue,
    setAutocompleteValue,
    setCustomerList,
    setStopSearchCustomers,
    getCustomerByID,
    handleClearAll
  } = props;
  const history = useHistory();
  
  function goToUrl(url) {
    return history.push(url);
  }

  // useEffect(() => {
  //   return history.listen((location) => {
  //     if(location.pathname !== '/customer-account') clearAll();
  //   });
  // },[history, clearAll]);

  return(<Autocomplete
    id="combo-box-demo2"
    className={classes.autocomplete}
    size="small"
    // getOptionSelected={(option, value) => option.id === value.id}
    autoHighlight
    options={customerList}
    value={autocompleteValue}
    filterOptions={(options, state) => options}
    getOptionLabel={options => (
      (customerList.length) === 0 ? ''
      : options.company_name === undefined ? ''
      : options.company_name === null ? options.users[0].user.username
      : options.company_name.trim() === '' ? options.users[0].user.username
      : `${options.company_name} ${options.users.length > 1 ? `(${options.users.length})` : ''}`
    )}
    onChange={(e, v, r) => {
      if((r === 'select-option') && (v !== null)) {
        getCustomerByID(v.id);
        setAutocompleteValue(v);
        goToUrl('/customer-account');
    } else if (r === 'clear') {
      handleClearAll();
    }
    }}
    renderInput={(params) => <TextField
      {...params}
      error={customerListError}
      label={customerListError ? 'Error, please reload page' : customerListLoading ? 'loading...' : 'Rechercher…'}
      variant="outlined"
      onChange={(e) => {
        if(e.target.value.length > 2) {
          setStopSearchCustomers(false);
          sestSearchTerm(e.target.value);
        } else if(e.target.value.length === 0) {
          setStopSearchCustomers(true);
          setCustomerList([]);
        }
      }}
    />}
  />);
}
