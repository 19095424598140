import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Frame from './common/Frame';
import LoginPage from './login/LoginPage';
import useToken from './login/useToken';
import './css/app.css';


const THEME = createTheme({
  typography: {
   fontFamily: `"Calibri", sans-serif`,
   fontSize: 16,
   fontWeightLight: 300,
   fontWeightRegular: 400,
   fontWeightMedium: 500,
  },
});

function App() {
  const { token, setToken } = useToken();
  const [stateSnackbar, setSnackbar] = useState({
		openSnackbar: false,
		vertical: 'bottom',
		horizontal: 'left',
	}); 

  const handleOpenSnackbar = () => {    
    setSnackbar({ ...stateSnackbar, openSnackbar: true });
  };
  
  const handleCloseSnackbar = () => {
		setSnackbar({ ...stateSnackbar, openSnackbar: false });
	};

  if(!token) {
    return <LoginPage
      setToken={setToken}
      handleOpenSnackbar={handleOpenSnackbar}
    />
  }

  return (
    <div id="App">
      <MuiThemeProvider theme={THEME}>
        <BrowserRouter>
          <Frame
            stateSnackbar={stateSnackbar}
            handleCloseSnackbar={handleCloseSnackbar}
          />
        </BrowserRouter>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
