import { SHIPMENTS_DASHBOARD_LOADING } from '../actions/types';

const dashboardShipmentsLoading = (state = false, action) => {
    switch (action.type) {
        case SHIPMENTS_DASHBOARD_LOADING:
            return action.payload;
        default:
            return state;
    };
};

export default dashboardShipmentsLoading;
