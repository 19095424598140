import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ChatHeader from './ChatHeader';
import ChatBody from './ChatBody';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { URL_All } from '../common/Constants';


const useStyles = makeStyles((theme) => ({
      stickyHead: {
        position: 'sticky',
        top: theme.spacing(-1),
        zIndex: 1,
      },
      paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        background: '#F7F7F7',
      },
  }));

function ChatController(props) {
    const classes = useStyles();
    const { statusObjectValues,
      refresh,
    } = props;
    const [isClickedHighlight, setClickedHighlight] = useState(false);
    const [statusValue, setStatusValue] = useState({});
    const [highlightCount, setHighlightCount] = useState([]);
    const [refreshKey,setRefreshKey] = useState(0);
    const [pageZero, setPageZero] = useState(0);

    useEffect(() => {
      fetch(URL_All.getStatusshipments)
          .then(response => response.json())
          .then(json => {
            setStatusValue(json);
            setHighlightCount(Object.values(json).filter(r => (r.highlighted === 1 & r.is_actif === 0)).map(g => g.shipment_id));
          })
          .catch(error => console.error(error))
    }, [setStatusValue, setHighlightCount, refreshKey, refresh]);

  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => { 
      location.pathname === '/messaging' && handleAllBtn();
    })
  },[history]);

  function handleAllBtn(){
      return [
        setClickedHighlight(false)
      ];
  }

  return (<div>
      <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12} className={classes.stickyHead}>
              <Paper className={classes.paper}>
                  <ChatHeader
                      setPageZero={setPageZero}
                  />
              </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
              <ChatBody
                  refreshKey={refreshKey}
                  setRefreshKey={setRefreshKey}
                  isClickedHighlight={isClickedHighlight}
                  statusValue={statusValue}
                  highlightCount={highlightCount}
                  statusObjectValues={statusObjectValues}
                  pageZero={pageZero}
              />
          </Grid>
      </Grid>
  </div>);
}

const mapStateToProps = state => {
  return { refresh: state.refresh };
};

export default connect(mapStateToProps)(ChatController);
