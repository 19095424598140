import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import ShipmentDisplay from './ShipmentDisplay';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  openOnClick: {
    '&:hover': {
      cursor: 'pointer',
    },

    '& .MuiTableCell-body': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  tableDisplayShipment: {
    background: '#F7F7F7',
  },
  tableHead:{
    background: '#727272',
    '& th': {
      textAlign: 'center',
      color: '#fff',
      fontWeight: '700',
    },
  },
  differentRows: {
    overflowWrap: 'anywhere',
    '& td': {
      textAlign: 'center',
    },
    '& .none': {
      backgroundColor: 'inherit',
    },
    '& .no_quotes': {
      background: '#ff8282',
    },
    '& .ok': {
      background: '#c5fac5',
    },
    '& .tracked_by_recipient': {
      background: '#ffe56b',
    },
    '& .discounted': {
      background: '#c6d5a3',
    },
    '& .pickup_error': {
      background: '#87dcea',
    },
    '& .payment_not_done': {
      background: '#e3b7e3',
    },
    '& .quote_selected': {
      background: '#beb9b9',
    },
    '& .greenIcon': {
      color: 'green',
    },
    '& .checkCircleIcon': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  infoShipmentColumn: {
    textAlign: 'left !important',
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function ShipmentsOpenSelected(props) {
  const classes = useStyles();
  const { shipment } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <TableRow onClick={handleClickOpen} className={classes.openOnClick}>
        <TableCell>
            {(shipment.admin_status === "ok") ? "RESERVATION OK"
            : (shipment.admin_status === "tracked_by_recipient") ? "FINALISATION ENVOI"
            : (shipment.admin_status === "none") ? "SHIPMENT CREE"
            : (shipment.admin_status === "quote_selected") ? "OFFRE CHOISIE"
            : (shipment.admin_status === "payment_not_done") ? "PAIEMENT"
            : (shipment.admin_status === "no_quotes") ? "PAS D’OFFRES"
            : (shipment.admin_status === "discounted") ? "DISCOUNTED"
            : (shipment.admin_status === "pickup_error") ? "PICKUP ERROR"
            : ""}
        </TableCell>
        <TableCell>{!shipment.user ? '' : shipment.user.length > 25 ? `${shipment.user.slice(0, 25)}...` : shipment.user}</TableCell>
        <TableCell>{shipment.creation.slice(11, 16)}</TableCell>
      </TableRow>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Selected Shipment
        </DialogTitle>
        <DialogContent dividers>
          <Table className={classes.tableDisplayShipment}>
            <colgroup>
              <col span="1" style={{width: "12%"}} />
              <col span="1" style={{width: "10%"}} />
              <col span="1" style={{width: "10%"}} />
              <col span="1" style={{width: "18%"}} />
              <col span="1" style={{width: "18%"}} />
              <col span="1" style={{width: "10%"}} />
              <col span="1" style={{width: "12%"}} />
              <col span="1" style={{width: "10%"}} />
            </colgroup>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell className={classes.infoShipmentColumn}>Info Shipment</TableCell>
                <TableCell>Création du shipment</TableCell>
                <TableCell>Date pickup</TableCell>
                <TableCell>Adresse d’enlèvement</TableCell>
                <TableCell>Adresse de livraison</TableCell>
                <TableCell>Type d’envoi</TableCell>
                <TableCell>Transporteur</TableCell>
                <TableCell>Fonctionnalités</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.differentRows}>
              <ShipmentDisplay shipment={shipment} />                  
            </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

// const mapStateToProps = state => {
//   return { users: state.users, refresh: state.refresh };
// };

export default connect(null)(ShipmentsOpenSelected);
