import { STATISTICS_LOADING_PARETO } from '../actions/types';

const initialState = {
  groupOnePareto: false,
  n1Pareto: false,
  n2Pareto: false,
  n3Pareto: false,
};

const statisticsLoadingPareto = (state = initialState, action) => {
  switch (action.type) {
    case STATISTICS_LOADING_PARETO:
      return action.payload;
    default:
      return state;
  }
};

export default statisticsLoadingPareto;
