import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { setSnackbar } from '../actions';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SnackbarMessage(props) {
  const { snackbar, setSnackbar } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({ ...snackbar, open: false });
  };

  const severity = {
    0: 'error',
    1: 'success',
  };

  const message = {
    0: 'An error occurred',
    1: 'Done!',
  };

  return (
    <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity[snackbar.severity]}>
        {message[snackbar.severity]}
      </Alert>
    </Snackbar>
  );
}

const mapStateToProps = state => {
  return { snackbar: state.snackbar };
};

export default connect(mapStateToProps, { setSnackbar })(SnackbarMessage);
