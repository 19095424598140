import { STATISTICS_GENERAL_FILTER_BUTTONS } from '../actions/types';

const initialState = {
  y2022: true,
  twelveMonth: false,
  quarterFirst: false,
  quarterSecond: false,
  quarterThird: false,
  quarterFouth: false,
  daysSeven: true,
  daysOne: true,
  n1: false,
  n2: false,
  n3: false,
  pareto: false,
  paretoOfPareto: false,
  Normal: true,
  Premium: true,
  Gold: true,
};

const statisticsGeneralFilterButtons = (state = initialState, action) => {
  switch(action.type) {
    case STATISTICS_GENERAL_FILTER_BUTTONS:
      return action.payload;
    default:
      return state;
  }
};

export default statisticsGeneralFilterButtons;
