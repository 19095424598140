import { STATISTICS_GENERAL_CALL_API } from '../actions/types';

const statisticsGenCallApi = (state = 0, action) => {
  switch(action.type) {
    case STATISTICS_GENERAL_CALL_API:
      return action.payload;
    default:
      return state;
  }
};

export default statisticsGenCallApi;
