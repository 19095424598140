import { GET_SHIPMENT_TRANSPORT_DASHBOARD } from '../actions/types';

const dashboardShipmentTransport = (state = [], action) => {
    switch (action.type) {
        case GET_SHIPMENT_TRANSPORT_DASHBOARD:
            return action.payload;
        default:
            return state;
    }
};

export default dashboardShipmentTransport;
