import { STATISTICS_GENERAL_GROUP_OLDS } from '../actions/types';

const initialState = {
  n1: false,
  n2: false,
  n3: false,
};

// Here we group buttons to avoid call same api again and again
const statisticsGeneralGroupOlds = (state = initialState, action) => {
  switch(action.type) {
    case STATISTICS_GENERAL_GROUP_OLDS:
      return action.payload;
    default: 
      return state;
  }
};

export default statisticsGeneralGroupOlds;
