import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
    form: {
        '& > *': {
          margin: theme.spacing(1),
          width: '55ch',
        },
    },
}));

function StatusEditingForm(props) {
    const { statusName,
        editStatus
    } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [editedStatus,setEditedStatus] = useState(statusName);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleChange(e) {
        setEditedStatus(e.target.value);
    }

    return (
        <div>
          <Tooltip title="Edit" placement="top" onClick={handleClickOpen}>
              <IconButton>
                  <EditIcon />
              </IconButton>
          </Tooltip>
          <Dialog
            fullScreen={fullScreen}
            // fullWidth
            // maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="edit-status-dialog-title"
          >
            <DialogTitle id="edit-status-dialog-title">{"Edit status:"}</DialogTitle>
            <DialogContent>
                <form
                    id="editStatus"
                    className={classes.form}
                    noValidate
                    autoComplete="off"
                    onSubmit={(e) => {
                        e.preventDefault();
                        editedStatus.trim() !== '' && editedStatus !== statusName ? editStatus(editedStatus) : handleClose();
                    }}
                >
                    <TextField
                        id="outlined-basic"
                        autoFocus
                        label="Name"
                        variant="outlined"
                        type="text"
                        value={editedStatus}
                        onChange={handleChange}
                    />
                </form>
            </DialogContent>
            <DialogActions>
            <Button
                color="primary"
                type="submit"
                form="editStatus"
                onClick={handleClose}
            >
                Save
            </Button>
            <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
}

export default StatusEditingForm;