import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';


/********************
 * In this component should be used message & config props
  
  const message = 'lorem';

  const config = {
    color: '#0008ff',
    messageLength: 30,
    header: 'Additional Pickup Info'
  };

***/

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
    '& p': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  button: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(.75),
    fontWeight: '700',
    background: '#e6e5e5',
    textTransform: 'none',
    lineHeight: 'normal',
  },
  body: {
    margin: '24px 16px',
    textAlign: 'center',
  },
  buttomDivider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function PopUpInfo({ message, config }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  return (<div>
    <Tooltip title={message} placement="top">
      <Button
        fontSize="large"
        className={classes.button}
        style={{ color: config.color }}
        onClick={handleClickOpen}
      >
        {message.length < config.messageLength ? message : `${message.slice(0, config.messageLength)}...`}
      </Button>
    </Tooltip>
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle
        id="customized-dialog-title"
        className={classes.dialogTitle}
        onClose={handleClose}
      >
        {config.header}
      </DialogTitle>
      <Divider />
      <div className={classes.body}>
        <Typography>{message}</Typography >
        <Divider className={classes.buttomDivider} />
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          Done
        </Button>
      </div>
    </Dialog>
  </div>);
}
