import { REFRESH_ISSUES_PAGE } from '../actions/types';

const issuesPageRefresh = (state = 0, action) => {
    switch (action.type) {
        case REFRESH_ISSUES_PAGE:
            return action.payload;
        default:
            return state;
    }
};

export default issuesPageRefresh;
