import { C_R_FILTER_BUTTONS } from '../actions/types';

const initialState = {
  general: true,
  technicalProblem: true,
  discount: true,
  pallet: true,
  estimate: true,
  untreated: true,
  inProgress: true,
  ended: false,
};

const crFilterButtons = (state = initialState, action) => {
  switch(action.type) {
    case C_R_FILTER_BUTTONS:
      return action.payload;
    default:
      return state;
  }
};

export default crFilterButtons;
