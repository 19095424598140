import React, { useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { getMonthFrench } from './CustomerAccountController';
import { CustomerAccountContext } from '../contexts/CustomerAccountContext';
import ClientTransfer from './ClientTransfer';
import PaymentOptions from './PaymentOptions';
import PopUpCreate from './PopUpCreate';
import { GoToCustomerAccount } from '../shipments/ShipmentsBody';
import { urlExpedismart } from '../common/Constants';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 138,
        background: '#F7F7F7',
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(13.75),

        '&.paddingBottom140': {
            paddingBottom: theme.spacing(17),
        },
    },
    headRightSide: {
        textAlign: 'right',
    },
    search: {
        width: '100%',
    },
    selectPerson: {
        display: 'inline-flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingTop: 0,
    },
    selectPersonTextField: {
        width: '95%',
        '& option': {
            width: '25%',
        },
    },
    selectPersonTextFieldItalic: {
        width: '95%',
        fontStyle: 'italic',
        '& option': {
            fontStyle: 'normal',
            width: '25%',
        },
    },
    selectPerson2: {
        width: '100%',
    },
    selectPerson2Italic: {
        width: '100%',
        fontStyle: 'italic',
        '& option': {
            fontStyle: 'normal',
        },
    },
    headPhone: {
        display: 'inline-block',
        verticalAlign: 'middle',
    },
    textAlignCenter: {
        textAlignCenter: 'center',
    },
    marginTop8: {
        marginTop: theme.spacing(1),
    },
    creation: {
        marginTop: theme.spacing(1),
        paddingBottom: 0,
    },
    emailAndIcon: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,

        '& a': {
            display: 'inline-flex',
            color: '#333',
        },
    },
    invoiceCreateOffer: {
        display: 'flex',
        gap: '1em',
        padding: '.5em 0',
    },
}));


function CustomerAccountHeader() {
    const classes = useStyles();
    const { customerList,
        customerListLoading,
        customerListError,
        autocompleteValue,
        setAutocompleteValue,
        sestSearchTerm,
        setCustomerList,
        setStopSearchCustomers,
        customer,
        handleClearAll,
        getCustomerByID,
        salesPeople,
        conversionFunnelID,
        editSalesPerson,
        usersUserID,
        setUsersUserID
    } = useContext(CustomerAccountContext);

    const customerCreation = (param) => (`${new Date(param).getDate()} ${getMonthFrench(param)} ${new Date(param).getFullYear()}`);

    
    return (<Paper className={`${classes.root} ${(usersUserID < 0) ? '' : 'paddingBottom140'}`}>
        <Grid container spacing={2}>
            <Grid item xs={3} container spacing={1}>
                <Grid item xs={12} className={classes.creation}>
                    Création: {(customerListError || customerListLoading || Object.keys(customer).length === 0) ? null : customerCreation(customer.creation)}
                </Grid>
                <Grid item xs={12} className={classes.selectPerson}>
                    <TextField
                        select
                        size="small"
                        className={(customerListError || customerListLoading || Object.keys(customer).length === 0) ? classes.selectPersonTextFieldItalic : classes.selectPersonTextField}
                        value={usersUserID.toString()}
                        onChange={(e) => {
                            setUsersUserID(parseInt(e.target.value));
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        {customer.users === undefined ? null
                        : (customer.users.length > 1) ? (<option value={-1} defaultValue>
                            Company
                        </option>)
                        : null}
                        {(customerListError || customerListLoading || Object.keys(customer).length === 0) ? (<option value={-2} style={{ fontStyle: "italic" }}>
                                Empty
                            </option>) : (customer.users.sort(function(a, b){return b.is_admin - a.is_admin}).map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.user.first_name.trim() === '' ? item.user.username : item.user.first_name}
                                {item.is_admin ? ' (Admin)' : null}
                            </option>
                        )))}
                    </TextField>
                </Grid>
            </Grid>
            <Grid item xs={6} container>
                <Grid item xs={8}>
                    <Typography className={classes.headPhone}>{(customerListError || customerListLoading || (Object.keys(customer).length === 0) || (usersUserID < 0)) ? null : customer.users.filter(user => (user.id === usersUserID))[0].phone_number}</Typography>
                    {(customerListError || customerListLoading || (Object.keys(customer).length === 0) || (usersUserID < 0)) ? null : <ClientTransfer user={customer.users.filter(user => (user.id === usersUserID))[0]} />}
                    {(customerListError || customerListLoading || (Object.keys(customer).length === 0) || (usersUserID < 0)) ? null : <Typography className={classes.emailAndIcon}>{customer.users.filter(user => (user.id === usersUserID))[0].user.email}
                        <Tooltip
                            title="Prendre le contrôle du compte"
                            placement="top"
                        >
                            {(usersUserID > 0 && Object.keys(customer.users.filter(user => (user.id === usersUserID))[0].user).length) ? <a className={classes.hrefCustomerAccount} rel="noopener noreferrer" href={`${urlExpedismart}/admin/auth/user/${customer.users.filter(user => (user.id === usersUserID))[0].user.id}/change/`}target="_blank"><GoToCustomerAccount fontSize="small" /></a>
                            : <IconButton className={classes.noUser}><GoToCustomerAccount fontSize="small" /></IconButton>}
                        </Tooltip>
                    </Typography>}
                </Grid>
                <Grid item xs={4} className={classes.textAlignCenter}>
                    <PaymentOptions />
                </Grid>
                <Grid item xs={8}>
                    <p className={classes.marginTop8}>
                        <strong>Adresse de facturation:</strong> {customer.billing_address}
                    </p>
                </Grid>
                <Grid item xs={4}>
                    {(customerListError || customerListLoading || (Object.keys(customer).length === 0) || (usersUserID < -1)) ? null
                    : (customer.users && (usersUserID === -1)) ? <div className={classes.invoiceCreateOffer}>
                        <PopUpCreate
                            createInvoice={true}
                            title="Création facture (Admin)"
                            altText="Create Invoice"
                            currentUser={customer.users.filter(user => user.is_admin)[0]}
                        />
                        <PopUpCreate
                            createInvoice={false}
                            title="Création offre (Admin)"
                            altText="Create Offer"
                            currentUser={customer.users.filter(user => user.is_admin)[0]}
                        />
                    </div> : <div className={classes.invoiceCreateOffer}>
                        <PopUpCreate
                            createInvoice={true}
                            title="Création facture"
                            altText="Create Invoice"
                            currentUser={customer.users.filter(user => (user.id === usersUserID))[0]}
                        />
                        <PopUpCreate
                            createInvoice={false}
                            title="Création offre"
                            altText="Create Offer"
                            currentUser={customer.users.filter(user => (user.id === usersUserID))[0]}
                        />
                    </div>}
                </Grid>
            </Grid>
            <Grid item xs={3} container className={classes.headRightSide} spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        className={(customerListError || customerListLoading || Object.keys(customer).length === 0 || (conversionFunnelID === -1)) ? classes.selectPerson2Italic : classes.selectPerson2}
                        select
                        size="small"
                        SelectProps={{
                            native: true,
                        }}
                        onChange={(e) => {
                            if(e.target.value === '-1') {
                                editSalesPerson(null);
                            } else {
                                editSalesPerson(parseInt(e.target.value));
                            }
                        }}
                        variant="outlined"
                        value={conversionFunnelID}
                    >
                         {/* {(customerListError || customerListLoading || Object.keys(customer).length === 0) ? (<option value={-1} style={{ fontStyle: "italic" }}>
                                Empty
                            </option>) : ()} */}
                            <option value={-1} style={{ fontStyle: "italic" }}>
                                Not Selected
                            </option>
                            {salesPeople.map(salesPerson => (
                                <option value={salesPerson.id} key={salesPerson.id}>
                                    {salesPerson.name}
                                </option>
                            ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        id="combo-box-demo"
                        className={classes.search}
                        size="small"
                        // getOptionSelected={(option, value) => option.id === value.id}
                        autoHighlight
                        value={autocompleteValue}
                        filterOptions={(options, state) => options}
                        options={customerList}
                        getOptionLabel={options => (
                            (customerList.length) === 0 ? ''
                            : options.company_name === null ? options.users[0].user.username
                            : options.company_name.trim() === '' ? options.users[0].user.username
                            : `${options.company_name} ${options.users.length > 1 ? `(${options.users.length})` : ''}`
                        )}
                        onChange={(e, v, r) => {
                            // console.log(v);
                            if((r === 'select-option') && (v !== null)) {
                                getCustomerByID(v.id);
                                setAutocompleteValue(v);
                            } else if (r === 'clear') {
                                handleClearAll();
                            }
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            error={customerListError}
                            label={customerListError ? 'Error, please reload page' : customerListLoading ? 'loading...' : 'Rechercher…'}
                            variant="outlined"
                            onChange={(e) => {
                                if(e.target.value.length > 2) {
                                    setStopSearchCustomers(false);
                                    sestSearchTerm(e.target.value);
                                } else if(e.target.value.length === 0) {
                                    setStopSearchCustomers(true);
                                    setCustomerList([]);
                                }
                            }}
                        />}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Paper>);
}

export default CustomerAccountHeader;
