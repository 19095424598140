import { CA_LOAD_ALL } from '../actions/types';


const caLoadAll = (state = false, action) => {
  switch(action.type) {
    case CA_LOAD_ALL:
      return action.payload;
    default:
      return state;
  }
};


export default caLoadAll;
