import { INVOICES_MAIN } from '../actions/types';
import { convertDateToWithDash } from '../common/functions';


const newDate = new Date();
const dateTo = convertDateToWithDash(newDate);

const dateStarts = newDate.setMonth(newDate.getMonth() - 6);
const dateFrom = convertDateToWithDash(dateStarts);

const initialValues = {
  controled: false, //  Déjà traitées, Pas traitées

  modifing: false, // surfacturation

  dateFrom,
  dateTo,

  synchronization: false,  // Synchronisation

  searchTerm: '',

  invoices: null,
  loading: false, // Is_Fetching 

  count: 0,
};

const invoicesMain = (state = initialValues, action) => {
  switch(action.type) {
    case INVOICES_MAIN:
      return action.payload;
    default:
      return state;
  }
};


export default invoicesMain;
