import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import MuiTableCell from "@material-ui/core/TableCell";
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import LinkButton from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import useToken from '../login/useToken';
import { FrameChatContext } from '../contexts/FrameChatContext';
import postFinancePng from '../static/images/pf_icon.png';
import twintPng from '../static/images/twint_icon.png';
import PopUpPackage from '../tracking/PopUpPackage';
import PopUpAddress from '../tracking/PopUpAddress';
import CommentTaskCountContext from '../contexts/CommentTaskCountContext';
import ChatPopUpBurger from './ChatPopUpBurger';
import CorrespondencePopUp from '../correspondence/CorrespondencePopUp';
import { URL_All } from '../common/Constants';
import { logOut } from '../common/Frame';
import { setIssuesSearchInput,
  getCustomerByIDAction,
  setGoToCA,
  getTaskCount,
  getStatusList,
  setRefresh,
} from '../actions';
import { convertDateToTimestamp } from '../common/functions';


const useStyles = makeStyles((theme) => ({
  standardTextAlignCenter: {
    textAlign: 'center',
  },
  shippingInformation: {
    textAlign: 'center',
    fontSize: ".9rem",
  },
  senderReceiver: {
    textAlign: 'left',
    fontSize: ".95rem",
    width: '5ch',
  },
  table: {
    backgroundColor: "#F7F7F7",
  },
  tableHeadCell: {
      textAlign: 'center',
      fontSize: "1.14em",
  },
  arrowDown: {
    padding: 0,
    paddingTop: theme.spacing(1),
  },
  arrowDownIcon: {
    textAlign: 'center',
  },
  arrowDropIcon: {
      color: "secondary",
      width: "4ch",
      fontSize: "2rem",
  },
  status: {
    textAlign: 'center',
    minWidth: '90%',
  },
  statusRed: {
    textAlign: 'center',
    minWidth: '90%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '& .MuiSelect-iconOutlined': {
        color: 'red',
      },
    },
  },
  statutLivraison: {
    backgroundColor: "#43a047",
    padding: "0.5rem",
    color: "#FFF",
    marginBottom: 0,
    letterSpacing: theme.spacing(0.1),
  },
  statutAujourdhui: {
    backgroundColor: "#2979ff",
    padding: "0.5rem",
    color: "#FFF",
    marginBottom: 0,
    letterSpacing: theme.spacing(0.1),
  },
  statutRetard: {
    backgroundColor: "#B71C1C",
    padding: "0.5rem",
    color: "#FFF",
    marginBottom: 0,
    letterSpacing: theme.spacing(0.1),
  },
  statutUnknown: {
    padding: "0.5rem",
    marginBottom: 0,
    letterSpacing: theme.spacing(0.1),
  },
  motif:{
    fontSize: ".95rem",
    color: "grey",
    fontWeight: "700",
    padding: theme.spacing(1.35),
  },
  motifRed:{
    fontSize: ".95rem",
    color: "red",
    fontWeight: "700",
    padding: theme.spacing(1.35),
  },
  moreInfo: {
    "& .MuiTableCell-root": {
      borderBottom: "1px solid #a9a1a1",
    },
    backgroundColor: '#cbefd5',
  },
  contactCompanyNames: {
    fontSize: "1.2em",
  },
  contactNames: {
    fontSize: "1.2em",
  },
  root: {
    '&.MuiPaper-root': {
      backgroundColor: 'inherit',
    },
  },
  packageIcon: {
    textAlign: 'center',
  },
  flipHorizontal: {
    transform: 'scaleX(-1)',
  },
  highlightIcon: {
    textAlign: 'center',
    alignItems: 'center',
  },
  lampOnIcon: {
    color: '#17a3f6',
  },
  gridToRight: {
    textAlign: 'right',
  },
  justifyContentRight: {
    justifyContent: "flex-end",
  },
  checkedRefresh: {
    textAlign: 'right',
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  paddingLeftZero: {
    paddingLeft: 0,
  },
  rowBtnWithInformations: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  enTransitHead: {
    color: 'gray',
  },
  shipmentId: {
    color: 'gray',
    textAlign: 'center',
  },
  fontSmaller: {
    fontSize: '0.9em',
  },
  lastCheckedUsername: {
    textAlign: 'right',
  },
  quoteServiceText: {
    marginLeft: theme.spacing(4),
    color: "#4b3000",
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(.5),
  },
  lastCheckIconToday: {
    color: "green",
    fontWeight: "700",
  },
  lastCheckIconTodayUser: {
    paddingRight: theme.spacing(2),
  },
  lastCheckIconBT: {
    color: "orange",
    fontWeight: "700",
  },
  lastCheckIconBTUser: {
    paddingRight: theme.spacing(2),
  },
  iconAtSameLine: {
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  archivedBy: {
    color: '#9e9b9b',
    fontStyle: 'italic',
  },
  emptyBody: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '2rem',
    color: '#9e9b9b',
    height: '60vh',
  },
  loading: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '2.5rem',
    color: '#9e9b9b',
    height: '60vh',
  },
  errorBody: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '2rem',
    color: '#e80606',
    height: '60vh',
  },
  issue: {
    fontSize: '1.2em',
    fontWeight: '700',
    marginTop: theme.spacing(2),
    color: 'red',
    display: 'inline-block',
    width: '90%'
  },
  showMore: {
    textDecoration: 'underline',
    color: 'red',
  },
  displayNone: {
    display: 'none',
  },
  goToCAButton: {
    textTransform: 'none',
  },
  isArchive: {
    color: '#999',
    fontStyle: 'italic',
    fontWeight: '700',
    marginRight: '2em',
  },
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TableCellS = withStyles({
  root: {
    borderBottom: "none",
    paddingBottom: 0,
  }
})(MuiTableCell);

function LampOffIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20,11H23V13H20V11M1,11H4V13H1V11M13,1V4H11V1H13M4.92,3.5L7.05,5.64L5.63,7.05L3.5,4.93L4.92,3.5M16.95,5.63L19.07,3.5L20.5,4.93L18.37,7.05L16.95,5.63M12,6A6,6 0 0,1 18,12C18,14.22 16.79,16.16 15,17.2V19A1,1 0 0,1 14,20H10A1,1 0 0,1 9,19V17.2C7.21,16.16 6,14.22 6,12A6,6 0 0,1 12,6M14,21V22A1,1 0 0,1 13,23H11A1,1 0 0,1 10,22V21H14M11,18H13V15.87C14.73,15.43 16,13.86 16,12A4,4 0 0,0 12,8A4,4 0 0,0 8,12C8,13.86 9.27,15.43 11,15.87V18Z" />
    </SvgIcon>
  );
}

function LampOnIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12,6A6,6 0 0,1 18,12C18,14.22 16.79,16.16 15,17.2V19A1,1 0 0,1 14,20H10A1,1 0 0,1 9,19V17.2C7.21,16.16 6,14.22 6,12A6,6 0 0,1 12,6M14,21V22A1,1 0 0,1 13,23H11A1,1 0 0,1 10,22V21H14M20,11H23V13H20V11M1,11H4V13H1V11M13,1V4H11V1H13M4.92,3.5L7.05,5.64L5.63,7.05L3.5,4.93L4.92,3.5M16.95,5.63L19.07,3.5L20.5,4.93L18.37,7.05L16.95,5.63Z" />
    </SvgIcon>
  );
}

function DateDifferences(props) {
  const { arg, transitStatus } = props;
  const classes = useStyles();
  const dateNow2 = `${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}-${(new Date().getDate()).toString().padStart(2, '0')}`; //  1611067478014
  const dateNow = new Date(dateNow2).getTime();
  const dateDelivery = (new Date(arg)).getTime();
  const diffTime = Math.abs(dateNow - dateDelivery); //milliseconds
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  if (isNaN(dateDelivery)) {
    return <p><b>No<br />Delivery<br />Date</b></p>;
  } else if(transitStatus === "delivered"){
    return <p><b>Delivered<br /></b></p>;
  } else if(dateNow < dateDelivery){
    return <p className={classes.statutLivraison}><b>Livraison<br />J+{diffDays}</b></p>;
  } else if (dateNow === dateDelivery) {
    return <p className={classes.statutAujourdhui}><b>Aujourd’hui</b></p>;
  } else if (dateNow > dateDelivery){
    return <p className={classes.statutRetard}><b>Retard<br />J-{diffDays}</b></p>;
  } else {
    return <p className={classes.statutUnknown}><b>Unknown</b></p>;
  }
}

function LastChecked(props) {
  const { updatedTime } = props;
  const classes = useStyles();

  const updatedTimeNotTimeStamp = updatedTime * 1000;
  const dateNow2 = `${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}-${(new Date().getDate()).toString().padStart(2, '0')}`;
  const dateNow = new Date(dateNow2).getTime();
  
  if(updatedTime !== 0 & dateNow <= updatedTimeNotTimeStamp) {
    const updatedHourMinuts = (`${(new Date(updatedTimeNotTimeStamp).getHours()).toString().padStart(2, '0')}:${(new Date(updatedTimeNotTimeStamp).getMinutes()).toString().padStart(2, '0')}`);

    return (<Grid item xs={12} className={classes.checkedRefresh}>
      <span className={classes.lastCheckIconToday}><span className={classes.archivedBy}>Archived date:</span>{<br />}{updatedHourMinuts}
      </span>
    </Grid>); 
  } else if (updatedTime !== 0 & dateNow > updatedTimeNotTimeStamp) {
    const updatedYYYYmmDD = `${new Date(updatedTimeNotTimeStamp).getFullYear()}-${(new Date(updatedTimeNotTimeStamp).getMonth()+1).toString().padStart(2, '0')}-${(new Date(updatedTimeNotTimeStamp).getDate()).toString().padStart(2, '0')}`;

    return (<Grid item xs={12} className={classes.checkedRefresh}>
      <span className={`${classes.lastCheckIconBT} ${classes.iconAtSameLine}`}><span className={classes.archivedBy}>Archived date:</span>{<br />}{updatedYYYYmmDD}
      </span>
    </Grid>); 
  } else {
    return (<Grid item xs={12} className={classes.checkedRefresh}>
      <span className={classes.justifyContentRight}>
      </span>
    </Grid>);
  }
}

function LastChecked2(props) {
  const { updatedTime, lastCheckedUser } = props;
  const classes = useStyles();

  const updatedTimeNotTimeStamp = updatedTime * 1000;
  const dateNow2 = `${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}-${(new Date().getDate()).toString().padStart(2, '0')}`;
  const dateNow = new Date(dateNow2).getTime();
  
  if(updatedTime !== 0 & dateNow <= updatedTimeNotTimeStamp) {
    return (<Grid item xs={12} className={`${classes.gridToRight} ${classes.lastCheckIconTodayUser}`}>
      <span className={classes.lastCheckIconToday}><span className={classes.archivedBy}>Archived by: </span>{lastCheckedUser}</span>
    </Grid>);
  } else if (updatedTime !== 0 & dateNow > updatedTimeNotTimeStamp) {
    return (<Grid item xs={12} className={`${classes.gridToRight} ${classes.lastCheckIconBTUser}`}>
      <span className={classes.lastCheckIconBT}><span className={classes.archivedBy}>Archived by: </span>{lastCheckedUser}</span>
    </Grid>);
  } else {
    return null;
  }
}

function InvoiceIcon(props) {
  return (<SvgIcon {...props} viewBox="0 0 200.000000 200.000000"
 preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
      <path d="M461 1679 c-83 -42 -127 -135 -138 -291 l-6 -88 92 0 91 0 0 -466 c0
      -412 2 -470 16 -501 32 -66 7 -64 582 -61 l520 3 39 31 c55 45 92 123 100 212
      l6 72 -91 0 -91 0 -3 498 c-3 476 -4 498 -23 531 -11 19 -33 43 -48 55 -28 21
      -38 21 -515 24 -482 2 -487 2 -531 -19z m995 -77 c32 -21 34 -52 34 -531 l0
      -481 -404 0 -403 0 -6 -80 c-6 -89 -30 -145 -63 -145 -19 0 -19 14 -24 600 -4
      463 -8 605 -18 623 l-13 22 443 0 c244 0 448 -4 454 -8z m-956 -144 l0 -68
      -45 0 c-36 0 -45 3 -45 18 0 33 27 120 45 149 l19 28 12 -30 c7 -17 13 -60 14
      -97z m1160 -978 c0 -30 -26 -75 -56 -99 -26 -21 -36 -21 -446 -21 -396 0 -420
      1 -413 18 5 14 25 107 25 118 0 2 200 4 445 4 442 0 445 0 445 -20z"/>
      <path d="M820 1442 c0 -22 -7 -32 -34 -45 -26 -12 -38 -26 -47 -56 -20 -66 -1
      -94 87 -136 41 -20 74 -40 74 -45 0 -22 -48 -29 -100 -16 l-51 13 -15 -35
      c-16 -39 -11 -47 36 -58 47 -11 50 -13 50 -44 0 -30 1 -31 43 -28 39 3 42 5
      45 33 2 23 11 34 34 44 36 16 62 70 53 111 -8 37 -49 73 -113 100 -70 30 -62
      45 21 44 l65 -2 7 35 c7 39 -4 53 -42 53 -19 0 -23 6 -23 30 0 29 -2 30 -45
      30 -43 0 -45 -1 -45 -28z"/>
      <path d="M1117 1413 c-4 -3 -7 -24 -7 -45 l0 -38 140 0 140 0 0 45 0 45 -133
      0 c-74 0 -137 -3 -140 -7z"/>
      <path d="M1112 1178 l3 -43 138 -3 137 -3 0 46 0 45 -141 0 -140 0 3 -42z"/>
      <path d="M1117 1023 c-4 -3 -7 -24 -7 -45 l0 -38 140 0 140 0 0 45 0 45 -133
      0 c-74 0 -137 -3 -140 -7z"/>
      <path d="M732 778 l3 -43 330 0 330 0 3 43 3 42 -336 0 -336 0 3 -42z"/>
    </g>
  </SvgIcon>);
}

function CreditCardIcon(props) {
  return (<SvgIcon {...props} viewBox="0 0 200.000000 200.000000"
  preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
      <path d="M1343 1730 c-447 -156 -1252 -456 -1270 -473 -49 -44 -45 -60 97
      -455 73 -202 137 -371 142 -377 4 -5 8 183 8 418 0 453 1 462 49 494 24 17 76
      18 667 23 l641 5 -64 175 c-35 96 -72 183 -81 193 -39 38 -73 38 -189 -3z"/>
      <path d="M397 1299 c-15 -11 -31 -37 -37 -57 -8 -25 -10 -186 -8 -490 l3 -454
      32 -28 c20 -18 48 -31 75 -35 24 -3 354 -5 733 -3 640 3 692 4 716 21 14 9 31
      29 37 43 9 18 12 160 12 490 l0 466 -34 34 -34 34 -735 0 c-726 0 -735 0 -760
      -21z m1491 -51 c7 -7 12 -34 12 -60 l0 -48 -745 0 -745 0 0 41 c0 22 5 49 10
      60 10 19 29 19 733 19 549 0 726 -3 735 -12z m12 -637 c0 -288 0 -290 -22
      -305 -20 -14 -105 -16 -723 -16 -618 0 -703 2 -723 16 -22 15 -22 17 -22 305
      l0 289 745 0 745 0 0 -289z"/>
    </g>
  </SvgIcon>);
}

function Row(props) {
  const { item,
    statusList,
    statusValue,
    lambOnOff,
    ifExist,
    users,
    setRefreshKey,
    statusCheckedTime,
    statusCheckedBy,
    typeIssue,
    isArchive,
    setIssuesSearchInput,
    countCommentsTasks,
    getCustomerByIDAction,
    setGoToCA,
    caGoTo,
    refresh,
    setRefresh,
    token,
  } = props;
  
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [enTransit,setEnTransit] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const { setStopSearchCustomers } = useContext(FrameChatContext);

  function getTransitInfo(args) {
    if(!token) return logOut();
  
    fetch(URL_All.EnTransit + args, {
      method: 'GET',
      headers: {
        'authorization': `JWT ${token}`,
      }
    })
      .then(response => {
        if (response.status === 403) {
          return logOut();
        } else if (response.status === 200) {
          return response.json();
        }
      })
      .then(json => {
        if(json.length >= 1){
          return setEnTransit(json);
        }
        return null;
      })
      .catch(error => console.error(error))
  };
  
  const username = localStorage.getItem('username');
  if(!username) logOut();

  let currentUser = users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0] === undefined ? '' : users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0].id;

  const lastCheckedUser = users.filter(filt => (filt.id === statusCheckedBy))[0] === undefined ? '' : users.filter(filt => (filt.id === statusCheckedBy))[0].username;

  function postStatus(shipmentId, highlighted) {
    fetch(URL_All.postEditStatusshipments, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({shipment_id: shipmentId, highlighted: highlighted, is_actif: 0, created_by: currentUser, updated_by: currentUser})
    })
    .then(() => setRefreshKey(oldKey => oldKey + 1))
    .catch(error => console.error(error))
  };

  function editStatus(statusId, highlighted) {
    fetch(URL_All.postEditStatusshipments, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({shipment_id: item.id, status_id: statusId, highlighted: highlighted, updated_by: currentUser})
    })
    .then(() => setRefreshKey(oldKey => oldKey + 1))
    .catch(error => console.error(error))
  };

  const deliveriDate = (<span>
    <span className={classes.fontSmaller}>Livraison prévue:</span><br />
    <span className={classes.fontSmaller}>{item.delivery_date}</span><br />
  </span>);

  const transporter_amount = (item.ikompar_invoice === null) ? 0 : Number.parseFloat(item.ikompar_invoice.transporter_amount).toFixed(2);
  const shipment_amount = (item.ikompar_invoice === null) ? 0 : Number.parseFloat(item.ikompar_invoice.shipment_amount).toFixed(2);
  const insurance_amount = (item.ikompar_invoice === null) ? 0 : Number.parseFloat(item.ikompar_invoice.insurance_amount).toFixed(2);
  const vat_amount = (item.ikompar_invoice === null) ? 0 : Number.parseFloat(item.ikompar_invoice.vat_amount).toFixed(2);
  const total_amount = (item.ikompar_invoice === null) ? 0 : Number.parseFloat(item.ikompar_invoice.total_amount).toFixed(2);

  const paymentMethodM = ((transporter_amount !== 'NaN' && shipment_amount !== 'NaN') ? Number.parseFloat((1 - transporter_amount / shipment_amount) * 100).toFixed(2) : null);

  const issueButton = (
    <Button
      className={classes.issue}
      variant="outlined"
      color="secondary"
      onClick={() => setIssuesSearchInput(item.tracking_numbers.split('<br')[0])}
      component={Link}
      to={'/issues'}
    >
      CAS LITIGIEUX
    </Button>
  );

  function showTrackingNumbers(trackingNumber) {
    if(trackingNumber.split(/<br\/>/g).length > 3 && !showMore) {
      return trackingNumber.split(/<br\/>/g).slice(0, 3).join('\n');
    }

    return trackingNumber.replace(/<br\/>/g, '\n');
  }
  
  const handleGoToCA = () => {
    if(item.ikompar_invoice) {
      setGoToCA(true);
      setStopSearchCustomers(false);
      getCustomerByIDAction(token, item.ikompar_invoice.customer_id);
    }
  };

  function recoveryShipment(args) {
    if(!token) return logOut();
  
    fetch(URL_All.apiShipment + '/' + item.id + args, {
      headers: {
        'authorization': `JWT ${token}`
      }
    })
      .then(response => {
        if (response.status === 403) {
          return logOut();
        }
      })
      .catch(error => console.error(error))
  }

  function recoveryStatusAndSendToTracking(args) {
    fetch(URL_All.postEditStatusshipments, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({shipment_id: item.id, is_actif: 1, checked_by: currentUser, checked_at: convertDateToTimestamp(new Date()), fulldata: "\"\"", archived_type: args, updated_by: currentUser})
    })
    .then(() => setRefresh(refresh + 1))
    .catch(error => console.error(error))
  };

  const goToCustomerAccountButton = (
    <Button
      disabled={!item.ikompar_invoice || caGoTo}
      className={classes.goToCAButton}
      variant="outlined"
      color="primary"
      size="small"
      onClick={handleGoToCA}
    >
      {item.user}
    </Button>
  );

  const pickupAddressInfo = {
    type: 'Pickup',
    street_number: item.pickup_street_number,
    street_line_1: item.pickup_street_line_1,
    street_line_2: item.pickup_street_line_2,
    zip_code: item.pickup_zip_code,
    city: item.pickup_city,
    state: item.pickup_state,
    country_name: item.pickup_country_name,
    full_address: item.pickup_address,
  };

  const deliveryAddressInfo = {
    type: 'Delivery',
    street_number: item.delivery_street_number,
    street_line_1: item.delivery_street_line_1,
    street_line_2: item.delivery_street_line_2,
    zip_code: item.delivery_zip_code,
    city: item.delivery_city,
    state: item.delivery_state,
    country_name: item.delivery_country_name,
    full_address: item.delivery_address,
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCellS className={classes.shippingInformation} component="th" scope="row">
          <span>Pick-up date-time:</span><br />
          <span><i>{item.pickup_date}</i></span><br />
          <span>{item.pickup_request.ready_time.slice(0, 5)} - {item.pickup_request.close_time.slice(0, 5)}</span><br /><br />
          <span>N° suivi:</span><br />
          <span>{showTrackingNumbers(item.tracking_numbers)}</span>
          <span className={item.tracking_numbers.split(/<br\/>/g).length > 3 ? '' : classes.displayNone}>
            <br />
            <LinkButton
              className={classes.showMore}
              component="button"
              variant="body2"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? 'Show Less' : 'Show More'}
            </LinkButton>
          </span>
          <br /><br />
          <span>N° enlèvement:</span><br />
          <span>{item.pickup_request.confirmation_number}</span><br /><br />
        </TableCellS>
        <TableCellS className={classes.senderReceiver}>
          <span className={classes.contactCompanyNames}><b>{item.pickup_contact}</b></span><br />
          <span className={classes.contactNames}><b><i>{item.pickup_contact_name}</i></b></span><br />
          <PopUpAddress data={pickupAddressInfo} />
          <span>{item.pickup_phone}</span><br />
          <span><a href={`mailto:${item.pickup_email}`}>{item.pickup_email}</a></span>
        </TableCellS>
        <TableCellS className={classes.senderReceiver}>
          <span className={classes.contactCompanyNames}><b>{item.delivery_contact}</b></span><br />
          <span className={classes.contactNames}><b><i>{item.delivery_contact_name}</i></b></span><br />
          <PopUpAddress data={deliveryAddressInfo} />
          <span>{item.delivery_phone}</span><br />
          <span><a href={`mailto:${item.delivery_email}`}>{item.delivery_email}</a></span>
        </TableCellS>
        <TableCellS className={classes.standardTextAlignCenter}>
        <div className="flex flex-shrink text-center justify-between sm:w-88 grid grid-cols-1 gap-4">
            {item.delivery_date !== '' ? deliveriDate : null}
						<span>
              <DateDifferences
                arg={item.delivery_date}
                pickUpDate={item.pickup_date}
                pickUpTime1={item.pickup_request.ready_time.slice(0, 5)}
                pickUpTime2={item.pickup_request.close_time.slice(0, 5)}
                transitStatus={item.transit_status}
              /> 
            </span>
					</div>
        </TableCellS>
        <TableCellS className={classes.standardTextAlignCenter}>
          <TextField
              className={statusValue === 1 ? classes.status : classes.statusRed}
              select
              value={statusValue ? statusValue : 1}
              onChange={isArchive ? null : ifExist ? (e) => editStatus(e.target.value) : (e) => postStatus(e.target.value)}
              SelectProps={{
                native: true,
              }}
              InputProps={{
                classes: {
                  input: statusValue === 1 ? classes.motif : classes.motifRed,
                },
              }}
              variant="outlined"
            >
                {isArchive ? statusList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))
                :statusList.filter(t => (t.id === statusValue)).map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                ))}
            </TextField>
        </TableCellS>
        <TableCellS
          className={classes.standardTextAlignCenter}
        >
          <CorrespondencePopUp
            parentId={item.id}
            goToTab={3}
            headerTop={null}
            isRequest={false}
            isShipment={true}
            customer_id={item.customer_id}
            tracking_nbr={item.tracking_numbers}
            disablePopUp={item.has_issue}
            countCommentsAndTasks={countCommentsTasks}
            isChatPage={true}
            email={item.user}
          />
          <CorrespondencePopUp
            parentId={item.id}
            goToTab={0}
            headerTop={null}
            isRequest={false}
            isShipment={true}
            customer_id={item.customer_id}
            tracking_nbr={item.tracking_numbers}
            disablePopUp={item.has_issue}
            countCommentsAndTasks={countCommentsTasks}
            isChatPage={true}
            email={item.user}
          />
          {item.has_issue ? issueButton : null}
        </TableCellS>

        <TableCellS>
          <Grid container>
            <Grid item xs={12} className={classes.shipmentId}>
              <span>ID: {item.id}</span>
            </Grid>
            <Grid item xs={12} className={classes.shipmentId} container justifyContent="flex-end">
              <span className={classes.iconAtSameLine}>
                {item.ikompar_invoice !== null && parseFloat(item.ikompar_invoice.number.split("-").join(''))}&nbsp;
                <Tooltip
                  className={classes.paymentMethod}
                  title={<div>
                      Transporter CHF: {transporter_amount !== 'NaN' && transporter_amount}
                      <br />Shipment CHF: {shipment_amount !== 'NaN' && shipment_amount}
                      <br />GM (%): {paymentMethodM !== 'Infinity' && paymentMethodM}
                      <br />Insurance CHF: {insurance_amount !== 'NaN' && insurance_amount}
                      <br />Vat CHF: {vat_amount !== 'NaN' && vat_amount}
                      <br />Total CHF: {total_amount !== 'NaN' && total_amount}
                    </div>}
                  placement="left-end"
                >
                  <IconButton>
                    {(item.ikompar_invoice === null) ? null : (item.ikompar_invoice.payment_method === 'CreditCard') ? <CreditCardIcon /> : (item.ikompar_invoice.payment_method === 'Invoice') ? <InvoiceIcon />
                      : ((item.ikompar_invoice.payment_method === 'Postfinance card') || (item.ikompar_invoice.payment_method === 'Postfinance e-finance') || (item.ikompar_invoice.payment_method === 'PF Pay')) ? <Avatar
                        variant="square"
                        alt={!item.ikompar_invoice ? 'Icon' : item.ikompar_invoice.payment_method}
                        src={postFinancePng}
                      /> : (item.ikompar_invoice.payment_method === 'Twint') ? <Avatar
                        variant="square"
                        alt={!item.ikompar_invoice ? 'Icon' : item.ikompar_invoice.payment_method}
                        src={twintPng}
                    /> : null}
                  </IconButton>
                </Tooltip>
              </span>
            </Grid>
            <Grid item xs={6} className={classes.packageIcon}>
              <PopUpPackage item={item} />
            </Grid>
            <Grid item xs={6} className={classes.highlightIcon}>
              <IconButton
                aria-label="highlight"
                onClick={(ifExist & lambOnOff === 1) ? (() => editStatus(undefined, 0)) : (ifExist & lambOnOff === 0) ? () => editStatus(undefined, 1) : () => postStatus(undefined, 1)}
              >
                {lambOnOff === 1 ? (<LampOnIcon className={classes.lampOnIcon} />) : (<LampOffIcon />)}
              </IconButton>
            </Grid>
            <Grid item xs={6} className={classes.recoveryIcon}>
              <Tooltip title={item.has_issue ? '' : 'Menu'} className={classes.recovery}>
                <IconButton aria-label="recovery">
                  <ChatPopUpBurger
                    shipmentId={item.id}
                    currentUser={currentUser}
                    isIssue={typeIssue > 0}
                    isArchive={!isArchive}
                    recoveryShipment={recoveryShipment}
                    recoveryStatusAndSendToTracking={recoveryStatusAndSendToTracking}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            {isArchive ? null : <LastChecked updatedTime={statusCheckedTime} />} 
          </Grid>
          <Grid item xs={6} />
        </TableCellS>
      </TableRow>
      <TableRow className={classes.rowBtnWithInformations}>
        <TableCell colSpan={9} className={classes.arrowDown}>
          <Grid container>
            <Grid item xs={5}>
              <span className={classes.quoteServiceText}>{item.quote_service} | {goToCustomerAccountButton}</span>
            </Grid>
            <Grid item xs={2} className={classes.arrowDownIcon}>
              <IconButton
                title="En transit"
                aria-label="expand row"
                onClick={() => {
                  setOpen(!open);
                  getTransitInfo(item.id);
                }}>
                {open ? <ExpandLessIcon color="inherit" className={classes.arrowDropIcon} /> : <ExpandMoreIcon color="inherit" className={classes.arrowDropIcon} />}
              </IconButton>
            </Grid>
            <Grid item xs={5} className={classes.lastCheckedUsername}>
              {/* {isArchive ? <span className={classes.isArchive}>Archived</span> : <LastChecked2
                updatedTime={statusCheckedTime}
                lastCheckedUser={lastCheckedUser}
              />} */}
              {isArchive ? null : <LastChecked2
                updatedTime={statusCheckedTime}
                lastCheckedUser={lastCheckedUser}
              />}
            </Grid>
          </Grid>
          
        </TableCell>
      </TableRow>
      <TableRow className={classes.moreInfo} id={`transit-${item.id}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                En transit
              </Typography>
              <Table size="small" aria-label="purchases">
                <colgroup>
                  <col span="1" style={{width: "65%"}} />
                  <col span="1" style={{width: "35%"}} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.enTransitHead}><h3>Event</h3></TableCell>
                    <TableCell className={classes.enTransitHead}><h3>Area</h3></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {enTransit.map((transit, index) => {
                    return (<React.Fragment key={index}>
                      <TableRow><TableCell colSpan={2}><h2>{transit.date.slice(0, 10)}</h2></TableCell></TableRow>
                      {transit.events.map(eventsDetails => {
                        return (<TableRow key={eventsDetails.id.toString()}>
                          <TableCell>{eventsDetails.date.slice(11, 16)}<br />{eventsDetails.event_desc}</TableCell><TableCell>{eventsDetails.area_desc}</TableCell>
                        </TableRow>);
                      })}
                    </React.Fragment>);
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function TablePaginationActions(props) {
  const classes = useStyles2();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
 
function ChatBody(props) {
  const { token } = useToken();
  const classes = useStyles();
  const { chatSearch,
    refreshKey,
    setRefreshKey,
    isClickedHighlight,
    statusValue,
    highlightCount,
    // statusObjectValues,
    pageZero,
    setIssuesSearchInput,
    countCommentsTasks,
    getCustomerByIDAction,
    setGoToCA,
    caGoTo,
    getTaskCount,
    chatGetShips,
    chatLoading,
    chatError,
    users,
    getStatusList,
    statusList,
    refresh,
    setRefresh,
  } = props;

  let [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  // const [chatGetShipsFiltered] = useState();
  const [countTracking, setCountTracking] = useState(0);

  // console.log('chatGetShips:', chatGetShips);
  // console.log('chatLoading:', chatLoading);
  // console.log('chatError:', chatError);
  // console.log('users:', users);

  useEffect(() => {
    if(users.length) {
      getStatusList();
      getTaskCount(users, true, token); // sequential get: 2nd getChatShips
      // console.log('how many times');
    }
  }, [token, users, getTaskCount, getStatusList]);
 
  useEffect(() => {
    setCountTracking(chatGetShips.filter(item => (isClickedHighlight ? highlightCount.includes(item.id) : [])).filter(f => (Object.values(f).toString().toLowerCase().includes(chatSearch.toLowerCase()) || Object.values(f.pickup_request).toString().toLowerCase().includes(chatSearch.toLowerCase()))).length);

    // {chatGetShips.filter(FILTER_MAP['CheckedHighlighted']).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(x => {
    // });

  }, [setCountTracking, chatGetShips, isClickedHighlight, highlightCount, chatSearch, refreshKey]);

  useEffect(() => {
    setPage(0);
  }, [setPage, pageZero])

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, countTracking - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 
  const FILTER_MAP = {
    CheckedHighlighted: item => false
    || (isClickedHighlight ? highlightCount.includes(item.id) : [])
  };

  const loadingRow = (<TableRow><TableCell colSpan={7} className={classes.loading}>Loading...</TableCell></TableRow>);
  const emptyBody = (<TableRow><TableCell colSpan={7} className={classes.emptyBody}>No Any Shipment With New Message</TableCell></TableRow>);
  const notFound = (<TableRow><TableCell colSpan={7} className={classes.errorBody}>"{chatSearch}" is not found</TableCell></TableRow>);
  const errorGet = (<TableRow><TableCell colSpan={7} className={classes.errorBody}>Error: Couldn't get shipments. Please, refresh the page.</TableCell></TableRow>);

  return (
    <div>
    <TableContainer className={classes.root} component={Paper}>
      <Table className={classes.table} aria-label="collapsible table">
        <colgroup>
          <col span="1" style={{width: "13%"}} />
          <col span="1" style={{width: "16%"}} />
          <col span="1" style={{width: "16%"}} />
          <col span="1" style={{width: "12%"}} />
          <col span="1" style={{width: "14%"}} />
          <col span="1" style={{width: "19%"}} />
          <col span="1" style={{width: "10%"}} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeadCell}>Information envoi</TableCell>
            <TableCell className={classes.tableHeadCell}>Expéditeur</TableCell>
            <TableCell className={classes.tableHeadCell}>Destinataire</TableCell>
            <TableCell className={classes.tableHeadCell}>Jours</TableCell>
            <TableCell className={classes.tableHeadCell}>Statut</TableCell>
            <TableCell className={classes.tableHeadCell}>Informations du colis</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {chatLoading ? loadingRow
          : chatError ? errorGet
          : (!countTracking && chatSearch.length) ? notFound
          : (!countTracking) ? emptyBody
          : null}
          <CommentTaskCountContext.Provider value={{countCommentsTasks}}>
            {chatGetShips.filter(FILTER_MAP['CheckedHighlighted']).filter(f => (Object.values(f).toString().toLowerCase().includes(chatSearch.toLowerCase()) || Object.values(f.pickup_request).toString().toLowerCase().includes(chatSearch.toLowerCase()))).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(x => {
              return <Row
                key={x.id}
                item={x}
                statusList={statusList}
                statusValue={statusValue[x.id] !== undefined ? (statusValue[x.id].status_id !== null ? statusValue[x.id].status_id : 1) : 1}
                lambOnOff={statusValue[x.id] !== undefined ? statusValue[x.id].highlighted : 0}
                ifExist={statusValue[x.id] !== undefined ? true : false}
                users={users}
                setRefreshKey={setRefreshKey}
                statusCheckedTime={statusValue[x.id] !== undefined ? statusValue[x.id].checked_at : 0}
                statusCheckedBy={statusValue[x.id] !== undefined ? statusValue[x.id].checked_by : null}
                typeIssue={statusValue[x.id] !== undefined ? statusValue[x.id].type_issue : 0}
                isArchive={statusValue[x.id] !== undefined ? statusValue[x.id].is_actif : 0}
                setIssuesSearchInput={setIssuesSearchInput}
                countCommentsTasks={countCommentsTasks}
                getCustomerByIDAction={getCustomerByIDAction}
                setGoToCA={setGoToCA}
                caGoTo={caGoTo}
                refresh={refresh}
                setRefresh={setRefresh}
                token={token}
              />;
            })}
            {emptyRows > 0 && <TableRow style={{ height: 0 * emptyRows }} />}
          </CommentTaskCountContext.Provider>
        </TableBody>
      </Table>
      <TablePagination
        className={classes.table}
        rowsPerPageOptions={[20, 30, 60]}
        colSpan={3}
        component="div"
        count={countTracking}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    countCommentsTasks: state.countCommentsTasks,
    caGoTo: state.caGoTo,
    chatGetShips: state.chatGetShips,
    chatLoading: state.chatLoading,
    chatError: state.chatError,
    users: state.users,
    statusList: state.statusList,
    chatSearch: state.chatSearch,
    refresh: state.refresh,
  };
};

export default connect(mapStateToProps, {
  setIssuesSearchInput,
  getCustomerByIDAction,
  setGoToCA,
  getTaskCount,
  getStatusList,
  setRefresh,
})(ChatBody);
