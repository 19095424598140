import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import { setChatSearch } from '../actions';


const useStyles = makeStyles((theme) => ({
  search: {
    width: '90%',
    "& .MuiIconButton-root": {
        padding: 0,
        color: "gray",
    },
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  headText: {
    '& .MuiTypography-root': {
        color: 'gray',
        fontSize: theme.spacing(6),
    },
  },
  searchGrid: {
  },
  gridHighlighted: {
    transform: 'translateY(40%)',
    textAlign: 'center',
  },
}));

function ChatHeader(props) {
    const classes = useStyles();
    let { chatSearch,
        setChatSearch,
        setPageZero,
    } = props;

    return (
    <div>
        <Grid 
            container
            spacing={1}
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
        >
            <Grid item xs={8} className={classes.headText}>
                <Typography>
                    Messaging (Chat)
                </Typography>
            </Grid>

            <Grid item xs={2} className={classes.gridHighlighted}>
            </Grid>

            <Grid item xs={2} className={classes.searchGrid}>
                <Grid 
                    container 
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                            <TextField 
                                size="small" 
                                id="outlined-basic" 
                                label="Rechercher…" 
                                variant="outlined" 
                                className={classes.search}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                      ev.preventDefault();
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {chatSearch.length > 0 ? (<IconButton onClick={() => {
                                            setChatSearch('');
                                        }}>
                                            <HighlightOffIcon size="small" />
                                        </IconButton>) : ''}
                                      </InputAdornment>
                                    ),
                                  }}
                                value={chatSearch}
                                onChange={e => {
                                    setChatSearch(e.target.value ? e.target.value : '');
                                    setPageZero(oldKey => (oldKey + 1));
                                }}
                            />
                        </form>
                    </Grid>
                
                </Grid>
            </Grid>
        </Grid>
    </div>
    );
}

const mapStateToProps = state => {
  return { chatSearch: state.chatSearch };
};

export default connect(mapStateToProps, { setChatSearch })(ChatHeader);
