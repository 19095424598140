import { CHAT_SEARCH } from '../actions/types';


const chatSearch = (state = '', action) => {
  switch(action.type) {
    case CHAT_SEARCH:
      return action.payload;
    default:
      return state;
  }
};


export default chatSearch;
