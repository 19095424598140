import { STATISTICS_N3_GET } from '../actions/types';

const statisticsN3Get = (state = {}, action) => {
  switch (action.type) {
    case STATISTICS_N3_GET:
      return action.payload;
    default:
      return state;
  }
};

export default statisticsN3Get;
