import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { editIssue } from '../actions';
import { currentUserId,
  convertDateToWithDash,
  convertDateToTimestamp
} from '../common/functions';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
  },
  openButton: {
    padding: '0 8px !important',

    '& .MuiSvgIcon-root': {
      marginLeft: theme.spacing(.5),
    },
  },
  radioGroup: {
    margin: '0 48px',
  },
  gridDates: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  gridRadio: {
    display: 'flex',
    justifyContent: 'center'
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function IssueTypeEditPopUp(props) {
  const classes = useStyles();
  const { title,
    issue,
    editIssue,
    users
  } = props;
  const [open, setOpen] = useState(false);
  const [editType, setEditType] = useState(issue.type_issue.toString());
  const [dateTransporteur, setDateTransporteur] = useState(issue.date_carrier === 0 ? '' : convertDateToWithDash(issue.date_carrier * 1000));
  const [dateClient, setDateClient] = useState(issue.date_customer === 0 ? '' : convertDateToWithDash(issue.date_customer * 1000));
  const [dateAssurance, setDateAssurance] = useState(issue.date_insurance === 0 ? '' : convertDateToWithDash(issue.date_insurance * 1000));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    setEditType(event.target.value)
  };

  const onSubmit = () => {
    editIssue(issue.shipment_id, parseInt(editType), currentUserId(users), {},  convertDateToTimestamp(dateClient), convertDateToTimestamp(dateAssurance), convertDateToTimestamp(dateTransporteur));
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Tooltip
        placement="top"
        title={'Edit Issue Dates & Type'}
      >
        
        <Button
          variant="contained"
          color="secondary"
          className={classes.openButton}
          onClick={handleClickOpen}
        >
          {title}
          <EditIcon fontSize="small" />
        </Button>
      </Tooltip>
      <Dialog
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Issue Dates {'&'} Type
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <Grid container className={classes.issueForm}>
            <Grid item xs={6} className={classes.gridDates}>
              <TextField
                id="dateTo"
                className={classes.datePicker}
                label="Client:"
                type="date"
                value={dateClient}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => setDateClient(e.target.value)}
              />
              <TextField
                id="dateTo"
                className={classes.datePicker}
                label="Assurance:"
                type="date"
                value={dateAssurance}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => setDateAssurance(e.target.value)}
              />
              <TextField
                id="dateTo"
                className={classes.datePicker}
                label="Transporteur:"
                type="date"
                value={dateTransporteur}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => setDateTransporteur(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridRadio}>
              <RadioGroup
                className={classes.radioGroup}
                value={editType}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="secondary" />}
                  label="Endommagé"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="secondary" />}
                  label="Perdu"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio color="secondary" />}
                  label="Surfacturation"
                />
                <FormControlLabel
                  value="4"
                  control={<Radio color="secondary" />}
                  label="Autre"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return { users: state.users };
};

export default connect(mapStateToProps, { editIssue })(IssueTypeEditPopUp);
