import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import createInvoicePng from '../static/images/create_invoice.png';
import createOfferPng from '../static/images/create_offer.png';
import { setCAButtons, setCAInvoicing } from '../actions';


const useStyles = makeStyles((theme) => ({
  actionButtons: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  paragraphs: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
  },
  disabledIcon: {
    '& img': {
      filter: 'opacity(0.5)',
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


function PopUpCreate(props) {
  const classes = useStyles();
  const { createInvoice,
    title,
    altText,
    setCAButtons,
    caButtons,
    currentUser,
    setCAInvoicing,
    caInvoicing,
  } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReason = reason => () => {
    const keyVal = createInvoice ? 'invoiceCreate' : 'invoiceOffer';
    setCAButtons({ ...caButtons, [reason]: true, [keyVal]: true });

    handleClose();
  };

  useEffect(() => {
    setCAInvoicing({ ...caInvoicing, currentUser });
  }, [currentUser]);

  return (
    <div> 
      <Tooltip
        title={title}
        placement="top"
        >
        <IconButton
          disabled={!createInvoice}
          className={!createInvoice ? classes.disabledIcon : ''}
          size="small"
          onClick={handleClickOpen}
        >
          {createInvoice ? <Avatar
          size="small"
            variant="square"
            alt={altText}
            src={createInvoicePng}
          /> : <Avatar
          size="small"
            variant="square"
            alt={altText}
            src={createOfferPng}
          />}
        </IconButton>
      </Tooltip>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Création de facture
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.actionButtons}>
            <Button
              disabled
              onClick={handleReason('transport')}
              color="primary"
              variant="outlined"
            >
              Transport
            </Button>
            <Button
              onClick={handleReason('other')}
              color="primary"
              variant="outlined"
            >
              Autre
            </Button>
          </div>
          <div className={classes.paragraphs}>
            <Typography>Offres en cour</Typography>
            <Typography>N° offre Titre – date de validité</Typography>
            <Typography>N° offre Titre – date de validité</Typography>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
      caButtons: state.caButtons,
      caInvoicing: state.caInvoicing,
  };
};

export default connect(mapStateToProps, { setCAButtons, setCAInvoicing })(PopUpCreate);
