import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { KEY_NAMES } from './PriceCorrection';


const useStyles = makeStyles((theme) => ({
  priceCorrectionForm: {
    padding: '16px 32px',
  },
  invoiceIcon: {
    color: '#0000008a',
  },
  dialogContent: {
    marginTop: theme.spacing(0),
  },
  formControl: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& .MuiFormControl-root': {
      width: 100,
    },
  },
  widthH: {
    width: '100%',
  },
  widthH2: {
    width: '90%',
  },
  grid22: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  backgroundDark: {
    background: '#f0f0f0',
  },
  dimentions: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  removeIcon: {
    color: '#909090',
  },
  dimentionsRow: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
}));

function PriceCorrectionForm(props) {
  const { pkg,
    index,
    handleEdit,
    isEmptyInput,
    handleRemove,
  } = props;
  const classes = useStyles();

  const handleChange = (e) => {
    handleEdit(index, {
      ...pkg, [e.target.name]: e.target.value
    });
  };

  return (
    <div className={classes.priceCorrectionForm}>
      <form className={classes.formControl} autoComplete="off">
        <div className={classes.dimentionsRow}>
          <div className={classes.dimentions}>
            <TextField
              error={!pkg.length.length && isEmptyInput}
              className={index%2 === 0 ? classes.backgroundDark : ''}
              size="small"
              variant="outlined"
              label={`Long${(index > 0) ? ` (${index + 1})` : ''}`}
              type="number"
              value={pkg.length}
              onChange={handleChange}
              name={KEY_NAMES[0]}
            />
            
            <Typography>x</Typography>

            <TextField
              error={!pkg.width.length && isEmptyInput}
              className={index%2 === 0 ? classes.backgroundDark : ''}
              size="small"
              variant="outlined"
              label={`Larg${(index > 0) ? ` (${index + 1})` : ''}`}
              type="number"
              value={pkg.width}
              onChange={handleChange}
              name={KEY_NAMES[1]}
            />
            
            <Typography>x</Typography>
            
            <TextField
              error={!pkg.height.length && isEmptyInput}
              className={index%2 === 0 ? classes.backgroundDark : ''}
              size="small"
              variant="outlined"
              label={`Haut${(index > 0) ? ` (${index + 1})` : ''}`}
              type="number"
              value={pkg.height}
              onChange={handleChange}
              name={KEY_NAMES[2]}
            />
          </div>
          <TextField
            error={!pkg.weight.length && isEmptyInput}
            className={index%2 === 0 ? classes.backgroundDark : ''}
            size="small"
            variant="outlined"
            label={`Poids${(index > 0) ? ` (${index + 1})` : ''}`}
            type="number"
            value={pkg.weight}
            onChange={handleChange}
            name={KEY_NAMES[3]}
          />
        </div>

        {(index > 0) ? <IconButton onClick={handleRemove(index)}>
          <RemoveCircleIcon className={classes.removeIcon} />
        </IconButton> : null}
      </form>
    </div>
  );
}


export default PriceCorrectionForm;
