import { TRANSPORT_FILTERS } from '../actions/types';

const initialValues = {
    isClickedFedEx: true,
    isClickedUPS: true,
    isClickedTNT: true,
    isClickedAramex: true,
    isClickedSpring: true,
    isClickedTransnat: true,
    isClickedFretcargo: true,
};

const issuesTransportFilters = (state = initialValues, action) => {
    switch (action.type) {
        case TRANSPORT_FILTERS:
            return action.payload;
        default:
            return state;
    };
};

export default issuesTransportFilters;
