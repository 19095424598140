import { CHAT_LOADING } from '../actions/types';


const chatLoading = (state = false, action) => {
  switch(action.type) {
    case CHAT_LOADING:
      return action.payload;
    default:
      return state;
  }
};


export default chatLoading;
