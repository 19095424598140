import axios from 'axios';
import { urlExpedismart } from '../common/Constants';

export const URL_EXPEDISMART = {
  Login: '/api-token-auth/',
  TrackingList: '/api/shipments/tracking',
  apiShipment: '/api/shipments',
  TrackingListArchive: '/api/shipments/archived',
  fromIssuesToTracking: '/api/shipments/156380/has_no_issue/',
  SearchShipment: '/api/shipments/search/?q=',
  ChatShipment: '/api/shipments/chat_ship_ids/?q=',
  ContactList: '/api/customers',
  ShipmentList: '/api/shipments/?filter=',
  CustomerInfo: '/api/shipments/customer',
  EnTransit: '/api/tracking/?s=',
  downloadInvoiceByID: '/en/user/download_invoice/?ship_id=',
  downloadAwbShipID: '/en/download_awb/?ship_id=',
  adjustments: '/api/customers/adjustments',
  transporters: '/api/customers/transporters',
  customersviewset: '/api/customersviewset',
  conversionfunnels: '/api/conversionfunnels/',
  getStatistics: '/api/statistics/shipments/',
  invoices: '/api/invoices',
  apiUsers: '/api/users',
};

export default axios.create({
  baseURL: `${urlExpedismart}`
});
