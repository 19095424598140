import { GET_TRACKINGS } from '../actions/types';

const trackings = (state = [], action) => {
  switch (action.type) {
      case GET_TRACKINGS:
        return action.payload.results;
      default:
        return state;
  }
};

export default trackings;
