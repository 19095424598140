import React from 'react';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const borderColor = '#010101';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F7F7F7',
  },
  table: {
    '& th, td': {
      padding: 0,
    },
  },
  tableHead:{
    background: '#d6dce4',

    '& th': {
      textAlign: 'center',
      fontWeight: '700',
      borderTop: `1px solid ${borderColor}`,
      borderRight: `1px solid ${borderColor}`,
      borderBottom: `1px solid ${borderColor}`,
    },

    '& th:first-child': {
      borderLeft: `1px solid ${borderColor}`,
    },
  },
  differentRows: {
    overflowWrap: 'anywhere',

    '& tr:nth-child(even)': {
      backgroundColor: '#d9d9d9',
    },

    '& .green': {
      backgroundColor: '#c6e0b4',
    },

    '& .red': {
      backgroundColor: '#fa9a9a',
    },

    '& td': {
      borderRight: `1px solid ${borderColor}`,
      borderBottom: `1px solid ${borderColor}`,
    },

    '& td:not(:first-child)': {
      textAlign: 'end',
    },

    '& td:first-child': {
      borderLeft: `1px solid ${borderColor}`,
    },
    
    '& td:not(.delta)': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },

    '& td.delta': {
      textAlign: 'center',
    },
  },
}));
// second: 17, second: 45,
const sampleData = [
  {
    first: 'janvier',
    second: 45,
    third: 17,
    fourth: 2458,
    fifth: 258,
    sixth: 17,
    seventh: 1525,
    eighth: 325,
    ninth: 17,
    tenth: 4587,
    eleventh: 1525,
    twelfth: 15,
    thirteenth: 1525,
    fourteenth: 15,
  },
  {
    first: 'Janvier N-2',
    second: 17,
    third: null,
    fourth: 2658,
    fifth: 127,
    sixth: null,
    seventh: 354,
    eighth: 125,
    ninth: null,
    tenth: 5985,
    eleventh: 354,
    twelfth: null,
    thirteenth: 354,
    fourteenth: null,
  },
  {
    first: 'février',
    second: 45,
    third: 17,
    fourth: 2458,
    fifth: 258,
    sixth: 17,
    seventh: 1525,
    eighth: 325,
    ninth: 17,
    tenth: 4587,
    eleventh: 1525,
    twelfth: 10,
    thirteenth: 1525,
    fourteenth: 10,
  },
  {
    first: 'février N-2',
    second: 17,
    third: null,
    fourth: 2658,
    fifth: 127,
    sixth: null,
    seventh: 354,
    eighth: 125,
    ninth: null,
    tenth: 5985,
    eleventh: 354,
    twelfth: null,
    thirteenth: 354,
    fourteenth: null,
  },
  {
    first: 'mars',
    second: 45,
    third: 17,
    fourth: 2458,
    fifth: 258,
    sixth: 17,
    seventh: 1525,
    eighth: 325,
    ninth: 17,
    tenth: 4587,
    eleventh: 1525,
    twelfth: 10,
    thirteenth: 1525,
    fourteenth: 10,
  },
  {
    first: 'mars N-2',
    second: 17,
    third: null,
    fourth: 2658,
    fifth: 127,
    sixth: null,
    seventh: 354,
    eighth: 125,
    ninth: null,
    tenth: 5985,
    eleventh: 354,
    twelfth: null,
    thirteenth: 354,
    fourteenth: null,
  },
  {
    first: 'avril',
    second: 45,
    third: 17,
    fourth: 2458,
    fifth: 258,
    sixth: 17,
    seventh: 1525,
    eighth: 325,
    ninth: 17,
    tenth: 4587,
    eleventh: 1525,
    twelfth: 10,
    thirteenth: 1525,
    fourteenth: 10,
  },
  {
    first: 'avril N-2',
    second: 17,
    third: null,
    fourth: 2658,
    fifth: 127,
    sixth: null,
    seventh: 354,
    eighth: 125,
    ninth: null,
    tenth: 5985,
    eleventh: 354,
    twelfth: null,
    thirteenth: 354,
    fourteenth: null,
  },
];

function Row(props) {
  const { first,
    second,
    third,
    fourth,
    fifth,
    sixth,
    seventh,
    eighth,
    ninth,
    tenth,
    eleventh,
    twelfth,
    thirteenth,
    fourteenth,
  } = props.item;

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>{first}</TableCell>
        <TableCell>{second}</TableCell>
        <TableCell className="delta">{third}</TableCell>
        <TableCell>{fourth}</TableCell>
        <TableCell>{fifth}</TableCell>
        <TableCell className="delta">{sixth}</TableCell>
        <TableCell>{seventh}</TableCell>
        <TableCell>{eighth}</TableCell>
        <TableCell className="delta">{ninth}</TableCell>
        <TableCell>{tenth}</TableCell>
        <TableCell>{eleventh}</TableCell>
        <TableCell className="delta">{twelfth}</TableCell>
        <TableCell>{thirteenth}</TableCell>
        <TableCell className="delta">{fourteenth}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function ByTypeOfCustomerBody() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table className={classes.table}>
          <colgroup>
            <col span="1" style={{width: "10%"}} />
            <col span="1" style={{width: "9%"}} />
            <col span="1" style={{width: "2%"}} />
            <col span="1" style={{width: "11%"}} />
            <col span="1" style={{width: "12%"}} />
            <col span="1" style={{width: "2%"}} />
            <col span="1" style={{width: "12%"}} />
            <col span="1" style={{width: "10%"}} />
            <col span="1" style={{width: "2%"}} />
            <col span="1" style={{width: "10%"}} />
            <col span="1" style={{width: "8%"}} />
            <col span="1" style={{width: "2%"}} />
            <col span="1" style={{width: "8%"}} />
            <col span="1" style={{width: "2%"}} />
          </colgroup>
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell>Mois</TableCell>
              <TableCell>Particuliers actifs</TableCell>
              <TableCell>&Delta;%</TableCell>
              <TableCell>Particuliers inactifs</TableCell>
              <TableCell>Entreprises Actives</TableCell>
              <TableCell>&Delta;%</TableCell>
              <TableCell>Entreprises inactives</TableCell>
              <TableCell>Total actifs</TableCell>
              <TableCell>&Delta;%</TableCell>              
              <TableCell>Total inactifs</TableCell>
              <TableCell>Premium</TableCell>
              <TableCell>&Delta;%</TableCell>
              <TableCell>Gold</TableCell>
              <TableCell>&Delta;%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.differentRows}>
            {sampleData.map((item, i) => <Row item={item} key={i} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ByTypeOfCustomerBody;
