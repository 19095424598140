import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { setSCustomerFilterButtons } from '../../actions';


const useStyles = makeStyles((theme) => ({
  secondLine: {
    marginBottom: theme.spacing(.5),
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  marginLeft16: {
    marginLeft: theme.spacing(2),
  },
}));

const theme = createTheme({
  breakpoints: {
    values: {
      lg: 1664,
    },
  },
});

const theme2 = createTheme({
  breakpoints: {
    values: {
      lg: 1820,
    },
  },
});

function ByTypeOfCustomerHeader(props) {
  const classes = useStyles();
  const { statisticsCustomerFilterButtons,
    setSCustomerFilterButtons,
    drawerOpen,
  } = props;

  const handleFilters = props => () => {
    if(props === 'all') {
      return setSCustomerFilterButtons({ ...statisticsCustomerFilterButtons,
        twelveMonth: true,
        quarterFirst: true,
        quarterSecond: true,
        quarterThird: true,
        quarterFouth: true,
        daysSeven: true,
        daysOne: true,
        n1: true,
        n2: true,
        n3: true,
        inactiveOne: true,
        inactiveThree: true,
        inactiveSix: true,
        inactiveTwelve: true,
      });
    }

    return setSCustomerFilterButtons({ ...statisticsCustomerFilterButtons, [props]: !statisticsCustomerFilterButtons[props] });
  };
  
  return(
    <div>
      <ThemeProvider theme={drawerOpen ? theme2 : theme}>
        <Grid container spacing={2}>
          <Grid item xs={2} lg={3} />
          <Grid item xs={7} lg={6}
            container
            justifyContent="space-between"
          >
            <Button
              className={classes.buttonLowerCase}
              size="small"
              variant={statisticsCustomerFilterButtons.twelveMonth ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.twelveMonth ? "secondary" : "default"}
              onClick={handleFilters('twelveMonth')}
            >
              12 mois
            </Button>
            <Button
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={statisticsCustomerFilterButtons.quarterFirst ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.quarterFirst ? "secondary" : "default"}
              onClick={handleFilters('quarterFirst')}
            >
              1ère trimestre
            </Button>
            <Button
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={statisticsCustomerFilterButtons.quarterSecond ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.quarterSecond ? "secondary" : "default"}
              onClick={handleFilters('quarterSecond')}
            >
              2ème trimestre
            </Button>
            <Button
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={statisticsCustomerFilterButtons.quarterThird ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.quarterThird ? "secondary" : "default"}
              onClick={handleFilters('quarterThird')}
            >
              3ème trimestre
            </Button>
            <Button
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={statisticsCustomerFilterButtons.quarterFouth ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.quarterFouth ? "secondary" : "default"}
              onClick={handleFilters('quarterFouth')}
            >
              4ème trimestre
            </Button>
            <Button
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={statisticsCustomerFilterButtons.daysSeven ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.daysSeven ? "secondary" : "default"}
              onClick={handleFilters('daysSeven')}
            >
              7 jours
            </Button>
            <Button
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={statisticsCustomerFilterButtons.daysOne ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.daysOne ? "secondary" : "default"}
              onClick={handleFilters('daysOne')}
            >
              1 jours
            </Button>
          </Grid>
          <Grid item xs={3} lg={3} />
          <Grid item xs={2} lg={3} />
          <Grid item xs={4} lg={3}
            className={classes.secondLine}
            container
            justifyContent="flex-start"
          >
            <Button
              className={classes.buttonLowerCase}
              size="small"
              variant={statisticsCustomerFilterButtons.n1 ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.n1 ? "secondary" : "default"}
              onClick={handleFilters('n1')}
            >
              N-1
            </Button>
            <Button
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={statisticsCustomerFilterButtons.n2 ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.n2 ? "secondary" : "default"}
              onClick={handleFilters('n2')}
            >
              N-2
            </Button>
            <Button
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={statisticsCustomerFilterButtons.n3 ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.n3 ? "secondary" : "default"}
              onClick={handleFilters('n3')}
            >
              N-3
            </Button>
          </Grid>
          <Grid item xs={3} lg={3} />
          <Grid item xs={3} lg={3} />

          <Grid item xs={2} lg={3} />
          <Grid item xs={7} lg={6}
            className={classes.secondLine}
            container
            justifyContent="flex-start"
          >
            <Button
              className={classes.buttonLowerCase}
              size="small"
              variant={statisticsCustomerFilterButtons.inactiveOne ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.inactiveOne ? "secondary" : "default"}
              onClick={handleFilters('inactiveOne')}
            >
              {'Inactifs < 1 mois'}
            </Button>
            <Button
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={statisticsCustomerFilterButtons.inactiveThree ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.inactiveThree ? "secondary" : "default"}
              onClick={handleFilters('inactiveThree')}
            >
              {'Inactifs < 3 mois'}
            </Button>
            <Button
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={statisticsCustomerFilterButtons.inactiveSix ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.inactiveSix ? "secondary" : "default"}
              onClick={handleFilters('inactiveSix')}
            >
              {'Inactifs < 6 mois'}
            </Button>
            <Button
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={statisticsCustomerFilterButtons.inactiveTwelve ? "contained" : "outlined"}
              color={statisticsCustomerFilterButtons.inactiveTwelve ? "secondary" : "default"}
              onClick={handleFilters('inactiveTwelve')}
            >
              {'Inactifs < 12 mois'}
            </Button>
          </Grid>        
          <Grid item xs={3} lg={3} />
        </Grid>
      </ThemeProvider>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    statisticsCustomerFilterButtons: state.statisticsCustomerFilterButtons,
    drawerOpen: state.drawerOpen,
  };
};

export default connect(mapStateToProps, { setSCustomerFilterButtons })(ByTypeOfCustomerHeader);
