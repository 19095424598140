import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import CorrespondencePopUp from '../correspondence/CorrespondencePopUp';
import PopUpDelete from './PopUpDelete';
import { setContactRequest,
  setCRLoading,
  setRefreshCR,
  editCR,
} from '../actions'
import { convertDateToWithDots, convertHoursAndMinutes } from '../common/functions';
import { logOut } from '../common/Frame';


const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F7F7F7',
  },
  tableHead:{
    background: '#727272',
    '& th': {
      textAlign: 'center',
      color: '#fff',
      fontWeight: '700',
    },
  },
  differentRows: {
    overflowWrap: 'anywhere',
    '& td': {
      textAlign: 'center',
    },
  },
  tableFirstColumn: {
    textAlign: 'left !important',
  },
  loadingOrNotFound: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '2.5rem',
    color: '#9e9b9b',
    height: '60vh',
  },
  statusActions: {
    '& .MuiButtonBase-root': {
      textTransform: 'none',
    },

    '& .taskStatus0': {
      background: '#ffd18e',
    },
    '& .taskStatus1': {
      background: '#fefa71',
    },
    '& .taskStatus2': {
      background: '#94eb94',
    },

    display: 'inline-flex',
    gap: theme.spacing(1.5),
  },
  formAssignedTo: {
    width: 190,

    [theme.breakpoints.up(1600)]: {
      width: 220,
    },
  },
  openCommentTaskButton: {
    textTransform: "none",
    fontSize: ".95rem",
    width: '90%',
  },
  tasksSeperate: {
    marginTop: theme.spacing(2),

    '& div:not(:last-child)': {
      marginRight: theme.spacing(.6),
    },
    
    [theme.breakpoints.down(1600)]: {
      '& div:not(:last-child)': {
        marginRight: theme.spacing(.4),
      },      
    },
  },
  tableCellCorrespondance: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
  headerTop: {
    backgroundColor: '#fafafa',
    padding: '12px 24px',
  },
  firstRow: {
    '& td': {
      borderBottom: 'none',
      paddingBottom: 0,
    },

    '& td:nth-child(2)': {
      whiteSpace: 'pre-wrap',
    },    
  },
  phoneEmail: {
    textAlign: 'left !important',
    paddingTop: 0,
    marginTop: 0,
    borderTop: 'none',
  },
}));

function HeaderTop({ item }) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      className={classes.headerTop}
    >
      <Grid item xs={6}>
        <span>{item.contact_name}</span><br />
        <span>{item.contact_phone}</span><br />
        <span><a href={`mailto:${item.contact_email}`}>{item.contact_email}</a></span>
      </Grid>
      <Grid item xs={6}>
        <span><strong>{item.nbr_shipments}</strong> (Nombre d’envois mensuels estimés)</span><br />
        <span>{item.comment.split("\\n").join("")}</span>
      </Grid>
    </Grid>
  );
}

function Row(props) {
  const classes = useStyles();
  const { item,
    users,
    crList,
    crDetails,
    currentUser,
    editCR,
    crTaskCommentCount,
    crFilterButtons,
    refreshLocal,
    setRefreshLocal,
  } = props;
  const [assignedTo, setAssignedTo] = useState((crDetails && crDetails.to_users) ? crDetails.to_users : '');
  const [statusButtons, setStatusButtons] = useState({
    untreated: (crDetails && crDetails.task_status === 1) ? true : false,
    inProgress: (crDetails && crDetails.task_status === 2) ? true : false,
    ended: (crDetails && crDetails.task_status === 3) ? true : false,
  });

  useEffect(() => {
    if(crDetails) {
      setAssignedTo(crDetails.to_users ? crDetails.to_users : '');
      setStatusButtons({
        untreated: (crDetails.task_status === 1) ? true : false,
        inProgress: (crDetails.task_status === 2) ? true : false,
        ended: (crDetails.task_status === 3) ? true : false,
      });
    }
  }, [crDetails]);

  const handleStatusButtons = (props, status) => () => {
    if(statusButtons[props]) { return; }

    const body = { task_status: status, updated_by: currentUser };
    return editCR(item.id, body).finally(() => {
      if(!crFilterButtons[props]) {
        setRefreshLocal(refreshLocal + 1);
      }
    });
  };

  const handleAssignedTo = event => {
    const body = { to_users: event.target.value.length ? event.target.value : null, updated_by: currentUser };
    editCR(item.id, body);
  };
  
  const cleancombinedText = DOMPurify.sanitize(item.comment.split("\\n").join("\n"), { USE_PROFILES: { html: true } });

  return (
    <React.Fragment>
      <TableRow className={classes.firstRow}>
        <TableCell className={classes.tableFirstColumn}>
          {item.id}
        </TableCell>
        <TableCell>
          <strong>
            {crList}
          </strong>
          <div
            dangerouslySetInnerHTML={{ __html: cleancombinedText}}
          />          
        </TableCell>
        <TableCell>
          <FormControl className={classes.formAssignedTo}>
            <Select
              disabled={!crDetails}
              value={assignedTo}
              onChange={handleAssignedTo}
              displayEmpty
              className={classes.selectEmpty}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {users.filter(f => f.is_actif).map(user => (<MenuItem key={user.id} value={user.id.toString()}>{user.username}</MenuItem>))}              
            </Select>
          </FormControl>
        </TableCell>
        <TableCell className={classes.tableCellCorrespondance}>          
          <CorrespondencePopUp
            parentId={item.id}
            goToTab={0}
            headerTop={<HeaderTop item={item} />}
            isRequest={true}
            isShipment={false}
            customer_id={0}
            tracking_nbr={''}
            disablePopUp={false}
            countCommentsAndTasks={crTaskCommentCount}
            isChatPage={false}
            email={item.contact_email}
          />
          <div className={classes.tasksSeperate}>
            <CorrespondencePopUp
              parentId={item.id}
              goToTab={1}
              headerTop={<HeaderTop item={item} />}
              isRequest={true}
              isShipment={false}
              customer_id={0}
              tracking_nbr={''}
              disablePopUp={false}
              countCommentsAndTasks={crTaskCommentCount}
              isChatPage={false}
              email={item.contact_email}
            />
            <CorrespondencePopUp
              parentId={item.id}
              goToTab={2}
              headerTop={<HeaderTop item={item} />}
              isRequest={true}
              isShipment={false}
              customer_id={0}
              tracking_nbr={''}
              disablePopUp={false}
              countCommentsAndTasks={crTaskCommentCount}
              isChatPage={false}
              email={item.contact_email}
            />
            <CorrespondencePopUp
              parentId={item.id}
              goToTab={3}
              headerTop={<HeaderTop item={item} />}
              isRequest={true}
              isShipment={false}
              customer_id={0}
              tracking_nbr={''}
              disablePopUp={false}
              countCommentsAndTasks={crTaskCommentCount}
              isChatPage={false}
              email={item.contact_email}
            />            
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.statusActions}>
            <Button
              className={statusButtons.untreated ? 'taskStatus0' : ''}
              size="small"
              variant="outlined"
              onClick={handleStatusButtons('untreated', 1)}
            >
              Non traité
            </Button>
            <Button
              className={statusButtons.inProgress ? 'taskStatus1' : ''}
              size="small"
              variant="outlined"
              onClick={handleStatusButtons('inProgress', 2)}
            >
              En cours
            </Button>
            <Button
              className={statusButtons.ended ? 'taskStatus2' : ''}
              size="small"
              variant="outlined"
              onClick={handleStatusButtons('ended', 3)}
            >
              Terminé
            </Button>
            <PopUpDelete id={item.id} />
          </div>
        </TableCell>
        <TableCell>
          {crDetails ? `${convertDateToWithDots(crDetails.created_at*1000)} (${convertHoursAndMinutes(crDetails.created_at*1000)})` : 'Loading date...'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} className={classes.phoneEmail}>
          {item.contact_phone} | {item.contact_email}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const stylesTablePag = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = stylesTablePag();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  
  return (<div className={classes.root}>
    <IconButton
      onClick={handleFirstPageButtonClick}
      disabled={page === 0}
      aria-label="first page"
    >
      {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
    </IconButton>
    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
      {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
    </IconButton>
    <IconButton
      onClick={handleNextButtonClick}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      aria-label="next page"
    >
      {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
    </IconButton>
    <IconButton
      onClick={handleLastPageButtonClick}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      aria-label="last page"
    >
      {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
    </IconButton>
  </div>);
}

TablePaginationActions.propTypes = {
count: PropTypes.number.isRequired,
onPageChange: PropTypes.func.isRequired,
page: PropTypes.number.isRequired,
rowsPerPage: PropTypes.number.isRequired,
};

function ContactRequestBody(props) {
  const classes = useStyles();
  const { crSearch,
    users,
    setContactRequest,
    setCRLoading,
    contactRequest,
    crTypes,
    crLoading,
    crDetails,
    setRefreshCR,
    crRefresh,
    editCR,
    crFilterButtons,
    crTaskCommentCount,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [fromDateBigger] = useState(false);
  const [refreshLocal, setRefreshLocal] = useState(0);
  
  const username = localStorage.getItem('username');
  if(!username) logOut();
  const currentUser = users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0] === undefined ? 0 : users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0].id;

  useEffect(() => {
    // Get Contact Request
    setCRLoading(true);
    setContactRequest();
  }, [setCRLoading, setContactRequest, crFilterButtons, refreshLocal]);

  useEffect(() => {
    setPage(0);
  }, [crSearch]);

  const countCustomerRequests = contactRequest.length;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, countCustomerRequests - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ifSearchNotFound = (<TableRow>
    <TableCell colSpan={8} className={classes.loadingOrNotFound} style={{ color: 'red' }}>Not found any Contact Request.</TableCell>
  </TableRow>);
  
  const empty = (<TableRow>
    <TableCell colSpan={8} className={classes.loadingOrNotFound}>(Empty, Try To Choose Other Dates)</TableCell>
  </TableRow>);

  const ifGetLoading = (<TableRow>
    <TableCell colSpan={8} className={classes.loadingOrNotFound}>Loading...</TableCell>
  </TableRow>);
  
  const ifFromDateBigger = (<TableRow>
    <TableCell colSpan={8} className={classes.loadingOrNotFound} style={{ color: 'red' }}>End date <u>cannot</u> be earlier than start date.</TableCell>
  </TableRow>);

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table>
          <colgroup>
            <col span="1" style={{width: "8%"}} />
            <col span="1" style={{width: "18%"}} />
            <col span="1" style={{width: "17%"}} />
            <col span="1" style={{width: "17%"}} />
            <col span="1" style={{width: "25%"}} />
            <col span="1" style={{width: "15%"}} />
          </colgroup>
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell className={classes.tableFirstColumn}>Contact ID</TableCell>
              <TableCell>Request</TableCell>
              <TableCell>Assigned to</TableCell>
              <TableCell>Correspondance</TableCell>
              <TableCell>Satut</TableCell>
              <TableCell>Date of request</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.differentRows}>
            {fromDateBigger ? ifFromDateBigger
            : (crLoading || !(crTypes.length === 5)) ? ifGetLoading
            : (!countCustomerRequests && crSearch.length) ? ifSearchNotFound
            : !countCustomerRequests ? empty
            : contactRequest.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => <Row
              key={item.id}
              item={item}
              users={users}
              currentUser={currentUser}
              crList={crTypes.filter(fl => (fl.id === item.type_webrequest))[0].name}
              setContactRequest={setContactRequest}
              setRefreshCR={setRefreshCR}
              crRefresh={crRefresh}
              editCR={editCR}
              crDetails={crDetails.filter(fl => (fl.id === item.id))[0]}
              crTaskCommentCount={crTaskCommentCount}
              crFilterButtons={crFilterButtons}
              refreshLocal={refreshLocal}
              setRefreshLocal={setRefreshLocal}
            />)}
            {emptyRows > 0 && <TableRow style={{ height: 0 * emptyRows }} />}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[20, 30, 60]}
          colSpan={3}
          component="div"
          count={countCustomerRequests}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    crSearch: state.crSearch,
    users: state.users,
    contactRequest: state.contactRequest,
    crTypes: state.crTypes,
    crLoading: state.crLoading,
    crDetails: state.crDetails,
    crTaskCommentCount: state.crTaskCommentCount,
    crFilterButtons: state.crFilterButtons,
    crRefresh: state.crRefresh,
  };
};

export default connect(mapStateToProps, {
  setContactRequest,
  setCRLoading,
  setRefreshCR,
  editCR,
})(ContactRequestBody);
