import { STATISTICS_LOADING_COUNT } from '../actions/types';

const statisticsLoadingCount = (state = 0, action) => {
  switch (action.type) {
    case STATISTICS_LOADING_COUNT:
      return action.payload;
    default:
      return state;
  }
};

export default statisticsLoadingCount;
