import { SHIPMENT_TRANSPORT_DASH_LOAD } from '../actions/types';

const shipmentTransportDashLoad = (state = false, action) => {
    switch (action.type) {
        case SHIPMENT_TRANSPORT_DASH_LOAD:
            return action.payload;
        default:
            return state;
    }
};

export default shipmentTransportDashLoad;
