import { CHAT_ERROR } from '../actions/types';


const chatError = (state = false, action) => {
  switch(action.type) {
    case CHAT_ERROR:
      return action.payload;
    default:
      return state;
  }
};


export default chatError;
