import { CONTACT_REQUEST_COUNT } from '../actions/types';


const initialValues = {
    total: 0,

    general: 0,
    technicalProblem: 0,
    discount: 0,
    pallet: 0,
    estimate: 0,
};

const contactRequestCount = (state = initialValues, action) => {
    switch (action.type) {
        case CONTACT_REQUEST_COUNT:
            return action.payload;
        default:
            return state;
    }
};

export default contactRequestCount;
