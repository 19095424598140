import { GET_TASKS_DASHBOARD } from '../actions/types';

const dashboardGetTasks = (state = [], action) => {
    switch (action.type) {
        case GET_TASKS_DASHBOARD:
            return action.payload;
        default:
            return state;
    }
};

export default dashboardGetTasks;
