import { GET_SHIPMENTS_DASHBOARD } from '../actions/types';

const dashboardShipments = (state = [], action) => {
    switch (action.type) {
        case GET_SHIPMENTS_DASHBOARD:
            return action.payload.results;
        default:
            return state;
    };
};

export default dashboardShipments;
