import { DELETED_SHIPMENT } from '../actions/types';

// action.payload is shipment id
const deleteShipment = (state = [], action) => {
  switch (action.type) {
    case DELETED_SHIPMENT:
      return [...state, action.payload];
    default:
      return state;
  }
};

export default deleteShipment;
