import { CR_TASKS_COMMENTS_COUNT } from '../actions/types';


const crTaskCommentCount = (state = {}, action) => {
  switch (action.type) {
    case CR_TASKS_COMMENTS_COUNT:
      return action.payload;
    default:
      return state;
  }
};

export default crTaskCommentCount;
