import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getShipmentForDashboard, setShipmentsDashboardLoading } from '../actions';
import useToken from '../login/useToken';
import ShipmentsOpenSelected from './ShipmentsOpenSelected';
import { convertDateToWithDash, convertDateToFrench } from '../common/functions';


const useStyles = makeStyles((theme) => ({
  cardContent: {
    border: '1px solid #ddd',
    overflow: 'hidden',
    paddingTop: 0,
    
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  contentHeader: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentBody: {
    overflowY: 'scroll',
    height: 250,
  },
  addEventButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableBody: {
    '& .MuiTableRow-root': {
      '&:nth-child(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },

    '& .MuiTableCell-body': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      
      '&:nth-child(2)': {
        textAlign: 'center',
      },
      '&:last-child': {
        textAlign: 'end',
      },
    },
  },    
  dashboardShipmentsLoading: {
    textAlign: 'start !important',
  },
  subtitleCenter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function Shipments(props) {
  const classes = useStyles();
  const { token } = useToken();
  const { dashboardShipments,
    dashboardShipmentsLoading,
    getShipmentForDashboard,
    setShipmentsDashboardLoading
  } = props;
  const [days, setDays] = useState(0);
  // const [days, setDays] = useState(-126);

  useEffect(() => {
    setShipmentsDashboardLoading(true);
    // getShipmentForDashboard('2021-09-09', token);
    getShipmentsByDate(days);
  }, [token]);

  const loading = (<TableRow>
    <TableCell colSpan={3} className={classes.dashboardShipmentsLoading}>Loading...</TableCell>
  </TableRow>);

  const getShipmentsByDate = days => {
    let desiredDay = new Date();
    desiredDay.setDate(desiredDay.getDate() + days);
    
    // getShipmentForDashboard('2021-09-09', token);
    setShipmentsDashboardLoading(true);
    getShipmentForDashboard(convertDateToWithDash(desiredDay), token);
  };
  
  const selectedDayName = () => {
    let desiredDay = new Date();
    desiredDay.setDate(desiredDay.getDate() + days);

    if(days === 0) {
      return 'Aujourd’hui';
    } else if(days === -1) {
      return 'Hier';
    } else {
      return convertDateToFrench(desiredDay);
    }
                              
  };

  return (
    <Card className="dashboard-box">
      <Grid container>
        <Grid item xs={12}>
          <Typography
            className="box-title"
            gutterBottom
            variant="h5"
            component="h2"
          >
            Envois
          </Typography>
        </Grid>
      </Grid>
      <CardContent className={classes.cardContent}>
        <CardActions className={classes.contentHeader}>
          <Grid container>
            <Grid item xs={3} />
            <Grid item xs={6} className={classes.subtitleCenter}>
              <IconButton
                size="small"
                onClick={() => {
                  setDays(days - 1);
                  getShipmentsByDate(days - 1);
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
              <Typography
              >
                {selectedDayName()}
              </Typography>
              <IconButton
                size="small"
                onClick={() => {
                  setDays(days + 1);
                  getShipmentsByDate(days + 1);
                }}
              >
                <ChevronRightIcon />
              </IconButton>
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </CardActions>
        <div className={classes.contentBody}>
          <Table>
            <colgroup>
              <col span="1" style={{width: "35%"}} />
              <col span="1" style={{width: "35%"}} />
              <col span="1" style={{width: "30%"}} />
            </colgroup>
            <TableBody className={classes.tableBody}>
              {dashboardShipmentsLoading ? loading : dashboardShipments.map(shipment => <ShipmentsOpenSelected key={shipment.id} shipment={shipment} />)}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = state => {
  return {
    dashboardShipments: state.dashboardShipments,
    dashboardShipmentsLoading: state.dashboardShipmentsLoading
  };
};

export default connect(mapStateToProps, { getShipmentForDashboard, setShipmentsDashboardLoading })(Shipments);
