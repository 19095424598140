import { CA_GO_TO } from '../actions/types';


const caGoTo = (state = false, action) => {
  switch(action.type) {
    case CA_GO_TO:
      return action.payload;
    default:
      return state;
  }
};


export default caGoTo;
