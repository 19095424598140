import { GET_TASK_REMINDER } from '../actions/types';

const alertTasks = (state = [], action) => {
    switch (action.type) {
        case GET_TASK_REMINDER:
            return action.payload;
        default:
            return state;
    };
};

export default alertTasks;
