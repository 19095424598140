import '../css/TextEditor.css';
import '../../node_modules/draft-js/dist/Draft.css';
import React, { useEffect, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Editor,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { URL_All } from '../common/Constants';
import { numberThousands } from '../common/Frame';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    formControl: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
        width: "90%",
    },
    toWho: {
        width: "80%",
    },
    dateTimeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dateTime: {
        width: 210,
    },
    inputButton: {
        width: "100%",
        border: "1px dashed #ccc",
        paddingLeft: theme.spacing(1),
    },
    dialogActions: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    paper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        padding: theme.spacing(.5),
        marginBottom: theme.spacing(1),
        background: '#f6f6f6',
    },
    highlightOffIcon: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        color: '#808080',
    },
    noBorder: {
        border: "none",
    },
    dialogContent: {
        padding: '0px 12px',
        minHeight: '30vh',
    },
    dialogTitle: {
        '& .RichEditor-controls': {
            '&>*': {
                fontSize: '1.5rem',
                width: 50,
            },            

            '& .MuiButtonBase-root:first-child': {
                marginLeft: -12,
            },
            
            '& .MuiButtonBase-root:not(last-child)': {
                marginRight: theme.spacing(1),
            },
        },
    },
}));


function TextEditorNew(props) {
    const { editorState, onChange, focusInput } = props;
  
    useEffect(() => {
        focusInput.current.focus();
    }, []);
  
    const handleKeyCommand = (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        onChange(newState);
        return true;
      }
      return false;
    }
  
    const mapKeyToEditorCommand = (e) => {
      if (e.keyCode === 9 /* TAB */) {
        const newEditorState = RichUtils.onTab(
          e,
          editorState,
          4, /* maxDepth */
        );
        if (newEditorState !== editorState) {
          onChange(newEditorState);
        }
        return;
      }
      return getDefaultKeyBinding(e);
    }
    
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }
  
    return (
      <div className="RichEditor-root">
        <div className={className}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onChange}
            placeholder="Write a task..."
            ref={focusInput}
          />
        </div>
      </div>
    );
}
  
// Custom overrides for "code" style.
const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
};
  
function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote': return 'RichEditor-blockquote';
      default: return null;
    }
}
  
class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }
  
    render() {  
      return (
        <IconButton
            className={this.props.style}
            color={this.props.active ? 'secondary' : 'default'}
            onMouseDown={this.onToggle}
        >
          {this.props.label}
        </IconButton>
      );
    }
}
  
  
var INLINE_STYLES = [
    {label: 'G', style: 'BOLD'},
    {label: 'I', style: 'ITALIC'},
    {label: 'S', style: 'UNDERLINE'},
];
  
const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) =>
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function TaskForm(props) {
    const { postCommentTask } = props;
    const [task, setTask] = useState('');
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [personName, setPersonName] = useState([]);
    const [personIDs, setPersonIDs] = useState([]);
    const [users, setUsers] = useState([]);
    const [dueTo, setDueTo] = useState(new Date());
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const scrollTo = useRef(null);
    const focusInput = useRef(null);

    useEffect(() => {
        fetch(URL_All.Users)
          .then(response => response.json())
          .then(json => setUsers(json))
          .catch(error => console.error(error))
      }, []);

    const handleChange2 = (event) => {
        setPersonName(event.target.value);
        setPersonIDs(users.filter(r => event.target.value.includes(r.username)).map(item => item.id));
    };

    function handleChange3(e) {
        setDueTo(e.target.value);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postCommentTask(task, selectedFiles, personIDs.length > 0 ? personIDs.toString() : null, dueTo, 1);
        setTask('');
        setPersonIDs([]);
        setPersonName([]);
        setSelectedFiles([]);
        setEditorState(EditorState.createEmpty());
        setDueTo(new Date());
        handleClose();
    };

    const scrollToBottom = () => {
        scrollTo.current.scrollIntoView({ behavior: 'smooth' });
    };

    const onChangeFile = (files) => {
        setSelectedFiles(selectedFiles.concat(files));
        scrollToBottom();
    }; 

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: onChangeFile,
        onChange: onChangeFile
    });

    const deleteItem = (filt) => {
        setSelectedFiles(selectedFiles.filter(item => item !== filt));
    };

    const handleEditorState = (editorState) => {
        setEditorState(editorState);

        var contentState = editorState.getCurrentContent();
        setTask(draftToHtml(convertToRaw(contentState)));
      };     
    
    const toggleInlineStyle = (inlineStyle) => {
        handleEditorState(
          RichUtils.toggleInlineStyle(
            editorState,
            inlineStyle
          )
        );
    }

    const renderSelectedFiles = selectedFiles.length ? (selectedFiles.map((file, index) => {
        return (
            <Paper className={classes.paper} key={index}>
                <span>{file.name} <span style={{ color: 'grey' }}>({numberThousands(Math.trunc(file.size / 1024))}KB)</span></span>
                <span className={classes.highlightOffIcon}>
                    <HighlightOffIcon onClick={() => deleteItem(file)} />
                </span>
            </Paper>
        );
    })) : null;

    return(
    <div>
        <Tooltip title="Ajouter une tâche" onClick={handleClickOpen}>
            <IconButton aria-label="add">
                <AddCircleOutlineRoundedIcon />
            </IconButton>
        </Tooltip>
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="form-dialog-title"
            className={classes.root}
        >
            <Grid
                container
                direction="row"
                alignItems="flex-start"
                className={classes.formControl}
            >
                <Grid item xs={6}>
                    <FormControl className={classes.toWho}>
                        <InputLabel id="task-mutiple-checkbox-label" className={classes.dropDown}>Tâche assignée à:</InputLabel>
                        <Select
                            labelId="users-mutiple-checkbox-label"
                            id="users-mutiple-checkbox"
                            multiple
                            value={personName}
                            onChange={handleChange2}
                            input={<Input />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {users.filter(user => (user.is_actif === 1)).sort(function(a, b) {
                                let x = a.username.toLowerCase();
                                let y = b.username.toLowerCase();
                                if(x < y) { return -1; }
                                if(x > y) { return 1; }
                                return 0;
                            }).map((item) => (
                                <MenuItem key={item.id} value={item.username}>
                                    <Checkbox checked={personName.indexOf(item.username) > -1} />
                                    <ListItemText primary={item.username} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <form className={classes.dateTimeContainer} noValidate>
                        <TextField
                            id="datetime-task"
                            label="Due to:"
                            type="datetime-local"
                            className={classes.dateTime}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            defaultValue={(new Date(dueTo).getFullYear()).toString()+'-'+(new Date(dueTo).getMonth()+1).toString().padStart(2, '0')+'-'+(new Date(dueTo).getDate()).toString().padStart(2, '0')+'T'+(new Date(dueTo).getHours()).toString().padStart(2, '0')+':'+(new Date(dueTo).getMinutes()).toString().padStart(2, '0')}
                            onChange={handleChange3}
                        />
                    </form>
                </Grid>
            </Grid>
            <DialogTitle className={classes.dialogTitle}>
                Tâche à faire :
                <InlineStyleControls
                    editorState={editorState}
                    onToggle={toggleInlineStyle}
                />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <TextEditorNew
                    editorState={editorState}
                    onChange={handleEditorState}
                    focusInput={focusInput}
                />
                {renderSelectedFiles}
                <div ref={scrollTo} />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <span className={classes.inputButton} {...getRootProps({
                            // onChange: onChangeFile
                        })}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    }
                </span>
                <Button
                    disabled={task.trim().length  <= 7 || personName.length < 1}
                    color="primary"
                    onClick={handleSubmit}
                >
                    Enregistrer
                </Button>
            </DialogActions>
      </Dialog>
    </div>
    );
}
