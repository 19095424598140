import React, { useEffect, useCallback } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { convertDateToFrench } from '../common/functions';
import CalendarEditPopUp from './CalendarEditPopUp';
import { DAILY } from '../common/constantsEN';
import { getMonthFrenchAbbr } from '../common/constantsFR';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
    selectedRow: {
      cursor: 'pointer',
    },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CalendarShowSelected(props) {
  const classes = useStyles();
  const { item,
    handleEditEvent,
    handleDeleteEvent,
    isSignedIn,
    days,
    view
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleEdit = editedEvent => {
    handleEditEvent(item.id, editedEvent);
  };

  const handleDelete = () => {
    setOpen(false);
    handleDeleteEvent(item.id);
  };

  useEffect(() => {
    if(!isSignedIn) {
      handleClose();
    }
  }, [handleClose, isSignedIn]);

  if(!item) {
    return null;
  }

  const showGuests = !item.attendees ? '' : item.attendees.map(item => item.email).join(', ').length > 13 ? `${item.attendees.map(item => item.email).join(', ').slice(0, 13)}...` : item.attendees.map(item => item.email).join(', ');
  
  const showDateTime = () => {
    if(item.start.dateTime && item.end.dateTime) {
      return <React.Fragment>
        <strong>Starts: </strong>{convertDateToFrench(new Date(item.start.dateTime))}, {`${(new Date(item.start.dateTime)).getHours().toString().padStart(2, '0')}:${(new Date(item.start.dateTime)).getMinutes().toString().padStart(2, '0')}`}<br />
        <strong>Ends: </strong>{convertDateToFrench(new Date(item.end.dateTime))}, {`${(new Date(item.end.dateTime)).getHours().toString().padStart(2, '0')}:${((new Date(item.end.dateTime)).getMinutes()).toString().padStart(2, '0')}`}
      </React.Fragment>;

    } else if(item.start.date && item.end.date) {
      return <React.Fragment>
        <strong>Starts: </strong>All day<br />
        <strong>Ends: </strong>All day
      </React.Fragment>;

    }
  };

  let desiredDay = new Date();
  desiredDay.setDate(desiredDay.getDate() + days);

  const showBriefDateTime = () => {
    if(view === DAILY && item.start.dateTime && item.end.dateTime) {
      if(convertDateToFrench(new Date(item.start.dateTime)) === convertDateToFrench(new Date(item.end.dateTime))) {
        return <React.Fragment>
          {`${(new Date(item.start.dateTime)).getHours().toString().padStart(2, '0')}:${(new Date(item.start.dateTime)).getMinutes().toString().padStart(2, '0')}`} - {`${(new Date(item.end.dateTime)).getHours().toString().padStart(2, '0')}:${((new Date(item.end.dateTime)).getMinutes()).toString().padStart(2, '0')}`}
        </React.Fragment>;
      } else if(convertDateToFrench(new Date(item.start.dateTime)) === convertDateToFrench(desiredDay)) {
        return <React.Fragment>
          {`${(new Date(item.start.dateTime)).getHours().toString().padStart(2, '0')}:${(new Date(item.start.dateTime)).getMinutes().toString().padStart(2, '0')}`} - ...
        </React.Fragment>;      
      } else if(convertDateToFrench(desiredDay) === convertDateToFrench(new Date(item.end.dateTime))) {
        return <React.Fragment>
          ... - {`${(new Date(item.end.dateTime)).getHours().toString().padStart(2, '0')}:${((new Date(item.end.dateTime)).getMinutes()).toString().padStart(2, '0')}`}
        </React.Fragment>;
      } else {
        return <React.Fragment>... - ...</React.Fragment>;
      }
      
    } else if(item.start.dateTime && item.end.dateTime) {
      return `${new Date(item.start.dateTime).getDate()} ${getMonthFrenchAbbr(item.start.dateTime)} - ${new Date(item.end.dateTime).getDate()} ${getMonthFrenchAbbr(item.end.dateTime)}`;

    } else if(item.start.date && item.end.date) {
      return 'All day';
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.selectedRow} onClick={handleClickOpen}>
        <TableCell>{!item.summary ? <em>(No title)</em> : item.summary.length > 16 ? `${item.summary.slice(0, 16)}...` : item.summary}</TableCell>
        <TableCell>{item.attendees ? showGuests : <em>(empty)</em>}</TableCell>
        <TableCell>
          {showBriefDateTime()}
        </TableCell>
      </TableRow>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Selected Agenda
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <strong>Title: </strong>{!item.summary ? <em>(No title)</em> : item.summary}<br />
            {showDateTime()}<br />
            <strong>Guests: </strong>{item.attendees ? item.attendees.map(item => item.email).join(', ') : <em>(empty)</em>}<br />
            <strong>Location: </strong>{!item.location ? '' : item.location}<br />
            <strong>Description: </strong>{!item.description ? '' : item.description}<br />
          </Typography>
        </DialogContent>
        <DialogActions>
          {(item.start.dateTime && item.end.dateTime) ? <CalendarEditPopUp item={item} handleEdit={handleEdit} /> : <Button
              disabled
              size="small"
              variant="contained"
            >
              Edit
          </Button>}
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default CalendarShowSelected;
