import React, { useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { CustomerAccountContext } from '../contexts/CustomerAccountContext';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: "none",
    fontSize: ".95rem",
    width: '90%',
  },
  dialog: {
    // width: 300,
  }, 
  dialogContent: {
    width: 300,
    textAlign: '-webkit-center',    
  },
  tableOptions: {
    width: 'unset',

    '& .MuiTableCell-root': {
      padding: theme.spacing(0),
      border: 'none',
      overflowWrap: 'anywhere',
    },
    '& p': {
      margin: theme.spacing(0),
    },
  },
  marginZero: {
    margin: theme.spacing(0),
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function PaymentOptions() {
  const classes = useStyles();
  const { state,
    setState,
    customerID,
    editPaymentByInvoice,
    editMonthlyInvoice,
    editDDP
  } = useContext(CustomerAccountContext);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  

  const handleSwitch = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={handleClickOpen}
      >
        Options de{<br />}paiement
      </Button>
      <Dialog
        className={classes.dialog}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Options de paiement
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <Table className={classes.tableOptions}>
            <colgroup span="1" style={{ width: "70%" }} />
            <colgroup span="1" style={{ width: "30%" }} />
            <TableBody>
                <TableRow>
                    <TableCell>
                        <p className={classes.marginZero}>Paiement par facture</p>
                    </TableCell>
                    <TableCell>
                        <Switch
                            disabled={customerID === -1}
                            checked={state.checked1}
                            onChange={() => editPaymentByInvoice(customerID)}
                            color="primary"
                            size="small"
                            name="checked1"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <p className={classes.marginZero}>Facture mensuelle</p>
                    </TableCell>
                    <TableCell>
                        <Switch
                            disabled={customerID === -1}
                            checked={state.checked2}
                            onChange={() => editMonthlyInvoice(customerID)}
                            color="primary"
                            size="small"
                            name="checked2"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <p className={classes.marginZero} style={{ color: 'gray' }}>Blocage automatique</p>
                    </TableCell>
                    <TableCell>
                        <Switch
                            disabled
                            checked={state.checked3}
                            onChange={handleSwitch}
                            color="primary"
                            size="small"
                            name="checked3"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <p className={classes.marginZero}>DDP</p>
                    </TableCell>
                    <TableCell>
                        <Switch
                            disabled={customerID === -1}
                            checked={state.checked4}
                            onChange={() => editDDP(customerID)}
                            color="primary"
                            size="small"
                            name="checked4"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </TableCell>
                </TableRow>
              </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            DONE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PaymentOptions;
