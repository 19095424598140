import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import GenerateInvoiceForm from './GenerateInvoiceForm';
// import TextField from '@material-ui/core/TextField';
// import Grid from '@material-ui/core/Grid';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  invoiceIcon: {
    color: '#0000008a',
  },
  dialogContent: {
    marginTop: theme.spacing(0),
    padding: '0px !important',
  },
  addNewLine: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1.5),
    width: '100%',
    // width: 'calc(100px-64px)',
  },
  // grid22: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   flexWrap: 'nowrap',
  //   alignItems: 'center',
  // },
  divButton: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function AddInvoiceIcon(props) {
  return (
    <SvgIcon {...props} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#000000" stroke="none">
          <path d="M1020 3555 c0 -901 -3 -1245 -11 -1245 -6 0 -48 -17 -94 -39 -651
          -305 -797 -1157 -283 -1657 135 -132 285 -216 473 -267 l80 -22 1808 -2 1807
          -3 0 2240 0 2240 -1890 0 -1890 0 0 -1245z m3520 -995 l0 -1980 -1256 0 -1256
          0 60 65 c120 127 203 279 249 453 22 84 26 118 26 247 0 129 -4 163 -26 247
          -50 187 -136 339 -271 474 -184 184 -410 284 -677 300 l-109 7 0 1084 0 1083
          1630 0 1630 0 0 -1980z m-2965 -484 c120 -40 219 -101 305 -186 386 -381 272
          -1018 -222 -1244 -148 -68 -333 -85 -497 -46 -256 62 -471 265 -553 524 -29
          91 -36 281 -13 382 54 248 230 456 466 551 104 42 173 53 309 49 103 -3 139
          -8 205 -30z"/>
          <path d="M3358 4234 l-3 -86 -55 -17 c-91 -29 -156 -68 -230 -137 -206 -195
          -210 -484 -10 -684 69 -69 148 -116 243 -146 l57 -18 0 -248 c0 -136 -2 -248
          -5 -248 -17 0 -87 44 -115 72 -36 36 -69 101 -70 136 0 22 -2 22 -131 22
          l-131 0 7 -52 c28 -202 182 -371 396 -434 l49 -15 0 -84 0 -85 130 0 129 0 3
          86 3 85 65 22 c226 76 370 261 370 476 0 217 -142 401 -370 478 l-65 22 -3
          250 -2 250 29 -10 c68 -24 139 -108 156 -186 l7 -33 125 0 126 0 -6 60 c-13
          146 -137 311 -289 383 -40 20 -90 39 -110 42 l-38 7 0 89 0 89 -130 0 -129 0
          -3 -86z m2 -584 c0 -190 -2 -230 -14 -230 -28 0 -112 68 -140 113 -58 93 -44
          197 37 274 38 37 90 72 110 73 4 0 7 -103 7 -230z m310 -561 c181 -108 181
          -310 0 -418 -19 -11 -38 -21 -42 -21 -5 0 -8 104 -8 230 0 127 3 230 8 230 4
          0 23 -10 42 -21z"/>
          <path d="M2780 1665 l0 -125 720 0 720 0 0 125 0 125 -720 0 -720 0 0 -125z"/>
          <path d="M2787 1213 c-4 -3 -7 -62 -7 -130 l0 -123 720 0 720 0 0 130 0 130
          -713 0 c-393 0 -717 -3 -720 -7z"/>
          <path d="M1218 1653 l-3 -178 -177 -3 -178 -2 0 -125 0 -125 178 -2 177 -3 3
          -177 2 -178 125 0 125 0 2 178 3 177 178 3 177 2 0 125 0 125 -180 0 -180 0 0
          180 0 180 -125 0 -125 0 -2 -177z"/>
      </g>
    </SvgIcon>
  );
}

export default function GenerateInvoice() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [arr, setArr] = useState([1]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddNewLine = params => {
    return setArr([...arr, params]);
  };

  return (
    <div>
      <Tooltip title="Generate Invoice" placement="top">
        <IconButton onClick={handleClickOpen}>
          <AddInvoiceIcon className={classes.invoiceIcon} />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add Line To Invoice
        </DialogTitle>
        <DialogContent className={classes.dialogContent} style={{ paddingTop: '0px !important'}} dividers>
          {arr.map((_) => <GenerateInvoiceForm place={_} key={_} />)}
          <div className={classes.divButton}>
            <Button 
              color="primary" 
              variant="outlined" 
              className={classes.addNewLine}
              onClick={() => handleAddNewLine(arr.length + 1)}
            >
              Add New Line
            </Button>
          </div>
          {/* <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6} className={classes.grid22}>
              <TextField
                className={classes.widthH2}
                label="Discount Amount"
                type="text"
              />
            </Grid>
            <Grid item xs={6} />
          </Grid> */}
        </DialogContent>
        <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary" variant="contained">
          Add New Invoice
        </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
