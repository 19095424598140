import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { editCommentTaskAction, setOpenAlertTasks } from '../actions';
import { convertHoursAndMinutes,
  convertDateToWithDash,
  convertDateToTimestamp,
} from '../common/functions';


const useStyles = makeStyles((theme) => ({
  snoozer: {
    display: 'flex',
    alignItems: 'center',
  },
  pickTimeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  pickTimeTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 256,
  },
  taskStatus1: {
    background: "#4a5dd6",
    color: "#fff",

    '&:hover': {
      color: '#4a5dd6',
    },
  },
}));

function SnoozeAll(props) {
  const classes = useStyles();
  const { alertTasks,
    editCommentTaskAction,
    setOpenAlertTasks,
  } = props;

    
  const nowDateAndTime = new Date();
  const nowGetTime = nowDateAndTime.getTime();
  const addThirtyMins = nowGetTime + 1800000;
  const defaultSnoozeTime = new Date(addThirtyMins);
  const [snoozeTime, setSnoozeTime] = useState(`${convertDateToWithDash(defaultSnoozeTime)}T${convertHoursAndMinutes(defaultSnoozeTime)}:00`);

  const handleSnoozeTime = event => {
    setSnoozeTime(event.target.value);
  }

  const handleSnooze = () => {
    const alertIds = alertTasks.map(item => item.id);
    alertIds.map(id => editCommentTaskAction(id, { snooze: convertDateToTimestamp(snoozeTime) }));
    setOpenAlertTasks(false);
  }

  return (
    <div className={classes.snoozer}>
      <form className={classes.pickTimeContainer} noValidate>
        <TextField
          id="time"
          type="datetime-local"
          className={classes.pickTimeTextField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 900, // 15 min
          }}
          value={snoozeTime}
          onChange={handleSnoozeTime}
          />
      </form>
      <Button
        className={classes.taskStatus1}
        size="small"
        onClick={handleSnooze}
      >
        Snooze All
      </Button>
    </div>
  );
}

const mapStateToProps = state => {
  return { alertTasks: state.alertTasks };
};

export default connect(mapStateToProps, {
  editCommentTaskAction,
  setOpenAlertTasks,
})(SnoozeAll);
