import { INVOICE_BY_SHIP_ID } from '../actions/types';


const invoiceByShipId = (state = {}, action) => {
  switch(action.type) {
    case INVOICE_BY_SHIP_ID:
      return action.payload;
    default:
      return state;
  }
};


export default invoiceByShipId;
