import { REFRESH_GENERAL } from '../actions/types';

// General refresh
const refresh = (state = 0, action) => {
    switch (action.type) {
        case REFRESH_GENERAL:
            return action.payload;
        default:
            return state;
    }
};

export default refresh;
