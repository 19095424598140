import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { green, yellow } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GetAppIcon from '@material-ui/icons/GetApp';
import SvgIcon from '@material-ui/core/SvgIcon';
import ReceiptIcon from '@material-ui/icons/Receipt';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';


const useStyles = makeStyles((theme) => ({
    root: {
        background: '#F7F7F7',
        marginTop: theme.spacing(1),
    },
    borderGrid: {
        border: '1px solid #e5d2d2',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    borderGridEnvois: {
        border: '1px solid #e5d2d2',
        paddingLeft: theme.spacing(.5),
        paddingRight: theme.spacing(.5),
        '& .MuiButton-text': {
            overflowWrap: 'anywhere',
        },
        textAlign: 'center',
    },
    sameLine1: {
        display: 'flex',
        marginTop: theme.spacing(1),
        '& .MuiSvgIcon-root:hover': {
            border: '1px solid blue',
        },
    },
    tableCellPaddingZero: {
        '& .MuiTableCell-root': {
            padding: theme.spacing(0),
            border: 'none',
        },
    },
    enterAmount: {
        '& > *': {
          margin: theme.spacing(1),
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 10px) scale(1)',
            fontSize: '1em',
            width: '90%',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
        },
    },
    optionRadio: {
        textAlign: 'center',
        '& .MuiButtonBase-root': {
            marginBottom: theme.spacing(1),
        },
    },
    typeRadio: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            padding: theme.spacing(0),
            border: 'none',
        },
    },
    typeLabel: {
        marginTop: theme.spacing(0),
    },
    taskContent: {
        backgroundColor: '#ffe0b2',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    taskParagraph: {
        marginTop: theme.spacing(0),
    },
    colorGray: {
        color: 'gray',
    },
    paragraphMarginZeroe: {
        margin: theme.spacing(0),
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    backgroundGreen: {
        background: '#79ef79',
    },
    tablePareto: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
        },
    },
    tableCA: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
            padding: theme.spacing(0.25),
        },
        marginTop: theme.spacing(6),
    },
    tableOptions: {
        '& .MuiTableCell-root': {
            padding: theme.spacing(0),
            border: 'none',
            overflowWrap: 'anywhere',
        },
        '& p': {
            margin: theme.spacing(0),
        },
    },
    tableRabais: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
            padding: theme.spacing(0.5),
        },
        marginBottom: theme.spacing(3),
    },
    tableChiffres: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
        },
        marginTop: theme.spacing(6),
    },
    tableTypeDenvois: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
        },
        marginTop: theme.spacing(6),
    },
    tableTopPays: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
        },
        marginTop: theme.spacing(4),
    },
    tableUtilisation: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
        },
        marginTop: theme.spacing(4),
    },
    tableValeurMoyenne: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
        },
        marginTop: theme.spacing(4),
    },
    borderNone: {
        border: 'none !important',
    },
    leftBorderNone: {
        borderLeft: 'none !important',
        borderBottom: 'none !important',
        display: 'flex',
        color: '#514f4f',
    },
    rightBorderNone: {
        borderRight: 'none !important',
    },
    alignLeft: {
        textAlign: 'left !important',
    },
    btnRabais: {
        color: 'gray',
        padding: theme.spacing(0.25),
        paddingBottom: theme.spacing(0),
    },
    btnRabaisEdit: {
        '&:hover': {
            color: 'gold',
        },
    },
    btnRabaisDelete: {
        '&:hover': {
            color: 'red',
        },
    },
    btnRabaisAdd: {
        '&:hover': {
            color: 'green',
        },
    },
    fontBigger: {
        fontSize: '1.2em',
    },
    fontBiggerEnvois: {
        fontSize: '1.2em',
        margin: theme.spacing(.75),
    },
    fontBiggerResumeDeLenvoi: {
        fontSize: '1.2em',
        textAlign: 'center',
        '& .MuiSvgIcon-root': {
            verticalAlign: 'bottom',
            '&:hover': {
                border: '1px solid blue',
            },
        },
    },
    fontBiggerReda: {
        fontSize: '1.2em',
    },
    fontSmallerEnvois: {
        fontSize: '.8em',
    },
    fontSmallerReda: {
        fontSize: '.8em',
    },
    tableEnvoisLivres: {
        '& .MuiTableCell-root': {
            padding: theme.spacing(0),
            paddingBottom: theme.spacing(1),
            border: 'none',
            textAlign: 'center',
            '& .MuiSvgIcon-root': {
                marginRight: theme.spacing(1),
            },
        },
        '& h3': {
            margin: theme.spacing(.5),
        },
        '& .MuiButton-root': {
            background: '#63ede0', // 9aede6
        },
    },
    borderGridResumeDeLenvoi: {
        border: '1px solid #e5d2d2',
        paddingLeft: theme.spacing(.5),
        paddingRight: theme.spacing(.5),
        overflowWrap: 'anywhere',
        '& .MuiGrid-container': {
            marginTop: theme.spacing(2),
        },
        '& .MuiGrid-root': {
            textAlign: 'center',
        },
    },
    borderGridReda: {
        border: '1px solid #e5d2d2',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1),
        '& h3': {
            marginLeft: theme.spacing(1.5),
            display: 'flex',
        },
        '& p': {
            margin: theme.spacing(1.75),
        },
        '& .MuiSvgIcon-root': {
            '&:hover': {
                color: 'green',
            },
        },
    },
    borderGridCasLitigieux: {
        border: '1px solid #e5d2d2',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1),
        fontSize: '1.2em',
        '& h3': {
            marginLeft: theme.spacing(2),
        },
        '& p': {
            marginLeft: theme.spacing(2),
        },
    },
    verticalCenterIcon: {
        verticalAlign: 'super',
        '&:hover': {
            border: '1px solid blue',
        },
    },
    marginLeftContent: {
        '& p': {
            marginLeft: theme.spacing(2),
        },
    },
    backgroundRed: {
        background: 'red',
    },
}));

const GreenRadio = withStyles({
    root: {
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const YellowRadio = withStyles({
    root: {
      '&$checked': {
        color: yellow[700],
      },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

function PackageIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z" />
      </SvgIcon>
    );
}

export default function CustomerAccountBodyDemo() {
    const classes = useStyles();
    // const [selectedRadioValue, setSelectedRadioValue] = useState('a');
    const [selectedRadioType, setSelectedRadioType] = useState('gold');
    const [inputBaseAmount, setInputBaseAmount] = useState('');
    const [state, setState] = useState({
        checked1: true,
        checked2: false,
        checked3: true,
        checked4: false
    });

    const handleSwitch = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    // const handleRadio = (event) => {
    //     setSelectedRadioValue(event.target.value);
    // };
    
    const handleRadioType = (event) => {
        setSelectedRadioType(event.target.value);
    };
    
    return (<div className={classes.root}>
        <Grid container spacing={1}>
            <Grid item xs={12} md={5} container>
                <Grid item xs={5} className={classes.borderGrid}>
                    <span className={classes.sameLine1}><b><u>Informations adresses</u></b>&nbsp;<ExitToAppIcon /></span>
                    <p><b>Adresse:</b> Avenue du Pangolin Joyeux 32 1008 Lausanne Suisse</p>
                    <p><b>Adresse de facturation:</b> Avenue du Putois Joyeux 32 1008 Lausanne Suisse</p>

                    <Divider />

                    <p><b><u>Options de paiement</u></b></p>
                    <Table className={classes.tableOptions}>
                        <colgroup span="1" style={{ width: "70%" }} />
                        <colgroup span="1" style={{ width: "30%" }} />
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <p className={classes.paragraphMarginZero}>Paiement par facture</p>
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={state.checked1}
                                        onChange={handleSwitch}
                                        color="primary"
                                        size="small"
                                        name="checked1"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <p className={classes.paragraphMarginZero}>Facture mensuelle</p>
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={state.checked2}
                                        onChange={handleSwitch}
                                        color="primary"
                                        size="small"
                                        name="checked2"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <p className={classes.paragraphMarginZero}>Blocage automatique</p>
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={state.checked3}
                                        onChange={handleSwitch}
                                        color="primary"
                                        size="small"
                                        name="checked3"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <p className={classes.paragraphMarginZero}>DDP</p>
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={state.checked4}
                                        onChange={handleSwitch}
                                        color="primary"
                                        size="small"
                                        name="checked4"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <form className={classes.enterAmount} noValidate autoComplete="off">
                                        <TextField
                                            error={isNaN(inputBaseAmount) ? true : false}
                                            id="outlined-basic2"
                                            label={isNaN(inputBaseAmount) ? "Please enter numbers only" : "Entrer un montant max (1’000 CHF) de base"}
                                            variant="outlined"
                                            value={inputBaseAmount}
                                            onChange={(e) => {
                                                setInputBaseAmount(e.target.value);
                                                Number(e.target.value) > 1000 && setState({ ...state, checked1: false });
                                            }}
                                            onKeyPress={(event) => {
                                                if(event.key === 'Enter') {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </form>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} className={classes.optionRadio}>
                                    {Number(inputBaseAmount) > 1000 ?
                                    <Radio
                                        // checked={selectedRadioValue === 'b'}
                                        checked
                                        // onChange={handleRadio}
                                        // value="b"
                                        // name="radio-button"
                                        // inputProps={{ 'aria-label': 'B' }}
                                    /> :
                                    <GreenRadio
                                    // checked={selectedRadioValue === 'a'}
                                    checked
                                    // onChange={handleRadio}
                                    // value="a"
                                    // name="radio-button"
                                    // inputProps={{ 'aria-label': 'A' }}
                                    />}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table size="small" className={classes.tableRabais}>
                        <colgroup span="1" style={{ width: "54%" }} />
                        <colgroup span="1" style={{ width: "30%" }} />
                        <colgroup span="1" style={{ width: "8%" }} />
                        <colgroup span="1" style={{ width: "8%" }} />
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.rightBorderNone} colSpan={3}><b>Rabais (%)</b></TableCell>
                                <TableCell className={classes.leftBorderNone}><AddCircleOutlineIcon className={classes.btnRabaisAdd} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.alignLeft}>UPS</TableCell>
                                <TableCell>33</TableCell>
                                <TableCell><EditIcon size="small" className={`${classes.btnRabais} ${classes.btnRabaisEdit}`} /></TableCell>
                                <TableCell><DeleteIcon size="small" className={`${classes.btnRabais} ${classes.btnRabaisDelete}`} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.alignLeft}>FedEx</TableCell>
                                <TableCell>32</TableCell>
                                <TableCell><EditIcon size="small" className={`${classes.btnRabais} ${classes.btnRabaisEdit}`} /></TableCell>
                                <TableCell><DeleteIcon size="small" className={`${classes.btnRabais} ${classes.btnRabaisDelete}`} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.alignLeft}>TNT</TableCell>
                                <TableCell>13</TableCell>
                                <TableCell><EditIcon size="small" className={`${classes.btnRabais} ${classes.btnRabaisEdit}`} /></TableCell>
                                <TableCell><DeleteIcon size="small" className={`${classes.btnRabais} ${classes.btnRabaisDelete}`} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.alignLeft}>Aramex</TableCell>
                                <TableCell>12</TableCell>
                                <TableCell><EditIcon size="small" className={`${classes.btnRabais} ${classes.btnRabaisEdit}`} /></TableCell>
                                <TableCell><DeleteIcon size="small" className={`${classes.btnRabais} ${classes.btnRabaisDelete}`} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.alignLeft}>Post Int</TableCell>
                                <TableCell>14</TableCell>
                                <TableCell><EditIcon size="small" className={`${classes.btnRabais} ${classes.btnRabaisEdit}`} /></TableCell>
                                <TableCell><DeleteIcon size="small" className={`${classes.btnRabais} ${classes.btnRabaisDelete}`} /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Divider />

                    <p><b><u>Type de client</u></b></p>
                    <Table>
                        <colgroup span="1" style={{ width: "33%" }} />
                        <colgroup span="1" style={{ width: "33%" }} />
                        <colgroup span="1" style={{ width: "33%" }} />
                        <TableBody className={classes.typeRadio}>
                            <TableRow>
                                <TableCell>
                                    <Radio
                                        checked={selectedRadioType === 'normal'}
                                        onChange={handleRadioType}
                                        value="normal"
                                        color="primary"
                                        name="radio-button"
                                        inputProps={{ 'aria-label': 'Normal' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Radio
                                        checked={selectedRadioType === 'premium'}
                                        onChange={handleRadioType}
                                        value="premium"
                                        color="secondary"
                                        name="radio-button"
                                        inputProps={{ 'aria-label': 'Premium' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <YellowRadio
                                        checked={selectedRadioType === 'gold'}
                                        onChange={handleRadioType}
                                        value="gold"
                                        name="radio-button"
                                        inputProps={{ 'aria-label': 'Gold' }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <p className={classes.typeLabel}>Normal</p>
                                </TableCell>
                                <TableCell>
                                    <p className={classes.typeLabel}>Premium</p>
                                </TableCell>
                                <TableCell>
                                    <p className={classes.typeLabel}>Gold</p>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Divider />

                    <p><b><u>Tâches (voir tracking)</u></b></p>

                    <p><b>1ZY23R5854848</b><br />
                    Tâches à faire (2)<br />Tâches en cours (0)</p>
                    <div className={classes.taskContent}>
                        <p className={classes.taskParagraph}>Renvoyer la facture au client<br />
                        <span className={classes.colorGray}>(3 janvier 2021, 08:56)</span></p>
                        <p>Attribué à : <b>Jehona</b><br />
                        Dernière modification à: <b>16:28</b><br />
                        <b>(Léonard)</b></p>
                    </div>
                    <div className={classes.taskContent}>
                        <p className={classes.taskParagraph}>Demander le numéro de téléphone<br />
                        <span className={classes.colorGray}>(3 janvier 2021, 08:56)</span></p>
                        <p>Attribué à : <b>Jehona</b><br />
                        Dernière modification à: <b>12:03</b><br />
                        <b>(Léonard)</b></p>
                    </div>
                    
                    <p><b>37475394734</b><br />
                    Tâches à faire (2)<br />Tâches en cours (0)</p>
                    <div className={classes.taskContent}>
                        <p className={classes.taskParagraph}>Renvoyer la facture au client<br />
                        <span className={classes.colorGray}>(3 janvier 2021, 08:56)</span></p>
                        <p>Attribué à : <b>Jehona</b><br />
                        Dernière modification à: <b>16:28</b><br />
                        <b>(Léonard)</b></p>
                    </div>
                    <div className={classes.taskContent}>
                        <p className={classes.taskParagraph}>Demander le numéro de téléphone<br />
                        <span className={classes.colorGray}>(3 janvier 2021, 08:56)</span></p>
                        <p>Attribué à : <b>Jehona</b><br />
                        Dernière modification à: <b>12:03</b><br />
                        <b>(Léonard)</b></p>
                    </div>
                </Grid>

                <Grid item xs={7} className={classes.borderGrid}>
                    <p className={classes.textAlignCenter}><b><u>Résumé du compte</u></b></p>

                    <Table size="small" className={classes.tablePareto}>
                        <colgroup span="1" style={{ width: "33%" }} />
                        <colgroup span="1" style={{ width: "33%" }} />
                        <colgroup span="1" style={{ width: "33%" }} />
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2}><b>Pareto</b> (top 20% du CA)</TableCell>
                                <TableCell className={classes.borderNone} />
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.backgroundGreen}>OUI</TableCell>
                                <TableCell>NON</TableCell>
                                <TableCell className={classes.borderNone} />
                            </TableRow>
                        </TableBody>
                    </Table>
                    
                    <Table size="small" className={classes.tableCA}>
                        <colgroup span="1" style={{ width: "32%" }} />
                        <colgroup span="1" style={{ width: "17%" }} />
                        <colgroup span="1" style={{ width: "17%" }} />
                        <colgroup span="1" style={{ width: "17%" }} />
                        <colgroup span="1" style={{ width: "17%" }} />
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={4}><b>CA/ MB (CHF)/Shipments</b></TableCell>
                                <TableCell><b>%</b></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Aujourd’hui</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>0</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Cette semaine</TableCell>
                                <TableCell>324.15</TableCell>
                                <TableCell>125.20</TableCell>
                                <TableCell>12</TableCell>
                                <TableCell>35</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ce mois</TableCell>
                                <TableCell>0562.30</TableCell>
                                <TableCell>254.10</TableCell>
                                <TableCell>52</TableCell>
                                <TableCell>38</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Cette année</TableCell>
                                <TableCell>1’276.80</TableCell>
                                <TableCell>650.80</TableCell>
                                <TableCell>120</TableCell>
                                <TableCell>43</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Début</TableCell>
                                <TableCell>12’384.30</TableCell>
                                <TableCell>719.50</TableCell>
                                <TableCell>145</TableCell>
                                <TableCell>42.5</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table size="small" className={classes.tableChiffres}>
                        <colgroup span="1" style={{ width: "75%" }} />
                        <colgroup span="1" style={{ width: "25%" }} />
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2}><b>Chiffres divers</b></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.alignLeft}>Taux d’assurances des colis (%)</TableCell>
                                <TableCell>8%</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.alignLeft}>Taux de colis remboursés (%)</TableCell>
                                <TableCell>2%</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.alignLeft}>Taux d’envois livrés en retard (%)</TableCell>
                                <TableCell>34%</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.alignLeft}>Nombre de surfacturations annuelles</TableCell>
                                <TableCell>21</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.alignLeft}>Nombre de factures en retard (+ 30j)</TableCell>
                                <TableCell>2</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.alignLeft}>Montant des factures en retard (+30j ,CHF)</TableCell>
                                <TableCell>658.35</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Grid container>
                        <Grid item xs={8} md={8}>
                            <Table size="small" className={classes.tableTypeDenvois}>
                                <colgroup span="1" style={{ width: "65%" }} />
                                <colgroup span="1" style={{ width: "35%" }} />
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={2}><b>Type d’envois (%)</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.alignLeft}>Colis</TableCell>
                                        <TableCell>77</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.alignLeft}>Documents</TableCell>
                                        <TableCell>23</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={4} md={4} />
                        <Grid item xs={9} md={7}>
                            <Table size="small" className={classes.tableTopPays}>
                                <colgroup span="1" style={{ width: "55%" }} />
                                <colgroup span="1" style={{ width: "45%" }} />
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={2}><b>Top 3 pays (%)</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>DE</TableCell>
                                        <TableCell>12</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>FR</TableCell>
                                        <TableCell>8</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>US</TableCell>
                                        <TableCell>7</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={3} md={5} />
                        <Grid item xs={10} md={9}>
                            <Table size="small" className={classes.tableUtilisation}>
                                <colgroup span="1" style={{ width: "55%" }} />
                                <colgroup span="1" style={{ width: "45%" }} />
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={2}><b>Utilisation prestaires (%)</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>UPS</TableCell>
                                        <TableCell>23</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>FedEx</TableCell>
                                        <TableCell>61</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>TNT</TableCell>
                                        <TableCell>13</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Aramex</TableCell>
                                        <TableCell>0</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Post Int</TableCell>
                                        <TableCell>0</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={2} md={3} />
                        <Grid item xs={11} md={10}>
                            <Table size="small" className={classes.tableValeurMoyenne}>
                                <colgroup span="1" style={{ width: "100%" }} />
                                <TableBody>
                                    <TableRow>
                                        <TableCell><b>Valeur moyenne des envois (CHF)</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>145.35</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={1} md={2} />
                    </Grid>

                </Grid>
            </Grid>
            
            <Grid item xs={12} md={7} container>
                <Grid item xs={2} className={classes.borderGridEnvois}>
                    <p className={classes.fontBigger}><b>Envois livrés</b></p>
                    <Divider />
                    <Button><b>1ZY23R5854848</b></Button>
                    <Button><b>1ZY23R5854848</b></Button>
                    <Divider />
                    <Button><b>1ZY23R5854848</b></Button>
                    <Button><b>1ZY23R5854848</b></Button>
                    <Button><b>1ZY23R5854848</b></Button>
                    <Button><b>1ZY23R5854848</b></Button>
                    <Button><b>1ZY23R5854848</b></Button>
                    <Divider />
                    <Button><b>1ZY23R5854848</b></Button>
                    <Button><b>1ZY23R5854848</b></Button>
                    <Button><b>1ZY23R5854848</b></Button>
                    <Button><b>1ZY23R5854848</b></Button>
                    <Button><b>1ZY23R5854848</b></Button>
                    <Button><b>1ZY23R5854848</b></Button>
                    <Button><b>1ZY23R5854848</b></Button>
                    <Divider />
                    <Button><b>1ZY23R5854848</b></Button>
                    <Button><b>1ZY23R5854848</b></Button>
                </Grid>

                <Grid item xs={3} className={classes.borderGridEnvois}>
                    <p className={classes.fontBiggerEnvois}><b>Envois en cours<br /><span className={classes.fontSmallerEnvois}>(date de livraison)</span></b></p>
                    <Divider />
                    <Table size="small" className={classes.tableEnvoisLivres}>
                        <colgroup span="1" style={{ width: "85%" }} />
                        <colgroup span="1" style={{ width: "15%" }} />
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2}><h3>Jeudi 17 avril</h3></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Button><b>1ZY23R5854848</b></Button></TableCell>
                                <TableCell><LocationOnIcon /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}><h3>Jeudi 12 avril</h3></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Button><b>1ZY23R5854848</b></Button></TableCell>
                                <TableCell><LocationOnIcon /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Button><b>1ZY23R5854848</b></Button></TableCell>
                                <TableCell><LocationOnIcon /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}><h3>Jeudi 9 avril</h3></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Button><b>1ZY23R5854848</b></Button></TableCell>
                                <TableCell><LocationOnIcon /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={7} className={classes.borderGridResumeDeLenvoi}>
                    <p className={classes.fontBiggerResumeDeLenvoi}><b>Résumé de l’envoi</b> <GetAppIcon /> Inv. N°: 0000 0000 0152</p>
                    <Divider />
                    <Grid container>
                        <Grid item xs={3}><PackageIcon fontSize="large" /></Grid>
                        <Grid item xs={3}><ReceiptIcon fontSize="large" /><GetAppOutlinedIcon className={classes.verticalCenterIcon} /></Grid>
                        <Grid item xs={3}><WorkOutlineIcon fontSize="large" /><GetAppOutlinedIcon className={classes.verticalCenterIcon} /></Grid>
                        <Grid item xs={3}><MarkunreadMailboxIcon fontSize="large" /><GetAppOutlinedIcon className={classes.verticalCenterIcon} /></Grid>
                        <Grid item xs={3}>Contenu</Grid>
                        <Grid item xs={3}>Facture</Grid>
                        <Grid item xs={3}>Proforma/AWB</Grid>
                        <Grid item xs={3}>Preuve de livraison</Grid>
                    </Grid>
                    <div className={classes.marginLeftContent}>
                        <p><b>Date pickup prévue :</b> 22 janvier 2021 14:00 17:00<br /><b>Date pickup effective:</b> : 23 janvier 2021 16:04<br/><b>Numéro de pickup:</b> : 234RTZ45</p>
                        <p><b>Adresse d’expédition:</b><br />Abouchar SA<br />M. Christophe Compondu<br />chemin du Centenaire 22<br />1204 Genève (CH)<br />+41793213454<br /><a href="mailto:">c.compondu@abouchar.com</a></p>
                        <p><b>Adresse de destination:</b><br />Vituper Gmbh<br />Mme Simona Alep<br />Drestenstrasse 19<br />233345 München (DE)<br />+498564563463<br /><a href="mailto:">salep@vituper.com</a></p>
                        <p><b>Date de livraison estimée:</b> : 15 avril 2021<br /><b>Date de livraison effective:</b> (elle se met quand l’envoi est livré (et l’heure)</p>
                        <p><b>Descriptions:</b> 2 pairs de chaussures<br /><b>DDP /</b> DAP</p>
                        <p><b>Prix de l’envoi:</b> 1’350 CHF<br /><b>Valeur de la marchandise:</b> 100 CHF<br /><b>Assurance:</b> Oui / <span className={classes.backgroundRed}>Non</span></p>
                        <p><b>Dimensions:</b> 25 cm(Lo) X 19 cm (La) X 3cm (h) 1.2kg</p>
                    </div>
                </Grid>
                <Grid item xs={12} className={classes.borderGridReda}>
                    <h3>Commentaires:&nbsp;<AddCircleOutlineIcon /></h3>
                    <p className={classes.fontBiggerReda}><span className={classes.fontSmallerReda}>Reda: ( 23 janvier 2021 12:33)</span><br />Le nous a demandé de contacter plutôt pour la facturation madame Ploncard.</p>
                    <p className={classes.fontBiggerReda}><span className={classes.fontSmallerReda}>Guillaume: ( 10 janvier 2021 17:35)</span><br />Le client ne souhaite plus que l’on informe la destinataire des colis en retard.</p>
                </Grid>
                <Grid item xs={12} className={classes.borderGridCasLitigieux}>
                    <h3>Cas litigieux</h3>
                    <p>1ZY23R5854848<br />Pertes ou dégâts<br />Retards</p>
                </Grid>

            </Grid>
        </Grid>
    </div>);
}
