import { CONTACT_REQUEST_REFRESH } from '../actions/types';

const crRefresh = (state = 0, action) => {
    switch (action.type) {
        case CONTACT_REQUEST_REFRESH:
            return action.payload;
        default:
            return state;
    }
};

export default crRefresh;
