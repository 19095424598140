import { INVOICES_BILLING } from '../actions/types';


const initialValues = {
  overcharging: false,
  tax: false,
};

const invoicesBilling = (state = initialValues, action) => {
  switch(action.type) {
    case INVOICES_BILLING:
      return action.payload;
    default:
      return state;
  }
};


export default invoicesBilling;
