import SvgIcon from '@material-ui/core/SvgIcon';


export function FontSizeDecrease(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M5.12,14L7.5,7.67L9.87,14M6.5,5L1,19H3.25L4.37,16H10.62L11.75,19H14L8.5,5H6.5M18,17L23,11.93L21.59,10.5L19,13.1V7H17V13.1L14.41,10.5L13,11.93L18,17Z" />
    </SvgIcon>
  );
}

export function FontSizeIncrease(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M5.12,14L7.5,7.67L9.87,14M6.5,5L1,19H3.25L4.37,16H10.62L11.75,19H14L8.5,5H6.5M18,7L13,12.07L14.41,13.5L17,10.9V17H19V10.9L21.59,13.5L23,12.07L18,7Z" />
    </SvgIcon>
  );
}

export function AlphabetE(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M9,7H15V9H11V11H15V13H11V15H15V17H9V7M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M5,5V19H19V5H5Z" />
    </SvgIcon>
  );
}

export function Pallet(props) {
  return (
    <SvgIcon {...props} x="0px" y="0px" viewBox="0 0 100 100">
      <path fill="currentColor" d="M92.5,45c0-0.912-0.567-1.828-1.382-2.236l-40-20c-0.703-0.353-1.533-0.353-2.236,0l-40,20C8.066,43.172,7.5,44.089,7.5,45  v12.5c0,0.947,0.535,1.813,1.382,2.236l10,5c0.095,0.048,0.196,0.072,0.295,0.107c0.066,0.023,0.128,0.053,0.196,0.071  C19.579,64.967,19.789,65,20,65c0.211,0,0.422-0.033,0.628-0.087c0.066-0.017,0.128-0.047,0.193-0.07  c0.099-0.035,0.201-0.06,0.297-0.107L32.5,59.045l5,2.5V72.5c0,0.947,0.535,1.813,1.382,2.236l10,5c0.69,0.346,1.547,0.346,2.236,0  l40-20c0.847-0.423,1.382-1.289,1.382-2.236V45z M50,27.795L84.41,45L80,47.205L45.59,30L50,27.795z M40,32.795L74.41,50L70,52.205  L35.59,35L40,32.795z M30,37.795L64.41,55L60,57.205L25.59,40L30,37.795z M17.5,58.455l-5-2.5v-6.91l5,2.5V58.455z M22.5,58.455  v-4.41l4.41,2.205L22.5,58.455z M47.5,73.455l-5-2.5v-6.91l5,2.5V73.455z M50,62.205l-8.882-4.441l-7.5-3.75l-12.5-6.25L15.59,45  L20,42.795L54.41,60L50,62.205z M87.5,55.955l-35,17.5v-6.91l35-17.5V55.955z" />
    </SvgIcon>
  );
}
