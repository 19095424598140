import { INVOICES_LOADING } from '../actions/types';


const invoicesLoading = (state = false, action) => {
  switch(action.type) {
    case INVOICES_LOADING:
      return action.payload;
    default:
      return state;
  }
};


export default invoicesLoading;
