import { GOOGLE_EVENT_EMAIL_ERROR } from '../actions/types';

const dashboardEventEmailError = (state = false, action) => {
    switch (action.type) {
        case GOOGLE_EVENT_EMAIL_ERROR:
            return action.payload;
        default:
            return state;
    }
};

export default dashboardEventEmailError;
