import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { createOverbillingKeys } from './Overcharging';


const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  dropDownMenu: {
    width: 300,
  },
  description: {
    width: 400,
  },
  fontItalic: {
    fontStyle: 'italic',
  },
  removeIcon: {
    color: '#909090',
  },
}));

function ItemForm(props) {
  const classes = useStyles();
  const { orderNumber,
    index,
    selectOptions,
    handleChangeCreate,
    selectValue,
    invoiceShipIdLoading,
    handleRemove,
    inputValues,
  } = props;

  return (    
    <form className={classes.form} noValidate autoComplete="off">
      <Autocomplete
        className={classes.dropDownMenu}
        size="small"
        autoHighlight
        options={selectOptions.length ? selectOptions : []}
        value={selectOptions.length ? selectOptions.filter(flt => flt[1] === selectValue)[0] : 1}
        getOptionLabel={option => {
          if(option[0]) return option[0].toString();
          return '';
        }}
        onChange={(e, v, r) => {
          if((r === 'select-option') && v) {
            let evtn = {
              target: {
                name: createOverbillingKeys[0],
                value: v[1].toString(),
              }
            };
  
            return handleChangeCreate(orderNumber)(evtn);
        } else if (r === 'clear') {
          let evtn = {
            target: {
              name: createOverbillingKeys[0],
              value: 1,
            }
          };

          return handleChangeCreate(orderNumber)(evtn);
        }
        }}
        disableClearable
        renderInput={(params) => <TextField
          {...params}
          label={invoiceShipIdLoading ? 'Loading...' : 'Rechercher…'}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {invoiceShipIdLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />}
      />

      <TextField
        size="small"
        className={classes.description}
        variant="outlined"
        label={`Description pour client${(index > 0) ? ` (${index + 1})` : ''}`}
        onChange={handleChangeCreate(orderNumber)}
        name={createOverbillingKeys[1]}
        multiline
        rows={4}
      />

      <TextField
        size="small"
        variant="outlined"
        type="number"
        label={`Prix de vente${(index > 0) ? ` (${index + 1})` : ''}`}
        value={inputValues[createOverbillingKeys[2]]}
        onChange={handleChangeCreate(orderNumber)}
        name={createOverbillingKeys[2]}
      />

      <TextField
        size="small"
        variant="outlined"
        type="number"
        label={`Prix d’achat${(index > 0) ? ` (${index + 1})` : ''}`}
        value={inputValues[createOverbillingKeys[3]]}
        onChange={handleChangeCreate(orderNumber)}
        name={createOverbillingKeys[3]}
      />

      {(index > 0) ? <IconButton onClick={handleRemove(orderNumber)}>
        <RemoveCircleIcon className={classes.removeIcon} />
      </IconButton> : null}
    </form>
  );
}

const mapStateToProps = state => {
  return { invoiceShipIdLoading: state.invoiceShipIdLoading };
};

export default connect(mapStateToProps)(ItemForm);
