import { CONTACT_REQUEST_TYPES } from '../actions/types';

const crTypes = (state = [], action) => {
    switch (action.type) {
        case CONTACT_REQUEST_TYPES:
            return action.payload;
        default:
            return state;
    }
};

export default crTypes;
