import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useToken from '../login/useToken';
import { setInvoicesMain, getInvoices } from '../actions';


const useStyles = makeStyles((theme) => ({
  gridMain: {
    '& > div': {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      height: 'auto',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),

    },
    
    '& div.gridTwo': {
      paddingBottom: theme.spacing(1),        
      justifyContent: 'flex-end',
    },
  },
  gridOne: {
    paddingBottom: theme.spacing(1),
    
    '& .MuiTypography-root': {
      color: 'gray',
    },
  },
  gridTwo: {
    // paddingBottom: theme.spacing(1),
  },
  gridThree: {
    
  },
  gridFour: {
    
  },
  searchForm: {
    width: 400,
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  search: {
    width: '100%',
    "& .MuiIconButton-root": {
      padding: 0,
      color: "gray",
    },
  },
  groupDatePicker: {
    display: 'flex',
    gap: theme.spacing(2),

    '& button': {
      alignSelf: 'flex-end',
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
  },
  textFieldDatePicker: {
    width: 200,
  },
  groupButtons: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}));

function InvoicesHeader(props) {
  const classes = useStyles();
  const { token } = useToken();
  const { invoicesMain,
    setInvoicesMain,
    getInvoices,
    invoicesLoading,
  } = props; 

  const handleFilters = props => () => {
    return setInvoicesMain({ ...invoicesMain, [props]: !invoicesMain[props] });
  };

  const handleControled = value => () => {
    setInvoicesMain({ ...invoicesMain, controled: value });
    handleGetData();
  };

  const handleString = props => event => {
    setInvoicesMain({ ...invoicesMain, [props]: event.target.value });
  };
  
  const handleGetData = () => {
    getInvoices(token);
  }

  const handleClearSearch = () => {
    setInvoicesMain({ ...invoicesMain, searchTerm: '' });
  }

  const disableButton = true;

  return(
    <div>
      <Grid container className={classes.gridMain}>
        <Grid item xs={5} md={5} lg={5} className={classes.gridOne}>
          <Typography variant="h4">Factures Expedismart</Typography>
        </Grid>

        <Grid item xs={7} md={7} lg={7} className="gridTwo">
          <form noValidate autoComplete="off" className={classes.searchForm}>
            <TextField 
              size="small" 
              id="outlined-basic" 
              label="N°de tracking, nom, n°facture, montant facturé" 
              variant="outlined" 
              className={classes.search}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  getInvoices(token);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                  {(invoicesMain.searchTerm.length > 0) ? (<IconButton onClick={handleClearSearch}>
                    <HighlightOffIcon size="small" />
                  </IconButton>) : ''}
                  </InputAdornment>
                ),
              }}
              value={invoicesMain.searchTerm}
              onChange={handleString('searchTerm')}
            />
          </form>          
        </Grid>


        <Grid item xs={5} md={5} lg={5} container className={classes.gridThree}>
          <div className={classes.groupButtons}>
            <Button
              disabled={invoicesLoading || (invoicesMain.searchTerm.length > 0)}
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={invoicesMain.controled ? "contained" : "outlined"}
              color={invoicesMain.controled ? "secondary" : "default"}
              onClick={handleControled(true)}
            >
              Déjà traitées
            </Button>
            <Button
              disabled={invoicesLoading || (invoicesMain.searchTerm.length > 0)}
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={!invoicesMain.controled ? "contained" : "outlined"}
              color={!invoicesMain.controled ? "secondary" : "default"}
              onClick={handleControled(false)}
            >
              Pas traitées
            </Button>
            <Button
              disabled={invoicesLoading || disableButton || (invoicesMain.searchTerm.length > 0)}
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={invoicesMain.modifing ? "contained" : "outlined"}
              color={invoicesMain.modifing ? "secondary" : "default"}
              onClick={handleFilters('modifing')}
            >
              surfacturation
            </Button>
          </div>
        </Grid>

        <Grid item xs={7} md={7} lg={7} className={classes.gridFour}>
          <Button
            disabled={invoicesLoading || disableButton || (invoicesMain.searchTerm.length > 0)}
            className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
            size="small"
            variant={invoicesMain.synchronization ? "contained" : "outlined"}
            color={invoicesMain.synchronization ? "secondary" : "default"}
            onClick={handleFilters('synchronization')}
          >
            Synchronisation
          </Button>
          
          <div className={classes.groupDatePicker}>
            <form noValidate>
              <TextField
                id="dateFrom"
                className={classes.textFieldDatePicker}
                label="Entre"
                type="date"
                value={invoicesMain.dateFrom}
                inputProps={{ max: invoicesMain.dateTo }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleString('dateFrom')}
              />
            </form>
            <form noValidate>
              <TextField
                id="dateTo"
                className={classes.textFieldDatePicker}
                label="et"
                type="date"
                value={invoicesMain.dateTo}
                inputProps={{ min: invoicesMain.dateFrom }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleString('dateTo')}
              />
            </form>
            <Button
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant="outlined"
              color="primary"
              disabled={!invoicesMain.dateFrom || !invoicesMain.dateTo || invoicesLoading || (invoicesMain.searchTerm.length > 0)}
              onClick={handleGetData}
            >
              Valider
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    invoicesMain: state.invoicesMain,
    invoicesLoading: state.invoicesLoading,
  };
};

export default connect(mapStateToProps, { setInvoicesMain, getInvoices })(InvoicesHeader);
