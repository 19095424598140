import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { getDateToday } from '../common/Frame';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
  invoiceIcon: {
      color: '#0000008a',
  },
  dialogContent: {
    // display: 'flex',
    // flexWrap: 'nowrap',
    // marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0),
    // paddingTop: '8px !important',
  },
  formControl: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(0),
    width: '100%',
    '& .MuiFormControl-root': {
      marginTop: theme.spacing(3),
    },
  },
  widthH: {
    width: '100%',
  },
  widthH2: {
    width: '90%',
  },
  grid22: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  backgroundDark: {
    background: '#ccc',
  },
}));

export default function GenerateInvoiceForm(props) {
  const { place } = props;
  const classes = useStyles();
  const [select, setSelect] = useState('');
  const [date, setDate] = useState(getDateToday());

  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  return (
    <form className={`${classes.formControl} ${place%2 === 0 ? classes.backgroundDark : ''}`}>      
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <FormControl required className={classes.widthH} style={{ marginTop: 0 }}>
            <InputLabel id="demo-simple-select-label">Items-{place}</InputLabel>
            <Select
              // className={classes.widthH}
              labelId="demo-simple-select-label"
              value={select}
              onChange={handleChange}
            >
              {/* <MenuItem value="">
                <em>None</em>
              </MenuItem> */}
              <MenuItem value="One">Itaque earum rerum</MenuItem>
              <MenuItem value="Two">Nam libero tempore</MenuItem>
              <MenuItem value="Three">Emporibus autem quibusdam</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.widthH}
            required
            label={`Description-${place}`}
            multiline
            rows={4}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} className={classes.grid2}>
          <TextField
            className={classes.widthH2}
            required
            label={`Total Amount-${place}`}
            type="text"
          />
        </Grid>
        <Grid item xs={6} className={classes.grid22}>
          <TextField
            className={classes.widthH2}
            required
            label={`Date-${place}`}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={date}
            onChange={e => setDate(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} className={classes.grid2}>
          <TextField
            className={classes.widthH2}
            label={`Ikompar Amount-${place}`}
            type="text"
          />
        </Grid>
        <Grid item xs={6} />
      </Grid> 
    </form>
  );
}
