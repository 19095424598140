import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { editIssue, fromIssuesToTrackings } from '../actions';
import useToken from '../login/useToken';
import { currentUserId } from '../common/functions';
import { logOut } from '../common/Frame';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    minHeight: '30vh',
  },
  dialogTitle: {
    marginTop: theme.spacing(1),
  },
  menuIcon: {
    color: theme.palette.grey[700],
    fontSize: '3.5em',
    cursor: 'pointer',
  },
  formControlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    textAlign: 'center',

    '& .MuiFormControlLabel-root': {
      marginBottom: theme.spacing(1),
    },
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function BurgerPopUp(props) {
  const classes = useStyles();
  const { token } = useToken();
  const { shipmentId,
    users,
    editIssue,
    fromIssuesToTrackings,
    deleteShipment,
    deleteStatusAndSendToArvhive
  } = props;
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [radioValue, setRadioValue] = useState('1');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  // Move back to Tracking/Archive page 
  const handleSave = () => {
    if(radioValue === '1') {
      deleteShipment('/hide/');
      deleteStatusAndSendToArvhive('Hide');
    } else if(radioValue === '2') {
      editIssue(shipmentId, 0, currentUserId(users), {});
      fromIssuesToTrackings(shipmentId, token);
    }

    handleClose();
  };

  const username = localStorage.getItem('username');
  if(!username) logOut();

  return (
    <div>
      <MenuIcon
        fontSize="large"
        onClick={handleClickOpen}
        className={classes.menuIcon}
      />
      <Dialog
        fullWidth={fullWidth}
        maxWidth="sm"
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}
      >
        <div className={classes.root}>
          <DialogTitle id="customized-dialog-title" className={classes.dialogTitle} onClose={handleClose}>
            Que veux-tu faire {username} ?
          </DialogTitle>
          <Divider />
          <div className={classes.formControlDiv}>
            <FormControl className={classes.formControl}>
              <RadioGroup
                value={radioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Close the Case"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Remove from Issues"
                />
              </RadioGroup>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSave}
              >
                Valider
              </Button>
            </FormControl>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return { users: state.users };
};

export default connect(mapStateToProps, { editIssue, fromIssuesToTrackings })(BurgerPopUp);
