import { STATISTICS_GET_PARETO } from '../actions/types';

const initialState = {
  mainStatisticsP: {},
  n1: {},
  n2: {},
  n3: {},
};

const statisticsGetPareto = (state = initialState, action) => {
  switch(action.type) {
    case STATISTICS_GET_PARETO:
      return action.payload;
    default:
      return state;
  }
};

export default statisticsGetPareto;
