import { CA_BUTTONS } from '../actions/types';


const initialValues = {
  invoiceCreate: false,
  invoiceOffer: false,

  transport: false,
  other: false,
};

const caButtons = (state = initialValues, action) => {
  switch(action.type){
    case CA_BUTTONS:
      return action.payload;
    default:
      return state;
  }
};

export default caButtons;
