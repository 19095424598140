import { OPEN_ALERT_TASKS } from '../actions/types';

const alertTasksOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_ALERT_TASKS:
      return action.payload;
    default:
      return state;
  }
};

export default alertTasksOpen;
