import { STATISTICS_GENERAL_GROUP } from '../actions/types';

const initialState = {
  groupOne: true, // 12 mois, quarters
  groupTwo: false, // n1, n2, n3
  groupThree: false, // Pareto
};

// Here we group buttons to avoid call same api again and again
const statisticsGeneralGroup = (state = initialState, action) => {
  switch(action.type) {
    case STATISTICS_GENERAL_GROUP:
      return action.payload;
    default: 
      return state;
  }
};

export default statisticsGeneralGroup;
