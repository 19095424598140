import { INVOICES_SORT } from '../actions/types';


const initialValues = {
  byName: false,
  byControlledDate: false,
  isAsc: true,
};

const invoicesSort = (state = initialValues, action) => {
  switch(action.type) {
    case INVOICES_SORT:
      return action.payload;
    default:
      return state;
  }
};

export default invoicesSort;
