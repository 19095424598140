import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getStatisticsGeneral,
  setStatisLoadCount,
  setStatisLoadParetoCount,
} from '../../actions';
import useToken from '../../login/useToken';
import { calcPercentage,
  signOfPercentage,
  seperateThousands,
} from '../../common/functions';


const borderColor = '#010101';

const useStyles = makeStyles((theme) => ({
  differentRows: {
    overflowWrap: 'anywhere',

    '& tr:nth-child(even)': {
      backgroundColor: '#d9d9d9',
    },

    '& tr.italic': {
      fontStyle: 'italic',
    },

    '& tr.total': {
      backgroundColor: '#b4c6e7',

      '& td': {
        fontWeight: '700',
      },
    },

    '& .green': {
      backgroundColor: '#c6e0b4',
    },

    '& .red': {
      backgroundColor: '#fa9a9a',
    },

    '& td': {
      borderRight: `1px solid ${borderColor}`,
      borderBottom: `1px solid ${borderColor}`,
    },

    '& td:not(:first-child)': {
      textAlign: 'end',
    },

    '& td:first-child': {
      borderLeft: `1px solid ${borderColor}`,
    },
    
    '& td:not(.delta)': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },

    '& td.delta': {
      textAlign: 'center',

      '&.positive': {
        backgroundColor: '#c6e0b4',
      },

      '&.negative': {
        backgroundColor: '#fa9a9a',
      },
    },
  },
  loading: {
    textAlign: 'center',
    height: '50vh',
    color: '#777',
    fontSize: 72,
    fontStyle: 'italic',
  },
}));

const selectedOldsNames = {
  n1: 'N-1',
  n2: 'N-2',
  n3: 'N-3',
};

function RowTotal(props) {
  const { item, months_axis } = props;

  
  if(!Object.keys(item).length) { return null; }

  return (
    <React.Fragment>
      <TableRow className="total">
        <TableCell>{months_axis}</TableCell>
        <TableCell>{seperateThousands(item.total_rows.shipments)}</TableCell>
        <TableCell />
        <TableCell>{seperateThousands(item.total_rows.documents)}</TableCell>
        <TableCell />
        <TableCell>{seperateThousands(item.total_rows.packages)}</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell>{seperateThousands(item.total_rows.turnover)}</TableCell>
        <TableCell />
        <TableCell>{seperateThousands(item.total_rows.profit)}</TableCell>
        <TableCell />
        <TableCell>{seperateThousands(item.total_rows.new_users)}</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell>{seperateThousands(item.total_rows.new_active_users_company)}</TableCell>
        <TableCell>{seperateThousands(item.total_rows.new_active_users_private)}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Row(props) {
  const { item,
    itemOrder,
    rowClass,
  } = props;
  
  if(!Object.keys(item).length) { return null; }

  return (
    <React.Fragment>
      <TableRow className={rowClass ? rowClass : ''}>
        <TableCell>{item.months_axis[itemOrder]}</TableCell>
        <TableCell>{seperateThousands(item.shipments_axis.all[itemOrder])}</TableCell>
        <TableCell />
        <TableCell>{seperateThousands(item.type_axis.all.documents[itemOrder])}</TableCell>
        <TableCell />
        <TableCell>{seperateThousands(item.type_axis.all.packages[itemOrder])}</TableCell>
        <TableCell />
        <TableCell>{seperateThousands(item.type_axis.all.pallets[itemOrder])}</TableCell>
        <TableCell>{seperateThousands(item.average_turnover.all.shipment_per_day[itemOrder])}</TableCell>
        <TableCell />
        <TableCell>{seperateThousands(item.total_turnover.all.total_turnover[itemOrder])}</TableCell>
        <TableCell />
        <TableCell>{seperateThousands(item.total_turnover.all.total_profit[itemOrder])}</TableCell>
        <TableCell />
        <TableCell>{seperateThousands(item.new_users[itemOrder])}</TableCell>
        <TableCell />
        <TableCell>{seperateThousands(item.total_users[itemOrder])}</TableCell>
        <TableCell />
        <TableCell>{seperateThousands(item.active_users_company[itemOrder])}</TableCell>
        <TableCell />
        <TableCell>{seperateThousands(item.active_users_private[itemOrder])}</TableCell>
        <TableCell>{seperateThousands(item.new_active_users_company[itemOrder])}</TableCell>
        <TableCell>{seperateThousands(item.new_active_users_private[itemOrder])}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function RowCalculation(props) {
  const { months_axis,
    shipments_axis,
    third,
    thirdClass,
    documents,
    fifth,
    fifthClass,
    packages,
    seventh,
    seventhClass,

    pallets,
    // shipment_per_day,

    shipment_per_day,
    ninth,
    ninthClass,
    average_turnover,
    eleventh,
    eleventhClass,
    average_profit,
    thirteenth,
    thirteenthClass,
    new_users,
    fifteenth,
    fifteenthClass,
    total_users,
    seventeenth,
    seventeenthClass,
    active_users,
    nineteenth,
    nineteenthClass,
    active_users_private,
    new_active_users,
    new_active_users_private,
    rowClass,
  } = props;

  return (
    <React.Fragment>
      <TableRow className={rowClass ? rowClass : ''}>
        <TableCell>{months_axis}</TableCell>
        <TableCell>{seperateThousands(shipments_axis)}</TableCell>
        <TableCell className={`delta ${thirdClass}`}>{third}</TableCell>
        <TableCell>{seperateThousands(documents)}</TableCell>
        <TableCell className={`delta ${fifthClass}`}>{fifth}</TableCell>
        <TableCell>{seperateThousands(packages)}</TableCell>
        <TableCell className={`delta ${seventhClass}`}>{seventh}</TableCell>

        <TableCell>{seperateThousands(pallets)}</TableCell>
        {/* <TableCell>{seperateThousands(shipment_per_day)}</TableCell> */}

        <TableCell>{seperateThousands(shipment_per_day)}</TableCell>
        <TableCell className={`delta ${ninthClass}`}>{ninth}</TableCell>
        <TableCell>{seperateThousands(average_turnover)}</TableCell>
        <TableCell className={`delta ${eleventhClass}`}>{eleventh}</TableCell>
        <TableCell>{seperateThousands(average_profit)}</TableCell>
        <TableCell className={`delta ${thirteenthClass}`}>{thirteenth}</TableCell>
        <TableCell>{seperateThousands(new_users)}</TableCell>
        <TableCell className={`delta ${fifteenthClass}`}>{fifteenth}</TableCell>
        <TableCell>{seperateThousands(total_users)}</TableCell>
        <TableCell className={`delta ${seventeenthClass}`}>{seventeenth}</TableCell>
        <TableCell>{seperateThousands(active_users)}</TableCell>
        <TableCell className={`delta ${nineteenthClass}`}>{nineteenth}</TableCell>
        <TableCell>{seperateThousands(active_users_private)}</TableCell>
        <TableCell>{seperateThousands(new_active_users)}</TableCell>
        <TableCell>{seperateThousands(new_active_users_private)}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CompareNone({ statisticsGet }) {
  return (
    <React.Fragment>
      {Object.keys(statisticsGet.months_axis).map((itemOrder) => <Row
      key={itemOrder}
      item={statisticsGet}
      itemOrder={itemOrder}         
    />)}
    <RowTotal
      months_axis={'Total'}
      item={statisticsGet}
    />
    </React.Fragment>
  );
}

function CompareNonePareto({ statisticsGet, statisticsGetPareto, paretoTwo }) {
  return (
    <React.Fragment>
      {Object.keys(statisticsGet.months_axis).map((itemOrder) => <React.Fragment key={itemOrder}>
        <Row
          item={statisticsGet}
          itemOrder={itemOrder}            
        />
        <Row
          item={statisticsGetPareto}
          itemOrder={itemOrder}            
        />
      </React.Fragment>)}
      <RowTotal
        item={statisticsGet}
        months_axis={'Total'}
      />
      <RowTotal
        item={statisticsGetPareto}
        months_axis={`Total Pareto ${paretoTwo ? 'x2' : ''}`}
      />
    </React.Fragment>
  );
}

function CompareTwo({ statisticsGet, second, selectedOlds }) {
  if(!Object.keys(second).length | !Object.keys(statisticsGet).length) {
    return null;
  }

  return (
    <React.Fragment>
      {Object.keys(statisticsGet.months_axis).map((itemOrder) => <React.Fragment key={itemOrder}>
          <RowCalculation
          months_axis={statisticsGet.months_axis[itemOrder]}
          shipments_axis={statisticsGet.shipments_axis.all[itemOrder]}
          third={calcPercentage(statisticsGet.shipments_axis.all[itemOrder], second.shipments_axis.all[itemOrder])}
          thirdClass={signOfPercentage(statisticsGet.shipments_axis.all[itemOrder], second.shipments_axis.all[itemOrder])}
          documents={statisticsGet.type_axis.all.documents[itemOrder]}
          fifth={calcPercentage(statisticsGet.type_axis.all.documents[itemOrder], second.type_axis.all.documents[itemOrder])}
          fifthClass={signOfPercentage(statisticsGet.type_axis.all.documents[itemOrder], second.type_axis.all.documents[itemOrder])}
          packages={statisticsGet.type_axis.all.packages[itemOrder]}
          seventh={calcPercentage(statisticsGet.type_axis.all.packages[itemOrder], second.type_axis.all.packages[itemOrder])}
          seventhClass={signOfPercentage(statisticsGet.type_axis.all.packages[itemOrder], second.type_axis.all.packages[itemOrder])}

          pallets={statisticsGet.type_axis.all.pallets[itemOrder]}
          // same with next
          
          shipment_per_day={statisticsGet.average_turnover.all.shipment_per_day[itemOrder]}
          ninth={calcPercentage(statisticsGet.average_turnover.all.shipment_per_day[itemOrder], second.average_turnover.all.shipment_per_day[itemOrder])}
          ninthClass={signOfPercentage(statisticsGet.average_turnover.all.shipment_per_day[itemOrder], second.average_turnover.all.shipment_per_day[itemOrder])}
          average_turnover={statisticsGet.total_turnover.all.total_turnover[itemOrder]}
          eleventh={calcPercentage(statisticsGet.total_turnover.all.total_turnover[itemOrder], second.total_turnover.all.total_turnover[itemOrder])}
          eleventhClass={signOfPercentage(statisticsGet.total_turnover.all.total_turnover[itemOrder], second.total_turnover.all.total_turnover[itemOrder])}
          average_profit={statisticsGet.total_turnover.all.total_profit[itemOrder]}
          thirteenth={calcPercentage(statisticsGet.total_turnover.all.total_profit[itemOrder], second.total_turnover.all.total_profit[itemOrder])}
          thirteenthClass={signOfPercentage(statisticsGet.total_turnover.all.total_profit[itemOrder], second.total_turnover.all.total_profit[itemOrder])}
          new_users={statisticsGet.new_users[itemOrder]}
          fifteenth={calcPercentage(statisticsGet.new_users[itemOrder], second.new_users[itemOrder])}
          fifteenthClass={signOfPercentage(statisticsGet.new_users[itemOrder], second.new_users[itemOrder])}
          total_users={statisticsGet.total_users[itemOrder]}
          seventeenth={calcPercentage(statisticsGet.total_users[itemOrder], second.total_users[itemOrder])}
          seventeenthClass={signOfPercentage(statisticsGet.total_users[itemOrder], second.total_users[itemOrder])}
          active_users={statisticsGet.active_users_company[itemOrder]}
          nineteenth={calcPercentage(statisticsGet.active_users_company[itemOrder], second.active_users_company[itemOrder])}
          nineteenthClass={signOfPercentage(statisticsGet.active_users_company[itemOrder], second.active_users_company[itemOrder])}
          active_users_private={statisticsGet.active_users_private[itemOrder]}                
          new_active_users={statisticsGet.new_active_users_company[itemOrder]}                
          new_active_users_private={statisticsGet.new_active_users_private[itemOrder]}                
        />
        <Row
          item={second}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
      </React.Fragment>)}
      <RowTotal
        item={statisticsGet}
        months_axis={'Total'}
      />
      <RowTotal
        item={second}
        months_axis={`Total ${selectedOldsNames[selectedOlds[0]]}`}
      />
    </React.Fragment>
  );
}

function CompareTwoPareto(props) {
  const { statisticsGet,
    statisticsGetPareto,
    second,
    secondPareto,
    selectedOlds,
    paretoTwo,
  } = props;

  return (
    <React.Fragment>
      {Object.keys(statisticsGet.months_axis).map((itemOrder) => <React.Fragment key={itemOrder}>
        <Row
          item={statisticsGet}
          itemOrder={itemOrder}            
        />
        <Row
          item={statisticsGetPareto}
          itemOrder={itemOrder}            
        />
        <Row
          item={second}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
        <Row
          item={secondPareto}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
      </React.Fragment>)}
      <RowTotal
        item={statisticsGet}
        months_axis={'Total'}
      />
      <RowTotal
        item={statisticsGetPareto}
        months_axis={`Total Pareto ${paretoTwo ? 'x2' : ''}`}
      />
      <RowTotal
        item={second}
        months_axis={`Total ${selectedOldsNames[selectedOlds[0]]}`}
      />
      <RowTotal
        item={secondPareto}
        months_axis={`Total ${selectedOldsNames[selectedOlds[0]]} Pareto ${paretoTwo ? 'x2' : ''}`}
      />
    </React.Fragment>
  );
}

function CompareThree({ statisticsGet, second, third, selectedOlds }) {
  return (
    <React.Fragment>
      {Object.keys(statisticsGet.months_axis).map((itemOrder) => <React.Fragment key={itemOrder}>
        <Row
          item={statisticsGet}
          itemOrder={itemOrder}            
        />
        <Row
          item={second}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
        <Row
          item={third}
          itemOrder={itemOrder}
          rowClass={'italic'}          
        />
      </React.Fragment>)}
      <RowTotal
        item={statisticsGet}
        months_axis={'Total'}
      />
      <RowTotal
        item={second}
        months_axis={`Total ${selectedOldsNames[selectedOlds[0]]}`}
      />
      <RowTotal      
        item={third}
        months_axis={`Total ${selectedOldsNames[selectedOlds[1]]}`}
      />
    </React.Fragment>
  );
}

function CompareThreePareto(props) {
  const { statisticsGet,
    statisticsGetPareto,
    second,
    secondPareto,
    third,
    thirdPareto,
    selectedOlds,
    paretoTwo,
  } = props;

  return (
    <React.Fragment>
      {Object.keys(statisticsGet.months_axis).map((itemOrder) => <React.Fragment key={itemOrder}>
        <Row
          item={statisticsGet}
          itemOrder={itemOrder}            
        />
        <Row
          item={statisticsGetPareto}
          itemOrder={itemOrder}            
        />
        <Row
          item={second}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
        <Row
          item={secondPareto}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
        <Row
          item={third}
          itemOrder={itemOrder}
          rowClass={'italic'}          
        />
        <Row
          item={thirdPareto}
          itemOrder={itemOrder}
          rowClass={'italic'}          
        />
      </React.Fragment>)}
      <RowTotal
        item={statisticsGet}
        months_axis={'Total'}
      />
      <RowTotal
        item={statisticsGetPareto}
        months_axis={`Total Pareto ${paretoTwo ? 'x2' : ''}`}
      />
      <RowTotal
        item={second}
        months_axis={`Total ${selectedOldsNames[selectedOlds[0]]}`}
      />
      <RowTotal
        item={secondPareto}
        months_axis={`Total ${selectedOldsNames[selectedOlds[0]]} Pareto ${paretoTwo ? 'x2' : ''}`}
      />
      <RowTotal      
        item={third}
        months_axis={`Total ${selectedOldsNames[selectedOlds[1]]}`}
      />
      <RowTotal
        item={thirdPareto}
        months_axis={`Total ${selectedOldsNames[selectedOlds[1]]} Pareto ${paretoTwo ? 'x2' : ''}`}
      />
    </React.Fragment>
  );
}

function CompareFour({ statisticsGet, second, third, fouth }) {
  return (
    <React.Fragment>
      {Object.keys(statisticsGet.months_axis).map((itemOrder) => <React.Fragment key={itemOrder}>
        <Row
          item={statisticsGet}
          itemOrder={itemOrder}
        />
        <Row
          item={second}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
        <Row
          item={third}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
        <Row
          item={fouth}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
      </React.Fragment>)}
      <RowTotal
        item={statisticsGet}
        months_axis={'Total'}
      />
      <RowTotal
        item={second}
        months_axis={'Total N-1'}
      />
      <RowTotal
        item={third}
        months_axis={'Total N-2'}
      />
      <RowTotal
        item={fouth}
        months_axis={'Total N-3'}
      />
    </React.Fragment>
  );
}

function CompareFourPareto(props) {
  const { statisticsGet,
    statisticsGetPareto,
    second,
    secondPareto,
    third,
    thirdPareto,
    fouth,
    fouthPareto,
    paretoTwo,
  } = props;
  return (
    <React.Fragment>
      {Object.keys(statisticsGet.months_axis).map((itemOrder) => <React.Fragment key={itemOrder}>
        <Row
          item={statisticsGet}
          itemOrder={itemOrder}
        />
        <Row
          item={statisticsGetPareto}
          itemOrder={itemOrder}
        />
        <Row
          item={second}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
        <Row
          item={secondPareto}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
        <Row
          item={third}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
        <Row
          item={thirdPareto}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
        <Row
          item={fouth}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
        <Row
          item={fouthPareto}
          itemOrder={itemOrder}
          rowClass={'italic'}
        />
      </React.Fragment>)}
      <RowTotal
        item={statisticsGet}
        months_axis={'Total'}
      />
      <RowTotal
        item={statisticsGetPareto}
        months_axis={`Total Pareto ${paretoTwo ? 'x2' : ''}`}
      />
      <RowTotal
        item={second}
        months_axis={'Total N-1'}
      />
      <RowTotal
        item={secondPareto}
        months_axis={`Total N-1 Pareto ${paretoTwo ? 'x2' : ''}`}
      />
      <RowTotal
        item={third}
        months_axis={'Total N-2'}
      />
      <RowTotal
        item={thirdPareto}
        months_axis={`Total N-2 Pareto ${paretoTwo ? 'x2' : ''}`}
      />
      <RowTotal
        item={fouth}
        months_axis={'Total N-3'}
      />
      <RowTotal
        item={fouthPareto}
        months_axis={`Total N-3 Pareto ${paretoTwo ? 'x2' : ''}`}
      />
    </React.Fragment>
  );
}

function StatisticsGeneralBody(props) {
  const classes = useStyles();
  const { token } = useToken();
  const { statisticsGet,
    statisticsN1Get,
    statisticsN2Get,
    statisticsN3Get,
    statisticsGetPareto,
    statisticsLoading,
    statisticsLoadingCount,
    statisticsLoadingPareto,
    statisticsLoadingParetoCount,
    getStatisticsGeneral,
    setStatisLoadCount,
    setStatisLoadParetoCount,
    statisticsGeneralFilterButtons,
    statisticsGenCallApi,
  } = props;
  const [olds, setOlds] = useState({
    n1: false,
    n2: false,
    n3: false,
  });
  const [lengthOlds, setLengthOlds] = useState(0);
  const [oldsData, setOldsData] = useState({
    n1: statisticsN1Get,
    n2: statisticsN2Get,
    n3: statisticsN3Get,
  });
  const [oldsDataPareto, setOldsDataPareto] = useState({
    n1: statisticsGetPareto.n1,
    n2: statisticsGetPareto.n2,
    n3: statisticsGetPareto.n3,
  });
  const [selectedOlds, setSelectedOlds] = useState([]);
  const [pareto, setPareto] = useState({
    one: statisticsGeneralFilterButtons.pareto,
    two: statisticsGeneralFilterButtons.paretoOfPareto
  });

  useEffect(() => {
    getStatisticsGeneral(token);
  }, [getStatisticsGeneral, token, statisticsGenCallApi]);
  
  useEffect(() => {
    setOlds({
      n1: statisticsGeneralFilterButtons.n1,
      n2: statisticsGeneralFilterButtons.n2,
      n3: statisticsGeneralFilterButtons.n3,
    });

    setPareto({
      one: statisticsGeneralFilterButtons.pareto,
      two: statisticsGeneralFilterButtons.paretoOfPareto
    });
  }, [statisticsGeneralFilterButtons]);
  
  useEffect(() => {
    setOldsData({
      n1: statisticsN1Get,
      n2: statisticsN2Get,
      n3: statisticsN3Get,
    });

    setOldsDataPareto({
      n1: statisticsGetPareto.n1,
      n2: statisticsGetPareto.n2,
      n3: statisticsGetPareto.n3,
    });
  }, [statisticsN1Get, statisticsN2Get, statisticsN3Get, statisticsGetPareto]);

  useEffect(() => {
    setLengthOlds(Object.values(olds).filter(fl => fl).length);
    
    setSelectedOlds([
      olds.n1 ? 'n1' : null,
      olds.n2 ? 'n2' : null,
      olds.n3 ? 'n3' : null,
    ].filter(ft => ft));
  }, [olds]);

  useEffect(() => {
    setStatisLoadCount(Object.values(statisticsLoading).filter(flt => flt).length);
    setStatisLoadParetoCount(Object.values(statisticsLoadingPareto).filter(flt => flt).length);
  }, [statisticsLoading, statisticsLoadingPareto,
    setStatisLoadCount, setStatisLoadParetoCount]);

  const loadingInfo = (<TableRow><TableCell className={classes.loading} colSpan={25}>Loading...</TableCell></TableRow>);

  return (
    <React.Fragment>
      <TableBody className={classes.differentRows}>
        {(statisticsLoadingCount | statisticsLoadingParetoCount) ? loadingInfo : null}
        {statisticsLoadingCount ? null
          : pareto.one ? null
          : pareto.two ? null
          : !Object.keys(statisticsGet).length ? null
          : !lengthOlds ? <CompareNone statisticsGet={statisticsGet} />
          : (lengthOlds === 1 && !statisticsLoadingCount && !statisticsLoadingCount && Object.keys(oldsData[selectedOlds[0]]).length) ? <CompareTwo statisticsGet={statisticsGet} second={oldsData[selectedOlds[0]]} selectedOlds={selectedOlds} />
          : (lengthOlds === 2 && !statisticsLoadingCount && !statisticsLoadingCount && Object.keys(oldsData[selectedOlds[0]]).length && Object.keys(oldsData[selectedOlds[1]]).length) ? <CompareThree statisticsGet={statisticsGet} second={oldsData[selectedOlds[0]]} third={oldsData[selectedOlds[1]]} selectedOlds={selectedOlds} />
          : (lengthOlds === 3 && !statisticsLoadingCount && !statisticsLoadingCount && Object.keys(oldsData[selectedOlds[0]]).length && Object.keys(oldsData[selectedOlds[1]]).length && Object.keys(oldsData[selectedOlds[2]]).length) ? <CompareFour statisticsGet={statisticsGet} second={statisticsN1Get} third={statisticsN2Get} fouth={statisticsN3Get} />
        : null}
        
        {(statisticsLoadingCount | statisticsLoadingCount) ? null
          : (!pareto.one && !pareto.two) ? null
          : !Object.keys(statisticsGet).length ? null
          : !Object.keys(statisticsGetPareto.mainStatisticsP).length ? null

          : !lengthOlds ? <CompareNonePareto statisticsGet={statisticsGet} statisticsGetPareto={statisticsGetPareto.mainStatisticsP} paretoTwo={pareto.two} />

          : (lengthOlds === 1 && !statisticsLoadingCount && !statisticsLoadingCount && Object.keys(oldsData[selectedOlds[0]]).length && Object.keys(oldsDataPareto[selectedOlds[0]]).length) ? <CompareTwoPareto statisticsGet={statisticsGet} statisticsGetPareto={statisticsGetPareto.mainStatisticsP} second={oldsData[selectedOlds[0]]} secondPareto={oldsDataPareto[selectedOlds[0]]} selectedOlds={selectedOlds} paretoTwo={pareto.two} />

          : (lengthOlds === 2 && !statisticsLoadingCount && !statisticsLoadingCount && Object.keys(oldsData[selectedOlds[0]]).length && Object.keys(oldsDataPareto[selectedOlds[0]]).length && Object.keys(oldsData[selectedOlds[1]]).length && Object.keys(oldsDataPareto[selectedOlds[1]]).length) ? <CompareThreePareto statisticsGet={statisticsGet} statisticsGetPareto={statisticsGetPareto.mainStatisticsP} second={oldsData[selectedOlds[0]]} secondPareto={oldsDataPareto[selectedOlds[0]]} third={oldsData[selectedOlds[1]]} thirdPareto={oldsDataPareto[selectedOlds[1]]} selectedOlds={selectedOlds} paretoTwo={pareto.two} />

          : (lengthOlds === 3 && !statisticsLoadingCount && !statisticsLoadingCount && Object.keys(oldsData[selectedOlds[0]]).length && Object.keys(oldsDataPareto[selectedOlds[0]]).length && Object.keys(oldsData[selectedOlds[1]]).length && Object.keys(oldsDataPareto[selectedOlds[1]]).length && Object.keys(oldsData[selectedOlds[2]]).length && Object.keys(oldsDataPareto[selectedOlds[2]]).length) ? <CompareFourPareto statisticsGet={statisticsGet} statisticsGetPareto={statisticsGetPareto.mainStatisticsP} second={statisticsN1Get} secondPareto={statisticsGetPareto.n1} third={statisticsN2Get} thirdPareto={statisticsGetPareto.n2} fouth={statisticsN3Get} fouthPareto={statisticsGetPareto.n3} paretoTwo={pareto.two} />
        : null}
      </TableBody>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    statisticsGet: state.statisticsGet,
    statisticsN1Get: state.statisticsN1Get,
    statisticsN2Get: state.statisticsN2Get,
    statisticsN3Get: state.statisticsN3Get,
    statisticsGetPareto: state.statisticsGetPareto,
    statisticsLoading: state.statisticsLoading,
    statisticsLoadingCount: state.statisticsLoadingCount,
    statisticsLoadingPareto: state.statisticsLoadingPareto,
    statisticsLoadingParetoCount: state.statisticsLoadingParetoCount,
    statisticsGeneralFilterButtons: state.statisticsGeneralFilterButtons,
    statisticsGenCallApi: state.statisticsGenCallApi,
  };
};

export default connect(mapStateToProps, {
  getStatisticsGeneral,
  setStatisLoadCount,
  setStatisLoadParetoCount,
})(StatisticsGeneralBody);
