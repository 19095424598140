import expedismart from './expedismart';
import newadmindb from './newadmindb';


///////////////////////////////
// EXPEDISMART DB

// Get anything from expedismart with token (authorization)
export async function getExpedismart(url, token) {
    const response = await expedismart.get(url, {
        headers: {
            'authorization': `JWT ${token}`
        }
    })

    return response;
}


///////////////////////////////
// NEWADMIN DB

// Get anything from newadmin db
export async function getNewadminDB(url) {
    const response = await newadmindb.get(url);

    return response;
}
