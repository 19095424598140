import { EVENT_ERROR_MESSAGE } from '../actions/types';

const eventErrorMessage = (state = '', action) => {
    switch (action.type) {
        case EVENT_ERROR_MESSAGE:
            return action.payload;
        default: 
            return state;
    }
};

export default eventErrorMessage;
