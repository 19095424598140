// General
export const DRAWER_OPEN = 'DRAWER_OPEN';
export const REFRESH_GENERAL = 'REFRESH_GENERAL';

// Trackings
export const GET_TRACKINGS = 'GET_TRACKINGS';
export const TRACKINGS_FILTER = 'TRACKINGS_FILTER';
export const TRACKINGS_LOADING = 'TRACKINGS_LOADING';
export const TRACKINGS_COUNT = 'TRACKINGS_COUNT';

// Messaging (Chat)
export const GET_CHAT_SHIPS = 'GET_CHAT_SHIPS';
export const CHAT_LOADING = 'CHAT_LOADING';
export const CHAT_ERROR = 'CHAT_ERROR';
export const CHAT_SEARCH = 'CHAT_SEARCH';

// Shipments
export const DELETED_SHIPMENT = 'DELETED_SHIPMENT';

// Status
export const GET_STATUS_SHIPMENT = 'GET_STATUS_SHIPMENT';
export const SELECTED_STATUS_ID = 'SELECTED_STATUS_ID';
export const GET_STATUS_LIST = 'GET_STATUS_LIST';

// Comments & Tasks
export const GET_TASK_COMMENT = 'GET_TASK_COMMENT';
export const SHIPMENT_TASKS_COMMENTS_COUNT = 'SHIPMENT_TASKS_COMMENTS_COUNT';
export const CR_TASKS_COMMENTS_COUNT = 'CR_TASKS_COMMENTS_COUNT';
export const GET_TASK_COUNT = 'GET_TASK_COUNT';
export const GET_TASKS_DASHBOARD = 'GET_TASKS_DASHBOARD';
export const TASK_FORM_INPUT = 'TASK_FORM_INPUT';
export const DOWNLOAD_FILES_LOAD = 'DOWNLOAD_FILES_LOAD';

// Customer-Account
export const CA_SELECTED_SHIPMENT = 'CA_SELECTED_SHIPMENT';
export const CA_GET_CUSTOMER_BY_ID = 'CA_GET_CUSTOMER_BY_ID';
export const CA_GO_TO = 'CA_GO_TO';
export const CA_LOAD_ALL = 'CA_LOAD_ALL';
export const CA_BUTTONS = 'CA_BUTTONS';
export const CA_INVOICING = 'CA_INVOICING';

// Users
export const GET_USERS = 'GET_USERS';

// Issues
export const ISSUES_LOADING = 'ISSUES_LOADING';
export const GET_ISSUES = 'GET_ISSUES';
export const ISSUES_SEARCH_INPUT = 'ISSUES_SEARCH_INPUT';
export const MAKE_SHIPMENT_AN_ISSUE = 'MAKE_SHIPMENT_AN_ISSUE';
export const FROM_ISSUES_TO_TRACKINGS = 'FROM_ISSUES_TO_TRACKINGS';
export const POST_ISSUE = 'POST_ISSUE';
export const EDIT_ISSUE = 'EDIT_ISSUE';
export const REFRESH_ISSUES_PAGE = 'REFRESH_ISSUES_PAGE';
export const REFRESH_CARD = 'REFRESH_CARD';
export const TRANSPORT_FILTERS = 'TRANSPORT_FILTERS';
export const COUNT_ISSUES = 'COUNT_ISSUES';

// Dashboard
export const GOOGLE_EVENT_DELETE = 'GOOGLE_EVENT_DELETE';
export const GOOGLE_EVENT_EMAIL_ERROR = 'GOOGLE_EVENT_EMAIL_ERROR';
export const EVENT_ERROR_MESSAGE = 'EVENT_ERROR_MESSAGE';
export const GET_SHIPMENTS_DASHBOARD = 'GET_SHIPMENTS_DASHBOARD';
export const SHIPMENTS_DASHBOARD_LOADING = 'SHIPMENTS_DASHBOARD_LOADING';
export const GET_SHIPMENT_TRANSPORT_DASHBOARD = 'GET_SHIPMENT_TRANSPORT_DASHBOARD';
export const SHIPMENT_TRANSPORT_DASH_LOAD = 'SHIPMENT_TRANSPORT_DASH_LOAD';
export const DASHBOARD_CALENDAR_LOADING = 'DASHBOARD_CALENDAR_LOADING';
export const API_KEY_AND_CALENDAR_ID = 'API_KEY_AND_CALENDAR_ID';

// Alert-Tasks
export const OPEN_ALERT_TASKS = 'OPEN_ALERT_TASKS';
export const GET_TASK_REMINDER = 'GET_TASK_REMINDER';

// Contact Request
export const C_R_FILTER_BUTTONS = 'C_R_FILTER_BUTTONS';
export const CONTACT_REQUEST_SEARCH = 'CONTACT_REQUEST_SEARCH';
export const CONTACT_REQUEST = 'CONTACT_REQUEST';
export const CONTACT_REQUEST_COUNT = 'CONTACT_REQUEST_COUNT';
export const CONTACT_REQUEST_LOADING = 'CONTACT_REQUEST_LOADING';
export const CONTACT_REQUEST_DETAILS = 'CONTACT_REQUEST_DETAILS';
export const CONTACT_REQUEST_TYPES = 'CONTACT_REQUEST_TYPES';
export const CONTACT_REQUEST_REFRESH = 'CONTACT_REQUEST_REFRESH';
export const CONTACT_REQUEST_DATE = 'CONTACT_REQUEST_DATE';


// Statistics
export const STATISTICS_GENERAL_FILTER_BUTTONS = 'STATISTICS_GENERAL_FILTER_BUTTONS';
export const STATISTICS_CUSTOMER_FILTER_BUTTONS = 'STATISTICS_CUSTOMER_FILTER_BUTTONS';
export const STATISTICS_GENERAL_GROUP = 'STATISTICS_GENERAL_GROUP';
export const STATISTICS_GENERAL_GROUP_OLDS = 'STATISTICS_GENERAL_GROUP_OLDS';
export const STATISTICS_GENERAL_GROUP_PARETO = 'STATISTICS_GENERAL_GROUP_PARETO';
export const STATISTICS_GENERAL_CALL_API = 'STATISTICS_GENERAL_CALL_API';
export const STATISTICS_GET = 'STATISTICS_GET';
export const STATISTICS_GET_PARETO = 'STATISTICS_GET_PARETO';
export const STATISTICS_N1_GET = 'STATISTICS_N1_GET';
export const STATISTICS_N2_GET = 'STATISTICS_N2_GET';
export const STATISTICS_N3_GET = 'STATISTICS_N3_GET';
export const STATISTICS_LOADING = 'STATISTICS_LOADING';
export const STATISTICS_LOADING_COUNT = 'STATISTICS_LOADING_COUNT';
export const STATISTICS_LOADING_PARETO = 'STATISTICS_LOADING_PARETO';
export const STATISTICS_LOADING_PARETO_COUNT = 'STATISTICS_LOADING_PARETO_COUNT';


// Invoices
export const INVOICES_MAIN = 'INVOICES_MAIN';
export const INVOICES_BILLING = 'INVOICES_BILLING';
export const INVOICES_LOADING = 'INVOICES_LOADING';
export const INVOICES_SORT = 'INVOICES_SORT';
export const INVOICES_DATE_ERROR = 'INVOICES_DATE_ERROR';
export const INVOICE_EDIT_RESPONSE = 'INVOICE_EDIT_RESPONSE';
export const INVOICE_CURRENT = 'INVOICE_CURRENT';
export const INVOICE_OVERCHARGING_LOADING = 'INVOICE_OVERCHARGING_LOADING';
export const INVOICE_PACKAGE_RESPONSE = 'INVOICE_PACKAGE_RESPONSE';
export const INVOICE_BY_SHIP_ID = 'INVOICE_BY_SHIP_ID';
export const INVOICE_SHIP_ID_LOADING = 'INVOICE_SHIP_ID_LOADING';
export const INVOICE_CREATE_LOADING = 'INVOICE_CREATE_LOADING';
export const INVOICE_CREATE_SUCCESS = 'INVOICE_CREATE_SUCCESS';


// Snackbar
export const SNACKBAR = 'SNACKBAR';
