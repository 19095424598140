import '../css/TextEditor.css';
import '../../node_modules/draft-js/dist/Draft.css';
import React, { useState,
    useContext,
    useEffect,
    useRef
} from 'react';
import DOMPurify from 'dompurify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import SvgIcon from '@material-ui/core/SvgIcon';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LinkButton from '@material-ui/core/Link';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Avatar from '@material-ui/core/Avatar';
import postFinancePng from '../static/images/pf_icon.png';
import twintPng from '../static/images/twint_icon.png';
import { getMonthFrench } from './CustomerAccountController';
import { numberThousands, logOut } from '../common/Frame';
import { URL_All } from '../common/Constants';
import useToken from '../login/useToken';
import { CustomerAccountContext } from '../contexts/CustomerAccountContext';
import CommentForm from '../common/CommentForm';
import { newadmindb } from '../common/Constants';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AdjustmentFormAdd from './AdjustmentFormAdd';
import AdjustmentFormEdit from './AdjustmentFormEdit';
import AdjustmentPopUpDelete from './AdjustmentPopUpDelete';
import TaskForm from '../common/TaskForm';
import CommentPopUpDelete from './CommentPopUpDelete';
import TaskCommentEdit from './TaskCommentEdit';
import CorrespondencePopUp from '../correspondence/CorrespondencePopUp';
import { getTaskCommentCount,
    setIssuesSearchInput,
    setCASelectedShipment,
    setRefresh,
    setCALoadAll,
} from '../actions';
import { countUndoneTasks, convertDateToTimestamp } from '../common/functions';
import HeaderTop from './HeaderTop';


const useStyles = makeStyles((theme) => ({
    root: {
        background: '#F7F7F7',
        marginTop: theme.spacing(1),
    },
    borderGrid: {
        border: '1px solid #e5d2d2',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    borderGridEnvois: {
        border: '1px solid #e5d2d2',
        paddingLeft: theme.spacing(.5),
        paddingRight: theme.spacing(.5),
        '& .MuiButton-text': {
            overflowWrap: 'anywhere',
        },
        textAlign: 'center',
    },
    sameLine1: {
        display: 'flex',
        marginTop: theme.spacing(1),
        '& .MuiSvgIcon-root:hover': {
            border: '1px solid blue',
        },
    },
    tableCellPaddingZero: {
        '& .MuiTableCell-root': {
            padding: theme.spacing(0),
            border: 'none',
        },
    },
    enterAmount: {
        '& > *': {
          margin: theme.spacing(1),
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 10px) scale(1)',
            fontSize: '1em',
            width: '90%',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
        },
    },
    optionRadio: {
        textAlign: 'center',
        '& .MuiButtonBase-root': {
            marginBottom: theme.spacing(1),
        },
    },
    typeRadio: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            padding: theme.spacing(0),
            border: 'none',
        },
    },
    typeLabel: {
        marginTop: theme.spacing(0),
    },
    taskContent: {
        backgroundColor: '#ffe0b2',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        '& .hoverShow': {
            display: 'none',
        },
        '&:hover': {
            '& .hoverShow': {
                display: 'flex',
            },
        },
        '& .MuiButtonBase-root': {
            padding: theme.spacing(0),
        },
    },
    taskParagraph: {
        marginTop: theme.spacing(0),
    },
    colorGray: {
        color: 'gray',
        display: 'flex',
    },
    marginZero: {
        margin: theme.spacing(0),
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    backgroundGreen: {
        background: '#79ef79',
    },
    tablePareto: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
        },
    },
    tableCA: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
            padding: theme.spacing(0.25),
        },
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    tableCAgreen: {
        backgroundColor: '#c4ffc4',
    },
    tableOptions: {
        '& .MuiTableCell-root': {
            padding: theme.spacing(0),
            border: 'none',
            overflowWrap: 'anywhere',
        },
        '& p': {
            margin: theme.spacing(0),
        },
    },
    tableRabais: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
            padding: theme.spacing(0.5),
        },
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    tableChiffres: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
        },
        marginTop: theme.spacing(6),
    },
    tableTypeDenvois: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
        },
        marginTop: theme.spacing(6),
    },
    tableTopPays: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
        },
        marginTop: theme.spacing(4),
    },
    tableUtilisation: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
        },
        marginTop: theme.spacing(4),
    },
    tableValeurMoyenne: {
        '& .MuiTableCell-root': {
            textAlign: 'center',
            border: '1px solid #ccc', // #e0e0e0
            overflowWrap: 'anywhere',
        },
        marginTop: theme.spacing(4),
    },
    leftBorderNone: {
        borderLeft: 'none !important',
        borderBottom: 'none !important',
        display: 'flex',
        color: '#514f4f',
        '& .buttonPaddingZero': {
            padding: theme.spacing(0),
        },
    },
    rightBorderNone: {
        borderRight: 'none !important',
    },
    alignLeft: {
        textAlign: 'left !important',
    },
    btnRabais: {
        color: 'gray',
        padding: theme.spacing(0.25),
        paddingBottom: theme.spacing(0),
    },
    // btnRabaisEdit: {
    //     '&:hover': {
    //         color: 'gold',
    //     },
    // },
    // btnRabaisDelete: {
    //     '&:hover': {
    //         color: 'red',
    //     },
    // },
    btnRabaisAdd: {
        '&:hover': {
            color: '#3bd73b',
        },
    },
    fontBigger: {
        fontSize: '1.2em',
    },
    fontBiggerEnvois: {
        fontSize: '1.2em',
        margin: theme.spacing(.75),
    },
    fontBiggerResumeDeLenvoi: {
        margin: '20px 0',
        fontSize: '1.2em',
        textAlign: 'center',
        '& .MuiSvgIcon-root': {
            verticalAlign: 'bottom',
            '&:hover': {
                border: '1px solid blue',
            },
            '&:active': {
                border: '1px solid red',
            },
        },
        '& a': {
            color: '#000',
            '&:visited': {
                color: '#000',
            },
        },
        '& .MuiTooltip-popper': {
            zIndex: '1',
        },
    },
    fontBiggerReda: {
        fontSize: '1.2em',
    },
    fontSmallerEnvois: {
        fontSize: '.8em',
    },
    fontSmallerReda: {
        fontSize: '.8em',
    },
    borderGridResumeDeLenvoi: {
        border: '1px solid #e5d2d2',
        paddingLeft: theme.spacing(.5),
        paddingRight: theme.spacing(.5),
        overflowWrap: 'anywhere',
        '& .MuiGrid-container': {
            marginTop: theme.spacing(2),
        },
        '& .MuiGrid-root': {
            textAlign: 'center',
        },
    },
    tasksTrackingNumber: {
        border: '1px solid #e5d2d2',
        paddingTop: theme.spacing(1.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        '& h3': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .task-add': {
            '&:hover': {
                color: '#3bd73b',
            },
        },
    },
    commentsTrackingNumber: {
        border: '1px solid #e5d2d2',
        borderLeft: 'none',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        '& h3': {
            marginLeft: theme.spacing(1.5),
            display: 'flex',
            alignItems: 'center',
        },
        '& .mrgnLftCmmntTrN': {
            margin: theme.spacing(1.75),
        },
        '& .comment-add': {
            '&:hover': {
                color: '#3bd73b',
            },
        },
    },
    borderGridCasLitigieux: {
        border: '1px solid #e5d2d2',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1),
        fontSize: '1.2em',
        '& h3': {
            marginLeft: theme.spacing(2),
        },
        '& p': {
            marginLeft: theme.spacing(2),
        },
    },
    verticalCenterIcon: {
        verticalAlign: 'super',
        '&:hover': {
            border: '1px solid blue',
        },
        '&:active': {
            border: '1px solid red',
        },
    },
    marginLeftContent: {
        '& p': {
            marginLeft: theme.spacing(2),
        },

        '& .marginLeftDiv': {
            marginLeft: theme.spacing(2),
        },        
    },
    backgroundRed: {
        background: 'red',
    },
    isInsuredTrue: {
        backgroundColor: '#6bef6b',
    },
    isInsuredFalse: {
        backgroundColor: '#ff0000',
    },
    showMoreLessButton: {
        width: '100%',
        '& > *': {
            color: '#ff0000',
        },
        borderBottom: '1px solid #cacaca',
    },
    displayNone: {
        display: 'none',
    },
    selected: {
        background: '#ffcf78 !important',
        textTransform: 'none',
    },
    search: {
      width: '100%',
      "& .MuiIconButton-root": {
          padding: 0,
          color: "gray",
      },
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    proformaAWBIcon: {
        transform: 'scaleX(-1)',
    },
    borderBottom: {
        borderBottom: '1px solid #ccc',
    },
    marginBottom: {
        marginBottom: theme.spacing(3),
    },
    selectedCustomer: {
        color: '#000',
    },
    selectedCustomerNot: {
        color: '#555',
    },
    selectedTracking: {
        color: '#000',
        '& a': {
            color: '#000',
        },
    },
    selectedTrackingNot: {
        color: '#555',
    },
    paddingZero: {
        padding: theme.spacing(0),
    },
    commentCustomerTitle: {
        display: 'flex',
        alignItems: 'center',
        '& .comment-add': {
            '&:hover': {
                color: '#3bd73b',
            },
        },
    },
    taskCustomerTitle: {
        display: 'flex',
        alignItems: 'center',
        '& .task-add': {
            '&:hover': {
                color: '#3bd73b',
            },
        },
    },
    commentContent: {
        '& span': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .hoverShow': {
            display: 'none',
        },
        '&:hover': {
            '& .hoverShow': {
                display: 'flex',
            },
        },
        '& .MuiButtonBase-root': {
            padding: theme.spacing(0),
        },
    },
    downloadButton: {
        padding: theme.spacing(0),
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    taskStatus0: {
        textTransform: "none",
        background: "#ff9a9a",
    },
    taskStatus1: {
        textTransform: "none",
        background: "#ffff59",
    },
    taskStatus2: {
        textTransform: "none",
        background: "#94eb94",
    },
    noneTextTransform: {
        textTransform: "none",
    },
    invoiceDownloads: {
        '& a': {
            color: '#000',
            '&:visited': {
                color: '#000',
            },
        },
        '& .MuiTooltip-popper': {
            zIndex: '1',
        },
    },
    paragraphNewLine: {
        whiteSpace: 'pre-wrap',
    },
    ifHaveTrackingNumber: {
        display: 'flex',
    },
    btnTrackingNumbers: {
        // fontSize: "1.1em",
        fontWeight: "700",
        whiteSpace: 'pre-wrap',
    },
    ahrefPaddingZero: {
        '& a': {
            padding: theme.spacing(0),
        },
    },
    marginRight2: {
        marginRight: theme.spacing(2),
    },
    displayInitial: {
        flexWrap: 'wrap !important',
    },
    showFilesMoreLessButton: {
      marginLeft: theme.spacing(1),
      textDecoration: 'underline',
      color: 'red',
    },
    displayInlineBlock: {
      display: 'inline-block',
    },
    whiteSpaceNoWrap: {
        whiteSpace: 'nowrap',
    },
    paymentMethod: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    colorRedTableRow: {
        '& .MuiTableCell-root': {
            color: 'red',
        },
        cursor: 'pointer',
    },
    envoiceRows: {
        cursor: 'pointer',
    },
    gridRabais: {
        borderLeft: 'none',
        borderRight: 'none',
        height: '36vh',
        [theme.breakpoints.up(1600)]: {
            height: '27vh',
        },
        overflowY: 'scroll',
    },
    tableEnvois: {
        display: 'block',
        overflowY: 'scroll',
        height: '243vh',
        [theme.breakpoints.up(1600)]: {
            height: '173vh',
        },
    },
    tableBodyEnvois: {
        '& tr:nth-of-type(odd)': {
            backgroundColor: '#cacaca', // theme.palette.action.hover,
        },
        
        '& tr.loadAll': {
            backgroundColor: 'unset',
        },

        '& td': {
            padding: 0,
            textAlign: 'center',
        },
    },
    commentTask: {
        height: '82vh',
        [theme.breakpoints.up(1600)]: {
            height: '58vh',
        },
        overflowY: 'scroll',
    },
    deliveryDate: {
        fontSize: '.8em',
        paddingLeft: theme.spacing(.5),
        width: 'max-content',
    },
    trackingNumberDiv: {
        display: 'inline-flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    marginTopZero: {
        marginTop: 0,
    },
    issue: {
        marginLeft: theme.spacing(2),
        fontWeight: '700',
    },
    dividerInvoice: {
        marginTop: theme.spacing(2),
    },
    task: {
        display: 'inline-block',

        '& p': {
            display: 'inline-block',
            margin: 0,
        },
    },
    trackingNumber: {
        paddingRight: theme.spacing(.5),
    },
    deliveryAddress: {
        width: '100%',
    },
    loadAll: {
        '& td': {
            padding: '8px 0',
            borderBottom: 'none',
        },

        '& button': {
            minWidth: '50%',
        },
    },
}));

export function InvoiceIcon(props) {
    return (<SvgIcon {...props} viewBox="0 0 200.000000 200.000000"
   preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M461 1679 c-83 -42 -127 -135 -138 -291 l-6 -88 92 0 91 0 0 -466 c0
        -412 2 -470 16 -501 32 -66 7 -64 582 -61 l520 3 39 31 c55 45 92 123 100 212
        l6 72 -91 0 -91 0 -3 498 c-3 476 -4 498 -23 531 -11 19 -33 43 -48 55 -28 21
        -38 21 -515 24 -482 2 -487 2 -531 -19z m995 -77 c32 -21 34 -52 34 -531 l0
        -481 -404 0 -403 0 -6 -80 c-6 -89 -30 -145 -63 -145 -19 0 -19 14 -24 600 -4
        463 -8 605 -18 623 l-13 22 443 0 c244 0 448 -4 454 -8z m-956 -144 l0 -68
        -45 0 c-36 0 -45 3 -45 18 0 33 27 120 45 149 l19 28 12 -30 c7 -17 13 -60 14
        -97z m1160 -978 c0 -30 -26 -75 -56 -99 -26 -21 -36 -21 -446 -21 -396 0 -420
        1 -413 18 5 14 25 107 25 118 0 2 200 4 445 4 442 0 445 0 445 -20z"/>
        <path d="M820 1442 c0 -22 -7 -32 -34 -45 -26 -12 -38 -26 -47 -56 -20 -66 -1
        -94 87 -136 41 -20 74 -40 74 -45 0 -22 -48 -29 -100 -16 l-51 13 -15 -35
        c-16 -39 -11 -47 36 -58 47 -11 50 -13 50 -44 0 -30 1 -31 43 -28 39 3 42 5
        45 33 2 23 11 34 34 44 36 16 62 70 53 111 -8 37 -49 73 -113 100 -70 30 -62
        45 21 44 l65 -2 7 35 c7 39 -4 53 -42 53 -19 0 -23 6 -23 30 0 29 -2 30 -45
        30 -43 0 -45 -1 -45 -28z"/>
        <path d="M1117 1413 c-4 -3 -7 -24 -7 -45 l0 -38 140 0 140 0 0 45 0 45 -133
        0 c-74 0 -137 -3 -140 -7z"/>
        <path d="M1112 1178 l3 -43 138 -3 137 -3 0 46 0 45 -141 0 -140 0 3 -42z"/>
        <path d="M1117 1023 c-4 -3 -7 -24 -7 -45 l0 -38 140 0 140 0 0 45 0 45 -133
        0 c-74 0 -137 -3 -140 -7z"/>
        <path d="M732 778 l3 -43 330 0 330 0 3 43 3 42 -336 0 -336 0 3 -42z"/>
      </g>
    </SvgIcon>);
}

export function ProformaAWBIcon(props) {
    return (<SvgIcon {...props} viewBox="0 0 512.000000 441.000000"
      preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,441.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
            <path d="M2980 4292 c0 -10 166 -254 226 -333 l43 -57 173 -7 c281 -12 964 -3
            980 13 14 14 25 295 14 357 l-7 35 -714 0 c-393 0 -715 -4 -715 -8z"/>
            <path d="M3151 3633 c-56 -70 -100 -132 -98 -138 4 -11 88 -22 179 -24 64 -1
            57 9 79 -106 41 -218 234 -402 458 -436 235 -35 480 91 583 301 52 106 62 160
            62 355 l1 170 -582 3 -582 2 -100 -127z"/>
            <path d="M340 2761 c-107 -13 -169 -49 -216 -122 l-25 -39 3 -889 3 -889 30
            -43 c17 -24 52 -57 78 -73 47 -29 51 -30 235 -37 103 -4 202 -8 220 -8 l32 -1
            0 -132 c1 -315 28 -399 150 -464 33 -18 75 -19 895 -19 l860 0 41 22 c44 23
            84 65 112 117 14 26 17 67 20 246 1 118 -1 221 -6 228 -7 11 -201 15 -1035 19
            l-1026 6 -3 1028 c-2 775 -6 1031 -15 1040 -14 14 -258 21 -353 10z"/>
            <path d="M3080 2750 c-91 -4 -178 -11 -195 -15 -110 -29 -152 -75 -610 -670
            -515 -669 -723 -951 -735 -996 -15 -54 -8 -145 14 -187 23 -44 81 -98 135
            -124 63 -30 169 -31 226 -1 65 34 64 34 495 603 343 454 616 806 630 815 7 4
            10 -65 10 -213 0 -345 13 -912 20 -912 11 0 718 926 1102 1445 150 202 175
            241 161 249 -21 11 -1029 17 -1253 6z"/>
            <path d="M4503 2499 c-104 -140 -315 -423 -468 -629 -379 -509 -655 -884 -655
            -891 0 -14 132 -19 570 -19 256 0 489 -3 518 -6 l52 -7 0 -132 0 -132 -721 -5
            c-426 -3 -724 -9 -729 -14 -6 -6 -13 -148 -16 -317 l-7 -307 1016 0 1017 0 -1
            1078 c0 592 -4 1136 -8 1208 -7 116 -11 138 -39 197 -59 128 -168 214 -283
            224 l-57 6 -189 -254z"/>
        </g>
    </SvgIcon>);
}

function PreuveDeLivraisonIcon(props) {
    return (<SvgIcon {...props} viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
            <path d="M545 4788 c-83 -16 -174 -66 -241 -132 -188 -188 -188 -484 0 -672
            276 -276 743 -123 807 264 11 69 4 163 -16 215 -7 16 4 17 133 17 84 0 151 5
            166 11 55 26 55 112 0 138 -16 7 -94 11 -209 11 l-185 0 -62 50 c-113 91 -251
            125 -393 98z"/>
            <path d="M4385 4788 c-83 -16 -174 -66 -241 -132 -188 -188 -188 -483 0 -672
            276 -276 743 -123 807 264 24 147 -26 299 -135 407 -120 119 -268 164 -431
            133z"/>
            <path d="M1687 4469 c-93 -22 -173 -89 -216 -179 l-26 -55 0 -475 0 -475 24
            -52 c29 -66 111 -143 176 -169 47 -18 81 -19 595 -19 514 0 548 1 595 19 65
            26 147 103 176 169 l24 52 0 475 0 475 -26 55 c-33 70 -89 126 -159 159 l-55
            26 -535 2 c-294 1 -552 -3 -573 -8z m393 -546 l0 -238 80 80 80 80 80 -80 80
            -80 0 238 0 237 160 0 160 0 0 -400 0 -400 -480 0 -480 0 0 400 0 400 160 0
            160 0 0 -237z"/>
            <path d="M407 3670 c-94 -24 -173 -90 -216 -180 l-26 -55 0 -640 1 -640 86
            -847 c95 -937 88 -897 167 -953 42 -30 44 -30 195 -33 93 -2 169 1 194 8 56
            14 107 61 126 113 10 27 33 242 66 617 28 316 66 742 85 945 18 204 32 371 31
            372 -1 1 -47 12 -101 23 -55 12 -124 34 -153 48 -71 35 -137 107 -179 199 -37
            81 -186 519 -181 534 2 5 91 -116 199 -268 171 -243 201 -281 240 -301 63 -32
            921 -216 969 -207 105 20 183 126 165 224 -11 57 -46 111 -90 136 -28 17 -150
            48 -409 104 -203 44 -373 84 -377 88 -4 4 -82 143 -174 308 -91 165 -178 313
            -193 328 -14 15 -51 40 -82 55 -50 25 -66 27 -180 29 -69 1 -142 -2 -163 -7z"/>
            <path d="M4405 3663 c-110 -40 -110 -39 -334 -439 -117 -209 -157 -273 -175
            -278 -12 -3 -179 -39 -371 -81 -248 -54 -361 -82 -390 -99 -47 -28 -83 -86
            -91 -150 -13 -94 64 -192 166 -211 48 -8 906 175 969 207 39 20 69 58 240 301
            108 153 197 274 199 269 5 -15 -143 -451 -181 -534 -43 -93 -107 -165 -179
            -200 -29 -14 -98 -36 -153 -48 -54 -11 -100 -22 -102 -23 -1 -2 2 -45 7 -97 6
            -52 28 -300 50 -550 22 -250 47 -527 55 -615 8 -88 24 -266 35 -395 12 -134
            27 -253 36 -277 19 -52 70 -99 126 -113 25 -7 101 -10 194 -8 151 3 153 3 195
            33 73 52 77 66 115 442 18 186 57 570 86 853 l52 515 1 635 0 635 -26 55 c-33
            70 -89 126 -159 158 -49 23 -70 27 -185 29 -105 3 -139 0 -180 -14z"/>
        </g>
    </SvgIcon>);
}

function CreditCardIcon(props) {
    return (<SvgIcon {...props} viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M1343 1730 c-447 -156 -1252 -456 -1270 -473 -49 -44 -45 -60 97
        -455 73 -202 137 -371 142 -377 4 -5 8 183 8 418 0 453 1 462 49 494 24 17 76
        18 667 23 l641 5 -64 175 c-35 96 -72 183 -81 193 -39 38 -73 38 -189 -3z"/>
        <path d="M397 1299 c-15 -11 -31 -37 -37 -57 -8 -25 -10 -186 -8 -490 l3 -454
        32 -28 c20 -18 48 -31 75 -35 24 -3 354 -5 733 -3 640 3 692 4 716 21 14 9 31
        29 37 43 9 18 12 160 12 490 l0 466 -34 34 -34 34 -735 0 c-726 0 -735 0 -760
        -21z m1491 -51 c7 -7 12 -34 12 -60 l0 -48 -745 0 -745 0 0 41 c0 22 5 49 10
        60 10 19 29 19 733 19 549 0 726 -3 735 -12z m12 -637 c0 -288 0 -290 -22
        -305 -20 -14 -105 -16 -723 -16 -618 0 -703 2 -723 16 -22 15 -22 17 -22 305
        l0 289 745 0 745 0 0 -289z"/>
        </g>
    </SvgIcon>);
}

function DisplayTask(props) {
    const classes = useStyles();
    const { task,
        currentUser,
        ifCompanyTask,
        deleteComment,
        findUserByID,
        setByCustomerID,
        ifHaveTrackingNumber,
        setRefresh,
        refresh
    } = props;
    const { customer,
        customerID,
        users,
        getTasksByCustomerID,
        getCommentsByCustomerID
    } = useContext(CustomerAccountContext);
    const [showMoreFiles, setShowMoreFiles] = useState(false);

    // how many files should be shown on all tasks & comments
    const countFile = 5;
    
    function checkIfFilesExist(files) {
        return (files !== null) ? files.slice(0, -1).split(';') : [];
    }

    function dateTimeFrench(params) {
        let dateTime = '';
        dateTime = `${(new Date(params*1000)).getDate().toString().padStart(2, '0')} ${getMonthFrench(params*1000)}
        ${(new Date(params*1000)).getFullYear().toString()}, ${(new Date(params*1000)).getHours().toString().padStart(2, '0')}:${(new Date(params*1000)).getMinutes().toString().padStart(2, '0')}`;

        return dateTime;
    }
    
    function editTaskComment(id, taskStatus, newTask, personName, dueTo) {
        fetch(`${URL_All.CommentTask}/${id}`, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({due_date: dueTo, comment: newTask, to_users: personName, task_status: taskStatus, updated_by: currentUser})
        })
        .then(() => {
            getTasksByCustomerID(customerID);
            getCommentsByCustomerID(customerID);
            setRefresh(refresh + 1);
        })
        .catch(error => console.error(error))
    };
    
    function dateOnlyFrench(params) {
        let dateOnly = '';
        dateOnly = `${(new Date(params*1000)).getDate().toString().padStart(2, '0')} ${getMonthFrench(params*1000)}
        ${(new Date(params*1000)).getFullYear().toString()}`;

        return dateOnly;
    }

    function timeOnly(params) {
        let timeOnly = '';
        timeOnly = `${(new Date(params*1000)).getHours().toString().padStart(2, '0')}:${(new Date(params*1000)).getMinutes().toString().padStart(2, '0')}`;

        return timeOnly;
    }
    
    const cleancombinedText = DOMPurify.sanitize(task.comment, { USE_PROFILES: { html: true } });

    return (<div className={classes.taskContent}>
        <div className={classes.taskParagraph}>
            <span className={ifCompanyTask ? '' : classes.displayNone}>
                <b>
                    {task.tracking_nbr.length > 0 ? ifHaveTrackingNumber(task.tracking_nbr)
                        : (customer.company_name === null) ? customer.users[0].user.username
                        : customer.company_name.trim() === '' ? customer.users[0].user.username
                    : customer.company_name}
                </b>
                <br />
            </span>
            <div className={classes.paragraphNewLine}>
                <div
                    className={classes.task}
                    dangerouslySetInnerHTML={{ __html: cleancombinedText}}
                />
            </div>
            <span>
                {checkIfFilesExist(task.files).slice(0, countFile).map((file, i) => (<span key={i}><Tooltip title={file} placement="top"><a rel="noopener noreferrer" className={`${classes.marginRight2} ${classes.whiteSpaceNoWrap}`} href={`${newadmindb}/basic/web/uploads/${task.id}/${file}`} target="_blank">{file.length > 9 ? `${file.slice(0, 8)}...` : file}</a></Tooltip> </span>))}
                <LinkButton
                    className={checkIfFilesExist(task.files).length > countFile ? classes.showFilesMoreLessButton : classes.displayNone}
                    component="button"
                    variant="body2"
                    onClick={() => setShowMoreFiles(!showMoreFiles)}
                >
                    {showMoreFiles ? 'Show Less' : 'Show More'}
                </LinkButton>
            </span>
            <div className={showMoreFiles ? classes.displayInlineBlock : classes.displayNone}>
                {checkIfFilesExist(task.files).slice(countFile).map((file, i) => (<span key={i}><Tooltip title={file} placement="top"><a rel="noopener noreferrer" className={`${classes.marginRight2} ${classes.whiteSpaceNoWrap}`} href={`${newadmindb}/basic/web/uploads/${task.id}/${file}`} target="_blank">{file}</a></Tooltip> </span>))}
            </div>
            <br />
            <span className={classes.colorGray}>({dateTimeFrench(task.created_at)})&nbsp;
                <TaskCommentEdit
                    id={task.id}
                    taskStatus={task.task_status}
                    dueDate={task.due_date}
                    toUsers={task.to_users !== null & !Number.isInteger(task.to_users) ? task.to_users : Number.isInteger(task.to_users) ? task.to_users.toString() : ''}
                    editTaskComment={editTaskComment}
                    byCustomerID={task.tracking_nbr.length > 0 ? false : true}
                    setByCustomerID={setByCustomerID}
                    isTask={task.is_task === 1 ? true : false}
                    oldTask={task.comment}
                />
                <CommentPopUpDelete
                    id={task.id}
                    comment={task.comment}
                    isTask={task.is_task}
                    deleteComment={deleteComment}
                    byCustomerID={task.tracking_nbr.length > 0 ? false : true}
                    setByCustomerID={setByCustomerID}
                    files={task.files}
                />
            </span>
        </div>
        <p>Attribué à: <b>{users.filter(t => task.to_users.split(',').includes(t.id.toString())).map(item => item.username).join(', ')}</b><br />
            Ajouté par: <b>{findUserByID(task.created_by)}</b><br />
            <span className={(task.created_at === task.updated_at) ? classes.displayNone : ''}>
                Dernière modification à: <b>{findUserByID(task.updated_by)}</b><br /> <span className={classes.colorGray}>({dateOnlyFrench(task.updated_at)}, {timeOnly(task.updated_at)})</span>
            </span>
        </p>
        <span spacing={1}>
            <Button
                className={(task.task_status === 1 | task.task_status === 2) ? classes.taskStatus0 : classes.noneTextTransform}
                size="small"
                onClick={() => {
                    editTaskComment(task.id, 1, task.comment, task.to_users);
                    setByCustomerID(task.tracking_nbr.length > 0 ? false : true);
                }}
            >
                à faire
            </Button>
            <Button
                className={task.task_status === 3 ? classes.taskStatus2 : classes.noneTextTransform}
                size="small"
                onClick={() => {
                    editTaskComment(task.id, 3, task.comment, task.to_users);
                    setByCustomerID(task.tracking_nbr.length > 0 ? false : true);
                }}
            >
                terminé
            </Button>
        </span>
    </div>);
}

function DisplayComment(props) {
    const classes = useStyles();
    const { comment,
        currentUser,
        deleteComment,
        findUserByID,
        setByCustomerID,
        setRefresh,
        refresh
    } = props;
    const { customerID,
        getTasksByCustomerID,
        getCommentsByCustomerID
    } = useContext(CustomerAccountContext);
    const [showMoreFiles, setShowMoreFiles] = useState(false);

    // how many files should be shown on all tasks & comments
    const countFile = 5;
    
    function checkIfFilesExist(files) {
        return (files !== null) ? files.slice(0, -1).split(';') : [];
    }

    function dateTimeFrench(params) {
        let dateTime = '';
        dateTime = `${(new Date(params*1000)).getDate().toString().padStart(2, '0')} ${getMonthFrench(params*1000)}
        ${(new Date(params*1000)).getFullYear().toString()}, ${(new Date(params*1000)).getHours().toString().padStart(2, '0')}:${(new Date(params*1000)).getMinutes().toString().padStart(2, '0')}`;

        return dateTime;
    }
    
    function editTaskComment(id, taskStatus, newTask, personName, dueTo) {
        fetch(`${URL_All.CommentTask}/${id}`, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({due_date: dueTo, comment: newTask, to_users: personName, task_status: taskStatus, updated_by: currentUser})
        })
        .then(() => {
            getTasksByCustomerID(customerID);
            getCommentsByCustomerID(customerID);
            setRefresh(refresh + 1);
        })
        .catch(error => console.error(error))
    };

    const cleancombinedText = DOMPurify.sanitize(comment.comment, { USE_PROFILES: { html: true } });

    return (<div className={classes.commentContent}>
        <div className={classes.fontBiggerReda}>
            <span className={classes.fontSmallerReda}>
                {findUserByID(comment.created_by)}: ({dateTimeFrench(comment.created_at)})&nbsp;
                <TaskCommentEdit
                    id={comment.id}
                    taskStatus={comment.task_status}
                    dueDate={comment.due_date}
                    editTaskComment={editTaskComment}
                    byCustomerID={comment.tracking_nbr.length > 0 ? false : true}
                    setByCustomerID={setByCustomerID}
                    isTask={comment.is_task === 1 ? true : false}
                    toUsers={(comment.to_users !== null & comment.to_users !== 'undefined') ? comment.to_users : ''}
                    oldTask={comment.comment}
                />
                <CommentPopUpDelete
                    id={comment.id}
                    comment={comment.comment}
                    deleteComment={deleteComment}
                    byCustomerID={comment.tracking_nbr.length > 0 ? false : true}
                    setByCustomerID={setByCustomerID}
                    files={comment.files}
                />
            </span>
            <div className={classes.paragraphNewLine}>
                <div
                    className={classes.task}
                    dangerouslySetInnerHTML={{ __html: cleancombinedText}}
                />
            </div>
            <span className={classes.displayInitial}>
                {checkIfFilesExist(comment.files).slice(0, countFile).map((file, i) => (<span key={i}><Tooltip title={file} placement="top"><a rel="noopener noreferrer" className={classes.marginRight2} href={`${newadmindb}/basic/web/uploads/${comment.id}/${file}`} target="_blank">{file.length > 9 ? `${file.slice(0, 8)}...` : file}</a></Tooltip> </span>))}
                <LinkButton
                    className={checkIfFilesExist(comment.files).length > countFile ? classes.showFilesMoreLessButton : classes.displayNone}
                    component="button"
                    variant="body2"
                    onClick={() => setShowMoreFiles(!showMoreFiles)}
                >
                    {showMoreFiles ? 'Show Less' : 'Show More'}
                </LinkButton>
            </span>
            <div className={showMoreFiles ? classes.displayInlineBlock : classes.displayNone}>
                {checkIfFilesExist(comment.files).slice(countFile).map((file, i) => (<span key={i}><Tooltip title={file} placement="top"><a rel="noopener noreferrer" className={classes.marginRight2} href={`${newadmindb}/basic/web/uploads/${comment.id}/${file}`} target="_blank">{file}</a></Tooltip> </span>))}
            </div>
        </div><br />
        <Divider />                            
    </div>);
}

function CustomerAccountBody(props) {
    const classes = useStyles();
    const { token } = useToken();
    const { getTaskCommentCount,
        countCommentsTasks,
        setIssuesSearchInput,
        setCASelectedShipment,
        setRefresh,
        refresh,
        setCALoadAll,
        caLoadAll,
    } = props;
    const { users,
        setMySearch,
        stopSearchCustomers,
        customerInfo,
        customerInfoLoading,
        customer,
        // customerLoading,
        customerID,
        statusValue,
        getCustomerByID,
        usersUserID,
        shipmentDetails,
        setShipmentDetails,
        // state,
        // setState,
        selectedTrackingID,
        setSelectedTrackingID,
        tasksCustomer,
        countTasksCustomer,
        commentsCustomer,
        getCommentsByCustomerID,
        getTasksByCustomerID,
        getCustomerShipmentByID,
    } = useContext(CustomerAccountContext);
    const scrollToInvoice = useRef(null);
    const [searchTracking, setSearchTracking] = useState('');
    const [selectedTrackingNbr, setSelectedTrackingNbr] = useState('');
    const [forPostingTrackingNbr, setPostingTrackingNbr] = useState('');
    const [tooltipTitleCustomer, setTooltipTitleCustomer] = useState(false); // comment
    const [tooltipTitleCustomer2, setTooltipTitleCustomer2] = useState(false); // task
    const [tooltipTitleRabais, setTooltipTitleRabais] = useState(false);
    const [byCustomerID, setByCustomerID] = useState(false);
    const [copyTitle, setCopyTitle] = useState(false);
    const [openInvoice, setOpenInvoice] = useState(false);
    const [openInvoice2, setOpenInvoice2] = useState(false);

    const username = localStorage.getItem('username');
    if(!username) logOut();
    const currentUser = users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0] === undefined ? 0 : users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0].id;

    // const handleSwitch = (event) => {
    //     setState({ ...state, [event.target.name]: event.target.checked });
    // };

    const trackingNumberCount = customerInfo.results ? customerInfo.results.filter(f => (usersUserID === -1 ? true : (f._user === usersUserID))).filter(sh => (sh.tracking[0].track.toLowerCase().includes(searchTracking.toLowerCase()) || Object.values(sh).toString().toLowerCase().includes(searchTracking.toLowerCase()) || Object.values(sh.delivery_address).toString().toLowerCase().includes(searchTracking.toLowerCase()) || Object.values(sh.ikompar_invoice).toString().toLowerCase().includes(searchTracking.toLowerCase()) || Object.values(sh.pickup_address).toString().toLowerCase().includes(searchTracking.toLowerCase()) || Object.values(sh.pickup_request).toString().toLowerCase().includes(searchTracking.toLowerCase()))).length : 0;

    function selectedTracking(args) {
        setSelectedTrackingNbr(args.replace(/<br\/>/g, "\n"));
        setPostingTrackingNbr(args);
    }

    function findUserByID(params) {
        let person = '';
        person = users.filter(filt => (filt.id === params))[0] !== undefined && users.filter(filt => (filt.id === params))[0].username;
        return person;
    }

    function clearAll() {
        setShipmentDetails({});
        setSelectedTrackingID(null);
        setSelectedTrackingNbr('');
    }

    // Get Comments & Tasks Count
    useEffect(() => {
        getTaskCommentCount();
    }, [getTaskCommentCount]);

    useEffect(() => {
        let mounted = true;
        if(mounted) {
            setShipmentDetails({});
            setSelectedTrackingID(null);
            setSelectedTrackingNbr('');
        }

        return () => mounted = false;
    }, [ setSearchTracking, setShipmentDetails,
        setSelectedTrackingID,
        setSelectedTrackingNbr, usersUserID ]
    );

    // Convert from "09-04-2021" to 09 avril 2021
    function dateOnlyFrench2(params) {
        let arrDate = params.split('-');
        let dateOnly = '';
        dateOnly = `${arrDate[0]} ${getMonthFrench(arrDate.reverse().join('-'))}
        ${arrDate[0]}`;

        return dateOnly;
    }
    
    const optionsAxios = {
        headers: { 'enctype': 'multipart/form-data' },
        onUploadProgress: ProgressEvent => {
            console.log(Math.round(ProgressEvent.loaded / ProgressEvent.total * 100))
        }
    };

    const postCommentTask = (comment, file, toUsers, dueDate, isTask) => {
        const dueDateConvert = Math.trunc(new Date(dueDate).getTime()/1000);
        const fd = new FormData();
        if(file && file.length > 0){
            for (let fN=0; fN<file.length; fN++) {
                fd.append(`file-${fN+1}`, file[fN]);
            }
        }
        fd.append('shipment_id', byCustomerID ? null : selectedTrackingID);
        fd.append('customer_id', customerID);
        fd.append('comment', comment);
        fd.append('created_by', currentUser);
        fd.append('to_users', toUsers);
        fd.append('due_date', dueDateConvert);
        fd.append('tracking_nbr', byCustomerID ? '' : forPostingTrackingNbr);
        fd.append('is_task', isTask);
        axios.post(URL_All.CommentTask, fd, optionsAxios)
          .then(() => {
                getTasksByCustomerID(customerID);
                getCommentsByCustomerID(customerID);
                setRefresh(refresh + 1);
            });
    }

    function deleteComment(id) {
        fetch(`${URL_All.CommentTask}/${id}`, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({is_actif: 0})
        })
        .then(() => {
            getTasksByCustomerID(customerID);
            getCommentsByCustomerID(customerID);
            setRefresh(refresh + 1);
        })
        .catch(error => console.error(error))
    };

    function postAdjustment(params) {
        if(!token) return logOut();
      
        fetch(URL_All.adjustments + '/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'authorization': `JWT ${token}`
            },
            body: JSON.stringify(params)
        })
            .then(response => {
                if (response.status === 403) {
                  return logOut();
                }

                getCustomerByID(customerID);
            })
            .catch(err => console.error(err))
    }

    function addAdjustment(transporter, adjustment) {
        const newAdjustment = { transporter: transporter, adjustment: `-${adjustment}`, customer: customerID };
        
        return postAdjustment(newAdjustment);
    }
    
    function editAdjustment(id, transporter, adjustment) {
        if(!token) return logOut();
      
        fetch(`${URL_All.adjustments}/${id}/`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'authorization': `JWT ${token}`
            },
            body: JSON.stringify({ id: id, transporter: transporter, customer: customerID, adjustment: `-${adjustment}` })
        })
            .then(response => {
                if (response.status === 403) {
                  return logOut();
                }

                getCustomerByID(customerID);
            })
            .catch(err => console.error(err))
    }

    function deleteAdjustment(params) {
        if(!token) return logOut();
      
        fetch(`${URL_All.adjustments}/${params}`, {
        method: 'DELETE',
        headers: {
            'authorization': `JWT ${token}`
        }
        })
            .then(response => {
                if (response.status === 403) {
                  return logOut();
                }

                getCustomerByID(customerID);
            })
            .catch(error => console.error(error))
    }
    
    // Error tooltip, adding comment by customerID
    const handleTooltipCloseCustomer = () => {
        setTooltipTitleCustomer(false);
    };
    
    // Error tooltip, adding task by customerID
    const handleTooltipCloseCustomer2 = () => {
        setTooltipTitleCustomer2(false);
    };
    
    const handleTooltipCloseRabais = () => {
        setTooltipTitleRabais(false);
    };

    function ifHaveTrackingNumber(params) {
        return (<span className={classes.ifHaveTrackingNumber}>
            <Button
                className={classes.btnTrackingNumbers}
                component={Link}
                to={`/archive?search=${params.split('<')[0]}`}
                onClick={() => setMySearch(params.replace(/<br\/>/g, "\n"))}
            >
                {params.replace(/<br\/>/g, "\n")}
            </Button>
            <ClickAwayListener
                onClickAway={handleCopyTitle}
            >
                <Tooltip
                    title="Copié"
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleCopyTitle}
                    open={copyTitle}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    <Button
                        className={classes.colorGray}
                        onClick={() => {
                            navigator.clipboard.writeText(params.replace(/<br\/>/g, " "));
                            setCopyTitle(true);
                    }}>
                        copie
                    </Button>
                </Tooltip>
            </ClickAwayListener>
        </span>);
    }

    function handleCopyTitle() {
        setCopyTitle(false);
    }

    const paymentMethod = (!shipmentDetails.ikompar_invoice) ? null
        : (shipmentDetails.ikompar_invoice.payment_method === 'CreditCard') ? <CreditCardIcon />
        : (shipmentDetails.ikompar_invoice.payment_method === 'Invoice') ? <InvoiceIcon />
        : ((shipmentDetails.ikompar_invoice.payment_method === 'Postfinance card') || (shipmentDetails.ikompar_invoice.payment_method === 'Postfinance e-finance') || (shipmentDetails.ikompar_invoice.payment_method === 'PF Pay')) ? <Avatar
            variant="square"
            alt={!shipmentDetails.ikompar_invoice ? 'Icon' : shipmentDetails.ikompar_invoice.payment_method}
            src={postFinancePng}
        />
        : (shipmentDetails.ikompar_invoice.payment_method === 'Twint') ? <Avatar
            variant="square"
            alt={!shipmentDetails.ikompar_invoice ? 'Icon' : shipmentDetails.ikompar_invoice.payment_method}
            src={twintPng}
    /> : null;
    const transporter_amount = shipmentDetails.ikompar_invoice && Number.parseFloat(shipmentDetails.ikompar_invoice.transporter_amount).toFixed(2);
    const shipment_amount = shipmentDetails.ikompar_invoice && Number.parseFloat(shipmentDetails.ikompar_invoice.shipment_amount).toFixed(2);
    const insurance_amount = shipmentDetails.ikompar_invoice && Number.parseFloat(shipmentDetails.ikompar_invoice.insurance_amount).toFixed(2);
    const vat_amount = shipmentDetails.ikompar_invoice && Number.parseFloat(shipmentDetails.ikompar_invoice.vat_amount).toFixed(2);
    
    const paymentMethodM = ((transporter_amount && shipment_amount) ? Number.parseFloat((1 - transporter_amount / shipment_amount) * 100).toFixed(2) : null);

    // tracking number: 390131888991
    
    const isErrorStyle = shipment => {
        if(statusValue[shipment.id] !== undefined) {
            if(statusValue[shipment.id].type_issue > 0) {
                return true;
            }

            return false;
        }
        
        return false;
    }

    const tableCellText = params => {
        return (
            <React.Fragment>
                <TableCell>
                    <p className={`${classes.marginZero} ${classes.deliveryDate}`}>{params.pickup_date}</p>
                </TableCell>
                <TableCell className={classes.deliveryAddress}>
                    <p className={classes.marginZero}>{params.delivery_address ? params.delivery_country_name : '-'}</p>
                </TableCell>
                <TableCell>
                    <p className={classes.trackingNumber}><strong>{params.tracking[0].track.replace(/<br\/>/g, "\n")}</strong></p>
                </TableCell>                
            </React.Fragment>
        );
    };
    
    function issueButton(shipment) {
        return (
            <React.Fragment>
                <Button
                    className={classes.issue}
                    variant="outlined"
                    color="secondary"
                    onClick={() => setIssuesSearchInput(shipment.tracking[0].track.split('<br')[0])}
                    component={Link}
                    to={'/issues'}
                >
                    CAS LITIGIEUX
                </Button>
                <br />
            </React.Fragment>
        );
    };

    const handleInvoiceTooltipClose = () => {
        setOpenInvoice(false);
    };

    const handleInvoiceTooltipOpen = () => {
        setOpenInvoice(true);
    };

    const handleInvoiceTooltipClose2 = () => {
        setOpenInvoice2(false);
    };

    const handleInvoiceTooltipOpen2 = () => {
        setOpenInvoice2(true);
    };

    function afterThirtyDays() {
        const newDate = new Date();
        const todayUTC = newDate.toUTCString();
        const timestampUTC = Math.floor(new Date(todayUTC).getTime() / 1000);

        const invoceUTC = shipmentDetails.ikompar_invoice ? convertDateToTimestamp(shipmentDetails.ikompar_invoice.date) : 0;

        const differentDays = Math.floor((timestampUTC - invoceUTC) / 86400);

        if(differentDays < 30 && differentDays > 0) {
            return 30 - differentDays;
        } else {
            return 0;
        }
    }
    
    const handleLoadAll = () => {
        setCALoadAll(true);
        getCustomerShipmentByID(customerID, true);
    };

    const pickupAddressInfo = (shipmentDetails.pickup_address ? <div>
        <span><strong>Street number:</strong> {shipmentDetails.pickup_address.street_number}</span><br />
        <span><strong>Address line 1:</strong> {shipmentDetails.pickup_address.street_line_1}</span><br />
        <span><strong>Address line 2:</strong> {shipmentDetails.pickup_address.street_line_2}</span><br />
        <span><strong>Zip code:</strong> {shipmentDetails.pickup_address.zip_code}</span><br />
        <span><strong>City:</strong> {shipmentDetails.pickup_address.city}</span><br />
        <span><strong>State:</strong> {shipmentDetails.pickup_address.state}</span><br />
        <span><strong>Country:</strong> {shipmentDetails.pickup_country_name}</span><br />
        <span><strong>Country Code:</strong> {shipmentDetails.pickup_address.country}</span>
    </div> : null);

    const deliveryAddressInfo = (shipmentDetails.delivery_address ? <div>
        <span><strong>Street number:</strong> {shipmentDetails.delivery_address.street_number}</span><br />
        <span><strong>Address line 1:</strong> {shipmentDetails.delivery_address.street_line_1}</span><br />
        <span><strong>Address line 2:</strong> {shipmentDetails.delivery_address.street_line_2}</span><br />
        <span><strong>Zip code:</strong> {shipmentDetails.delivery_address.zip_code}</span><br />
        <span><strong>City:</strong> {shipmentDetails.delivery_address.city}</span><br />
        <span><strong>State:</strong> {shipmentDetails.delivery_address.state}</span><br />
        <span><strong>Country:</strong> {shipmentDetails.delivery_country_name}</span><br />
        <span><strong>Country Code:</strong> {shipmentDetails.delivery_address.country}</span>
    </div> : null);

    return (<div className={classes.root}>
        <Grid container spacing={1}>
            <Grid item xs={4} container>
                <Grid item xs={12} className={classes.borderGrid}>
                    <Table size="small" className={`${classes.tableCA} ${(customerID !== -1) ? classes.tableCAgreen : ''}`}>
                        <colgroup span="1" style={{ width: "32%" }} />
                        <colgroup span="1" style={{ width: "17%" }} />
                        <colgroup span="1" style={{ width: "17%" }} />
                        <colgroup span="1" style={{ width: "17%" }} />
                        <colgroup span="1" style={{ width: "17%" }} />
                        <TableBody>
                            <TableRow>
                                <TableCell />
                                <TableCell><b>CA</b></TableCell>
                                <TableCell><b>MB</b></TableCell>
                                <TableCell style={{ wordWrap: 'break-word' }}><b>Shipments</b></TableCell>
                                <TableCell><b>%</b></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Aujourd’hui</TableCell>
                                <TableCell>{numberThousands(customer.total_turnover_current_day)}</TableCell>
                                <TableCell>{numberThousands(customer.total_profit_current_day)}</TableCell>
                                <TableCell>{numberThousands(customer.total_shipments_current_day)}</TableCell>
                                <TableCell>{(customer.total_turnover_current_day && customer.total_turnover_current_day !== 0 && customer.total_profit_current_day) ? numberThousands((customer.total_profit_current_day / customer.total_turnover_current_day * 100).toFixed(2)) : '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Cette semaine</TableCell>
                                <TableCell>{numberThousands(customer.total_turnover_current_week)}</TableCell>
                                <TableCell>{numberThousands(customer.total_profit_current_week)}</TableCell>
                                <TableCell>{numberThousands(customer.total_shipments_current_week)}</TableCell>
                                <TableCell>{(customer.total_turnover_current_week && customer.total_turnover_current_week !== 0 && customer.total_profit_current_week) ? numberThousands((customer.total_profit_current_week / customer.total_turnover_current_week * 100).toFixed(2)) : '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ce mois</TableCell>
                                <TableCell>{numberThousands(customer.total_turnover_current_month)}</TableCell>
                                <TableCell>{numberThousands(customer.total_profit_current_month)}</TableCell>
                                <TableCell>{numberThousands(customer.total_shipments_current_month)}</TableCell>
                                <TableCell>{(customer.total_turnover_current_month && customer.total_turnover_current_month !== 0 && customer.total_profit_current_month) ? numberThousands((customer.total_profit_current_month / customer.total_turnover_current_month * 100).toFixed(2)) : '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Cette année</TableCell>
                                <TableCell>{numberThousands(customer.total_turnover_current_year)}</TableCell>
                                <TableCell>{numberThousands(customer.total_profit_current_year)}</TableCell>
                                <TableCell>{numberThousands(customer.total_shipments_current_year)}</TableCell>
                                <TableCell>{(customer.total_turnover_current_year && customer.total_turnover_current_year !== 0 && customer.total_profit_current_year) ? numberThousands((customer.total_profit_current_year / customer.total_turnover_current_year * 100).toFixed(2)) : '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Début</TableCell>
                                <TableCell>{numberThousands(customer.total_turnover)}</TableCell>
                                <TableCell>{numberThousands(customer.total_profit)}</TableCell>
                                <TableCell>{numberThousands(customer.valid_shipments_count)}</TableCell>
                                <TableCell>{(customer.total_turnover && customer.total_turnover !== 0 && customer.total_profit) ? numberThousands((customer.total_profit / customer.total_turnover * 100).toFixed(2)) : '-'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <div className={`${classes.borderGrid} ${classes.gridRabais}`}>
                        <Table size="small" className={classes.tableRabais}>
                            <colgroup span="1" style={{ width: "54%" }} />
                            <colgroup span="1" style={{ width: "30%" }} />
                            <colgroup span="1" style={{ width: "8%" }} />
                            <colgroup span="1" style={{ width: "8%" }} />
                            <TableBody className={classes.borderBottom}>
                                <TableRow>
                                    <TableCell className={classes.rightBorderNone} colSpan={3}><b>Rabais (%)</b></TableCell>
                                    <TableCell className={classes.leftBorderNone} colSpan={1}>
                                        {(customerID === -1 ? (<ClickAwayListener onClickAway={handleTooltipCloseRabais}>
                                            <Tooltip
                                                title={`Please select Company`}
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                placement="top"
                                                onClose={handleTooltipCloseRabais}
                                                open={tooltipTitleRabais}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                            >
                                                <IconButton className="buttonPaddingZero" onClick={() => setTooltipTitleRabais(true)}>
                                                    <AddCircleOutlineIcon className={classes.btnRabaisAdd} />
                                                </IconButton>
                                            </Tooltip>
                                        </ClickAwayListener>) : (<AdjustmentFormAdd addAdjustment={addAdjustment} />))}
                                    </TableCell>
                                </TableRow>
                                {customer.adjustments ? customer.adjustments.map(adj => (
                                    <TableRow key={adj.id}>
                                        <TableCell className={classes.alignLeft}>{adj.transporter_name}</TableCell>
                                        <TableCell>{adj.adjustment * -100}</TableCell>
                                        <TableCell>
                                            <AdjustmentFormEdit
                                                editAdjustment={editAdjustment}
                                                adjustmentID={adj.id}
                                                adjustmentCompanyID={adj.transporter}
                                                adjustmentAmount={adj.adjustment * -100}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <AdjustmentPopUpDelete
                                                adjustmentID={adj.id}
                                                deleteAdjustment={deleteAdjustment}
                                                adjustmentCompanyName={adj.transporter_name}
                                                adjustmentAmount={adj.adjustment * -100}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )) : null}
                            </TableBody>
                        </Table>
                    </div>

                    <div className={classes.taskCustomerTitle}>
                        <p className={(customerID !== -1) ? classes.selectedCustomer : classes.selectedCustomerNot}><b>Tâches:</b></p>
                        {(customerID !== -1) ? <TaskForm
                            postCommentTask={postCommentTask}
                            byCustomerID={true}
                            setByCustomerID={setByCustomerID}
                        />
                        : (<ClickAwayListener onClickAway={handleTooltipCloseCustomer2}>
                            <Tooltip
                                title={`Please select Company`}
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                placement="top"
                                onClose={handleTooltipCloseCustomer2}
                                open={tooltipTitleCustomer2}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                            >
                                <IconButton onClick={() => setTooltipTitleCustomer2(true)}>
                                    <AddCircleOutlineIcon className="task-add" />
                                </IconButton>
                            </Tooltip>
                        </ClickAwayListener>)}
                    </div>
                    
                    {Object.values(customer).length > 0 && (<div>
                        <p><b>{(customer.company_name === null) ? customer.users[0].user.username
                            : customer.company_name.trim() === '' ? customer.users[0].user.username
                            : customer.company_name}</b><br />
                            Tâches à faire ({countUndoneTasks(countTasksCustomer, customerID.toString())})
                        </p>
                        <div className={classes.commentTask}>
                            {tasksCustomer.map(task => (
                                <DisplayTask
                                    task={task}
                                    currentUser={currentUser}
                                    byCustomerID={byCustomerID}
                                    selectedTrackingNbr={selectedTrackingNbr}
                                    ifCompanyTask={true}
                                    deleteComment={deleteComment}
                                    findUserByID={findUserByID}
                                    setByCustomerID={setByCustomerID}
                                    ifHaveTrackingNumber={ifHaveTrackingNumber}
                                    setRefresh={setRefresh}
                                    refresh={refresh}
                                    key={task.id}
                                />
                            ))}
                        </div>
                    </div>)}
                    
                    <Divider />
                    
                    <div className={classes.commentCustomerTitle}><p className={(customerID !== -1) ? classes.selectedCustomer : classes.selectedCustomerNot}><b>Commentaires client:</b>&nbsp;</p>
                    {(customerID !== -1) ? <CommentForm
                        postCommentTask={postCommentTask}
                        customerAccount={true}
                        byCustomerID={true}
                        setByCustomerID={setByCustomerID}
                    /> : (<ClickAwayListener onClickAway={handleTooltipCloseCustomer}>
                        <Tooltip
                            title={`Please select Company`}
                            PopperProps={{
                                disablePortal: true,
                            }}
                            placement="top"
                            onClose={handleTooltipCloseCustomer}
                            open={tooltipTitleCustomer}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                        >
                            <IconButton onClick={() => setTooltipTitleCustomer(true)}>
                                <AddCircleOutlineIcon className="comment-add" />
                            </IconButton>
                        </Tooltip>
                    </ClickAwayListener>)}</div>
                    
                    {Object.values(customer).length > 0 && (<div>
                        <p><b>{(customer.company_name === null) ? customer.users[0].user.username
                            : customer.company_name.trim() === '' ? customer.users[0].user.username
                        : customer.company_name}</b><br /></p>

                        <div className={classes.commentTask}>
                            {commentsCustomer.map(comment => (
                                <DisplayComment
                                    comment={comment}
                                    currentUser={currentUser}
                                    byCustomerID={byCustomerID}
                                    selectedTrackingNbr={selectedTrackingNbr}
                                    ifCompanyComment={true}
                                    deleteComment={deleteComment}
                                    findUserByID={findUserByID}
                                    setByCustomerID={setByCustomerID}
                                    ifHaveTrackingNumber={ifHaveTrackingNumber}
                                    setRefresh={setRefresh}
                                    refresh={refresh}
                                    key={comment.id}
                                />
                            ))}
                        </div>
                    </div>)}

                    <div className={classes.marginBottom} />
                </Grid>
            </Grid>

            <Grid item xs={8} container>
                <Grid item xs={5} className={classes.borderGridEnvois}>
                    <p className={classes.fontBigger}><b>Envois ({trackingNumberCount})</b></p>
                    <Divider />
                    <form noValidate autoComplete="off">
                        <TextField 
                            size="small" 
                            id="outlined-basic" 
                            label="Entrée quelque chose" 
                            variant="outlined"
                            color="primary"
                            className={classes.search}
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    ev.preventDefault();
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchTracking.length > 0 ? (<IconButton onClick={() => {
                                            setSearchTracking('');
                                            clearAll();
                                        }}>
                                            <HighlightOffIcon size="small" />
                                        </IconButton>) : ''}
                                    </InputAdornment>
                                ),
                                }}
                            value={searchTracking}
                            onChange={e => {
                                clearAll();
                                setSearchTracking(e.target.value);
                            }}
                        />
                    </form>

                    <Divider />
                    
                    <Table className={(customerInfoLoading & !stopSearchCustomers) ? '' : classes.tableEnvois}>
                        <colgroup span="1" style={{ width: "25%" }} />
                        <colgroup span="1" style={{ width: "25%" }} />
                        <colgroup span="1" style={{ width: "50%" }} />
                        <TableBody className={classes.tableBodyEnvois}>
                            {customerInfo.results && customerInfo.results.filter(f => (usersUserID === -1 ? true : (f._user === usersUserID))).filter(sh => (sh.tracking[0].track.toLowerCase().includes(searchTracking.toLowerCase()) || Object.values(sh).toString().toLowerCase().includes(searchTracking.toLowerCase()) || Object.values(sh.delivery_address).toString().toLowerCase().includes(searchTracking.toLowerCase()) || Object.values(sh.ikompar_invoice).toString().toLowerCase().includes(searchTracking.toLowerCase()) || Object.values(sh.pickup_address).toString().toLowerCase().includes(searchTracking.toLowerCase()) || Object.values(sh.pickup_request).toString().toLowerCase().includes(searchTracking.toLowerCase()))).map(shipment => <TableRow
                                key={shipment.id}
                                className={`${isErrorStyle(shipment) ? classes.colorRedTableRow : classes.envoiceRows} ${(shipment.id === shipmentDetails.id) ? classes.selected : ''}`}
                                color={'secondary'}
                                onClick={() => {
                                    setShipmentDetails(shipment);
                                    setCASelectedShipment(shipment);
                                    selectedTracking(shipment.tracking[0].track);
                                    setSelectedTrackingID(shipment.id);
                                }}>
                                    {tableCellText(shipment)}
                            </TableRow>)}

                            {(customerInfoLoading & !stopSearchCustomers) ? <TableRow><TableCell colSpan={3}><b>Loading...</b></TableCell></TableRow> : null}
                            {(!caLoadAll & customerInfo.count > 10) ? <TableRow className={`${classes.loadAll} loadAll`}>
                                <TableCell colSpan={3}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleLoadAll}
                                    >
                                        Load All ({customerInfo.count ? customerInfo.count : ''})
                                    </Button>
                                </TableCell>
                            </TableRow>: null}
                        </TableBody>
                    </Table>

                </Grid>

                <Grid item xs={7} className={classes.borderGridResumeDeLenvoi}>
                    <Grid container>
                        <Grid item xs={7}>
                            <div className={classes.fontBiggerResumeDeLenvoi} ref={scrollToInvoice}>
                                <b>Résumé de l’envoi</b>
                                {afterThirtyDays() ? (
                                    <ClickAwayListener onClickAway={handleInvoiceTooltipClose}>
                                        <Tooltip
                                            PopperProps={{
                                            disablePortal: true,
                                            }}
                                            onClose={handleInvoiceTooltipClose}
                                            open={openInvoice}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            leaveTouchDelay={3000}
                                            enterTouchDelay={50}
                                            enterNextDelay={0}
                                            arrow
                                            placement="top"
                                            title={`${afterThirtyDays()} days left`}
                                        >
                                            <GetAppIcon onClick={handleInvoiceTooltipOpen} />
                                        </Tooltip>
                                    </ClickAwayListener>
                                )
                                : (
                                    <a
                                        rel="noopener noreferrer"
                                        href={shipmentDetails.ikompar_invoice && `${URL_All.downloadInvoiceByID}${parseFloat(shipmentDetails.id)}`}
                                        target={shipmentDetails.ikompar_invoice && "_blank"}
                                    >
                                        <GetAppIcon />
                                    </a>
                                )}
                                <br />
                                Inv. N°: {shipmentDetails.ikompar_invoice && shipmentDetails.ikompar_invoice.number}
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <CorrespondencePopUp
                                parentId={Object.keys(shipmentDetails).length ? shipmentDetails.id : 0}
                                goToTab={0}
                                headerTop={<HeaderTop />}
                                isShipment={true}
                                customer_id={customerID}
                                tracking_nbr={Object.keys(shipmentDetails).length ? shipmentDetails.tracking[0].track : ''}
                                isRequest={false}
                                countCommentsAndTasks={countCommentsTasks}
                                disablePopUp={!(Object.keys(shipmentDetails).length)}
                                isChatPage={false}
                                email={Object.keys(shipmentDetails).length ? shipmentDetails.user : ''}
                            />
                        </Grid>
                    </Grid>
                    <Divider className={classes.dividerInvoice} />
                    <Grid container className={classes.invoiceDownloads}>
                        <Grid item xs={3} container>
                            <Grid item xs={12}>
                                <ReceiptIcon fontSize="large" style={{ color: '#333' }} />
                                <a
                                    rel="noopener noreferrer"
                                    href={shipmentDetails.id && `${URL_All.downloadProforma}${parseFloat(shipmentDetails.id)}`}
                                    target={shipmentDetails.id && "_blank"}
                                >
                                    <GetAppOutlinedIcon className={classes.verticalCenterIcon} />
                                </a>
                            </Grid>
                            <Grid item xs={12}>Client invoice</Grid>
                        </Grid>
                        <Grid item xs={3} container>
                            <Grid item xs={12}>
                                <InvoiceIcon fontSize="large" />
                                {afterThirtyDays() ? (
                                    <ClickAwayListener onClickAway={handleInvoiceTooltipClose2}>
                                        <Tooltip
                                            PopperProps={{
                                            disablePortal: true,
                                            }}
                                            onClose={handleInvoiceTooltipClose2}
                                            open={openInvoice2}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            leaveTouchDelay={3000}
                                            enterTouchDelay={50}
                                            arrow
                                            placement="top"
                                            title={`${afterThirtyDays()} days left`}
                                        >
                                            <GetAppOutlinedIcon onClick={handleInvoiceTooltipOpen2} className={classes.verticalCenterIcon} />
                                        </Tooltip>
                                    </ClickAwayListener>
                                )
                                : (
                                    <a
                                        rel="noopener noreferrer"
                                        href={shipmentDetails.ikompar_invoice && `${URL_All.downloadInvoiceByID}${parseFloat(shipmentDetails.id)}`}
                                        target={shipmentDetails.ikompar_invoice && "_blank"}
                                    >
                                        <GetAppOutlinedIcon className={classes.verticalCenterIcon} />
                                    </a>
                                )}
                            </Grid>
                            <Grid item xs={12}>Facture</Grid>
                        </Grid>
                        <Grid item xs={3} container>
                            <Grid item xs={12}>
                                <ProformaAWBIcon className={classes.proformaAWBIcon} fontSize="large" />
                                <a
                                    rel="noopener noreferrer"
                                    href={shipmentDetails.ikompar_invoice && `${URL_All.downloadAwbShipID}${parseFloat(shipmentDetails.id)}`}
                                    target={shipmentDetails.ikompar_invoice && "_blank"}
                                >
                                    <GetAppOutlinedIcon className={classes.verticalCenterIcon} />
                                </a>
                            </Grid>
                            <Grid item xs={12}>Proforma<br />AWB</Grid>
                        </Grid>
                        <Grid item xs={3} container>
                            <Grid item xs={12}>
                                <PreuveDeLivraisonIcon fontSize="large" /><GetAppOutlinedIcon className={classes.verticalCenterIcon} />
                            </Grid>
                            <Grid item xs={12}>Preuve de livraison</Grid>
                        </Grid>
                    </Grid>
                    <div className={classes.marginLeftContent}>
                        {(shipmentDetails.tracking && isErrorStyle(shipmentDetails)) ? issueButton(shipmentDetails) : null}
                        <div className={classes.trackingNumberDiv}><b>Upload numbers:</b> {shipmentDetails.ikompar_invoice && shipmentDetails.ikompar_invoice.upload_numbers}</div><br />
                        <div className={classes.trackingNumberDiv}><b>N° suivi:</b> {shipmentDetails.tracking && ifHaveTrackingNumber(shipmentDetails.tracking[0].track)}</div>
                        <p className={classes.marginTopZero}><b>Date pickup prévue:</b> {shipmentDetails.pickup_date && `${shipmentDetails.pickup_date.slice(-2)} ${getMonthFrench(shipmentDetails.pickup_date)}
                        ${shipmentDetails.pickup_date.slice(0, 4)}`} {shipmentDetails.pickup_request && `${shipmentDetails.pickup_request.ready_time.slice(0, 5)}-${shipmentDetails.pickup_request.close_time.slice(0, 5)}`}<br /><b>Date pickup effective:</b><br/><b>Numéro de pickup:</b> {shipmentDetails.pickup_request && shipmentDetails.pickup_request.confirmation_number}</p>

                        <div className="marginLeftDiv"><b>Adresse d’expédition:</b><br />{shipmentDetails.pickup_address && shipmentDetails.pickup_address.company_name}<br />{shipmentDetails.pickup_address && `${shipmentDetails.pickup_address.contact_title}. ${shipmentDetails.pickup_address.contact_first_name} ${shipmentDetails.pickup_address.contact_last_name}`}<br />{pickupAddressInfo}{shipmentDetails.pickup_address && shipmentDetails.pickup_address.contact_phone}<br /><a href={`mailto:${shipmentDetails.pickup_address ? shipmentDetails.pickup_address.contact_email : ''}`}>{shipmentDetails.pickup_address && shipmentDetails.pickup_address.contact_email}</a></div><br />

                        <div className="marginLeftDiv"><b>Adresse de destination:</b><br />{shipmentDetails.delivery_address && shipmentDetails.delivery_address.company_name}<br />{shipmentDetails.delivery_address && `${shipmentDetails.delivery_address.contact_title}. ${shipmentDetails.delivery_address.contact_first_name} ${shipmentDetails.delivery_address.contact_last_name}`}<br />{deliveryAddressInfo}{shipmentDetails.delivery_address && shipmentDetails.delivery_address.contact_phone}<br /><a href={`mailto:${shipmentDetails.delivery_address ? shipmentDetails.delivery_address.contact_email : ''}`}>{shipmentDetails.delivery_address && shipmentDetails.delivery_address.contact_email}</a></div>
                        
                        <p><b>Date de livraison estimée:</b> {shipmentDetails._quote && dateOnlyFrench2(shipmentDetails._quote.delivery_date)}, {shipmentDetails._quote && shipmentDetails._quote.delivery_time}
                        <br /><b>Date de livraison effective:</b> 
                        </p>
                        
                        <p><b>Descriptions:</b> 
                        {shipmentDetails.package_group && shipmentDetails.package_group.goods.map((e, i) => (<span key={i}> {e.description}</span>))}
                        <br/><b>Référence:</b> {shipmentDetails.shipment_customer_reference}
                        <br /><b>{shipmentDetails && shipmentDetails.is_ddp ? 'DDP' : 'DAP'}</b></p>

                        <p>
                            <span className={classes.paymentMethod}><b>Payment Method:</b>&nbsp; {paymentMethod}</span>
                            <b>Transporter CHF:</b> {transporter_amount}
                            <br /><b>Shipment CHF:</b> {shipment_amount}
                            <br /><b>GM (%):</b> {paymentMethodM}
                            <br /><b>Insurance CHF:</b> {insurance_amount}
                            <br /><b>Vat CHF:</b> {vat_amount}
                        </p>
                        
                        <p><b>Prix de l’envoi:</b> {shipmentDetails.ikompar_invoice && Number.parseFloat(shipmentDetails.ikompar_invoice.total_amount).toFixed(2)} CHF<br /><b>Valeur de la marchandise:</b> {shipmentDetails.package_group && numberThousands(shipmentDetails.package_group.customs_total_value)} CHF<br /><b>Assurance:</b> <span className={shipmentDetails.is_insured ? classes.isInsuredTrue : null}>Oui</span> / <span className={shipmentDetails.is_insured ? null : classes.isInsuredFalse}>Non</span></p>
                        
                        <p><b>Dimensions:</b> {shipmentDetails.package_group && shipmentDetails.package_group.packages.map(dimenshions => <span key={dimenshions.id}>{dimenshions.length} cm(Lo) X {dimenshions.width} cm (La) X {dimenshions.height} cm (h) {dimenshions.weight} kg<br /></span>)}</p>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    </div>);
}

const mapStateToProps = state => {
    return {
        countCommentsTasks: state.countCommentsTasks,
        refresh: state.refresh,
        caLoadAll: state.caLoadAll,
    };
};

export default connect(mapStateToProps, {
    getTaskCommentCount,
    setIssuesSearchInput,
    setCASelectedShipment,
    setRefresh,
    setCALoadAll,
})(CustomerAccountBody);
