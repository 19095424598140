import { INVOICE_PACKAGE_RESPONSE } from '../actions/types';


const invoicePackageResponse = (state = [], action) => {
  switch(action.type) {
    case INVOICE_PACKAGE_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};


export default invoicePackageResponse;
