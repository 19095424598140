import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import IssueCard from './IssueCard';
import { getIssues,
  getTrackings,
  setIssuesLoading,
  loadingTrackings,
  setIssuesCount
} from '../actions';
import useToken from '../login/useToken';
import { issueTitle } from '../common/constantsFR';
import IssueBodyContext from '../contexts/IssueBodyContext';
import { URL_All } from '../common/Constants';


const useStyles = makeStyles((theme) => ({
  root: {
    // background: '#F7F7F7',
    minHeight: '70vh',
  },
  loading: {
    height: '70vh',
    [theme.breakpoints.up(1600)]: {
      height: '75vh',
    },
    backgroundColor: '#f7f7f7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  emptyBody: {
    height: '70vh',
    [theme.breakpoints.up(1600)]: {
      height: '75vh',
    },
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& .MuiTypography-root': {
      textAlign: 'center',
      fontStyle: 'italic',
      fontSize: '2rem',
      color: '#9e9b9b',
    },
  },
  notFound: {
    height: '70vh',
    [theme.breakpoints.up(1600)]: {
      height: '75vh',
    },
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& .MuiTypography-root': {
      textAlign: 'center',
      fontStyle: 'italic',
      fontSize: '2rem',
      color: '#f00',
    },
  },
  textNormal: {
    fontStyle: 'normal',
  },
}));

function IssuesBody(props) {
  const classes = useStyles();
  const { token } = useToken();
  const { issues,
    trackings,
    issuesSearchInput,
    issuesLoading,
    trackingsLoading,
    setIssuesLoading,
    getIssues,
    loadingTrackings,
    getTrackings,
    issuesPageRefresh,
    transportFilters,
    setIssuesCount
  } = props;

  useEffect(() => {
    setIssuesLoading(true);
    getIssues();
    loadingTrackings(true);
    getTrackings(token);
  }, [issuesPageRefresh, token]);

  // Send back all tracking ids
  useEffect(() => {
    let allTrackingIDs = trackings.map(i => i.id);

    if(allTrackingIDs.length > 0) {
      fetch(URL_All.sendIDsBack, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({data: allTrackingIDs.join(',')})
      })
        .catch(err => console.error(err))
    }
  }, [trackings]);

  const emptyBody = (
    <Paper className={classes.emptyBody}>
      <Typography>No any issue <span className={classes.textNormal}>🥳</span></Typography>
    </Paper>
  );

  const loading = (
    <Paper className={classes.loading}>
      <CircularProgress color="secondary" size="5em" />
      <Typography>Loading...</Typography>
    </Paper>
  );

  const notFound = (
    <Paper className={classes.notFound}>
      {/* <Typography><u>{queryTrackingNumber}</u> is not found.</Typography> */}
      <Typography><u>{issuesSearchInput}</u> is not found.</Typography>
    </Paper>
  );

  const handleIssuesCount = count => {
    setIssuesCount(count);
  };

  const FILTER_MAP = useMemo(() => {
    return {
      FilterButtons: item => false
      || (transportFilters.isClickedFedEx && item.quote_transporter === 'FedEx')
      || (transportFilters.isClickedUPS && item.quote_transporter === 'UPS')
      || (transportFilters.isClickedTNT && item.quote_transporter === 'TNT')
      || (transportFilters.isClickedAramex && item.quote_transporter === 'ARAMEX')
      || (transportFilters.isClickedSpring && item.quote_transporter.includes('SPRING'))      
      || (transportFilters.isClickedTransnat && item.quote_transporter === 'TRANSNAT')
      || (transportFilters.isClickedFretcargo && item.quote_transporter === 'FRETCARGO')
    };
  }, [transportFilters]);

  const renderHelper = () => {
    if(issuesLoading || trackingsLoading) {
      return loading;
    } else {
      const issuesShipmentIds = Object.keys(issues);
      const filterTrackings = trackings.filter(FILTER_MAP['FilterButtons']).filter(item => item.has_issue).filter(f => (Object.values(f).toString().toLowerCase().includes(issuesSearchInput.toLowerCase()) || Object.values(f.pickup_request).toString().toLowerCase().includes(issuesSearchInput.toLowerCase()))).filter(ifIssue => issuesShipmentIds.includes(ifIssue.id.toString()));
      
      if(filterTrackings.length) {
        handleIssuesCount(filterTrackings.length);
        
        return (
          <React.Fragment>
            {filterTrackings.map(tracking => (
              <IssueBodyContext.Provider
                key={tracking.id}
                value={{ tracking }}>
                <IssueCard
                  title={issueTitle[issues[tracking.id.toString()].type_issue]}
                  issue={issues[tracking.id.toString()]}
                />
              </IssueBodyContext.Provider>
            ))}
          </React.Fragment>
        );
      } else if (issuesSearchInput.length) {
        return notFound;
      } else {
        return emptyBody;
      }
    }
  };

  return (
    <div className={classes.root}>
      {renderHelper()}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    issues: state.issues,
    trackings: state.trackings,
    issuesSearchInput: state.issuesSearchInput,
    issuesLoading: state.issuesLoading,
    trackingsLoading: state.trackingsLoading,
    issuesPageRefresh: state.issuesPageRefresh,
    transportFilters: state.issuesTransportFilters
  };
};

export default connect(mapStateToProps,
  { 
    getIssues,
    getTrackings,
    setIssuesLoading,
    loadingTrackings,
    setIssuesCount
  }
)(IssuesBody);
