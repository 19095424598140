import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
      width: 500,
    },
    title: {
        maxWidth: 420,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(0),
      color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    dialogActions: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        justifyContent: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    grid: {
        alignSelf: 'flex-end',
    },
    gridTextField: {
        alignSelf: 'flex-end',
    },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography className={classes.title} variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

export default function PurchasePriceEdit(props) {
    const classes = useStyles();
    const { item, handlePurchasePriceEdit } = props;
    const [open, setOpen] = useState(false);
    const [price, setPrice] = useState(item.price);

    function handleClickOpen() {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = event => {
        setPrice(event.target.value);
    };

    const handleSubmit = () => {
        handlePurchasePriceEdit(price);
        setOpen(false);
    };

    return(
    <div>
        <Tooltip title="Edit" placement="top">
            <IconButton
                className={classes.editButton}
                onClick={handleClickOpen}
            >
                <EditIcon fontSize="small" />
            </IconButton>
        </Tooltip>
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            aria-labelledby="form-dialog-title"
            className={classes.root}
        >
            <DialogTitle onClose={handleClose}>
                Modification envoi: {item.trackingNumber}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={3}>
                        <FormControl className={classes.formControl}>
                            <Typography>
                                Prix d’achat
                            </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item xs={7} className={classes.gridTextField}>
                        <TextField
                            className={classes.textField}
                            autoFocus
                            autoComplete="off"
                            margin="dense"
                            id="edit-input"
                            type="number"
                            fullWidth
                            value={price}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={2} />
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                >
                    Valider
                </Button>
            </DialogActions>
      </Dialog>
    </div>
    );
}
