import { CA_SELECTED_SHIPMENT } from '../actions/types';

const caSelectedShipment = (state = {}, action) => {
  switch(action.type) {
    case CA_SELECTED_SHIPMENT:
      return action.payload;
    default:
      return state;
  }
};

export default caSelectedShipment;
