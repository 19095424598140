import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import { setCRFilterButtons,
  setCRSearch,
  setCRDate,
  setContactRequest
} from '../actions';


const useStyles = makeStyles((theme) => ({
  secondLine: {
    marginBottom: theme.spacing(.5),
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  search: {
    width: '90%',
    "& .MuiIconButton-root": {
      padding: 0,
      color: "gray",
    },
  },
  gridDatePicker: {
    display: 'flex',
    placeContent: 'center',
    marginLeft: theme.spacing(4),
  },
  formDatePicker: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textFieldDatePicker: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  marginLeft16: {
    marginLeft: theme.spacing(2),
  },
  filterByDates: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-end',
  },
}));

function ContactRequestHeader(props) {
  const classes = useStyles();
  const { crFilterButtons,
    setCRFilterButtons,
    crSearch,
    setCRSearch,
    crDate,
    setCRDate,
    setContactRequest,
    contactRequestCount,
  } = props;

  const handleFilters = props => () => {
    if(props === 'all') {
      return setCRFilterButtons({ ...crFilterButtons,
        general: true,
        technicalProblem: true,
        discount: true,
        pallet: true,
        estimate: true,
        untreated: true,
        inProgress: true,
        ended: true,
      });
    }

    return setCRFilterButtons({ ...crFilterButtons, [props]: !crFilterButtons[props] });
  };

  const handleSearch = event => {
    setCRSearch(event.target.value);
  };

  const handleDateFilter = () => {
    setContactRequest();
  };

  return(
    <div>
      <Grid container space={2}>
        <Grid item xs={12} md={12} lg={12} container>
          <Grid item xs={9}>
            <Button
              // disabled={!fetchDone}
              className={classes.buttonLowerCase}
              size="small"
              variant={crFilterButtons.general ? "contained" : "outlined"}
              color={crFilterButtons.general ? "secondary" : "default"}
              onClick={handleFilters('general')}
            >
              Général{contactRequestCount.general ? ` (${contactRequestCount.general})` : ''}
            </Button>
            <Button
              // disabled={!fetchDone}
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={crFilterButtons.technicalProblem ? "contained" : "outlined"}
              color={crFilterButtons.technicalProblem ? "secondary" : "default"}
              onClick={handleFilters('technicalProblem')}
            >
              Problème technique{contactRequestCount.technicalProblem ? ` (${contactRequestCount.technicalProblem})` : ''}
            </Button>
            <Button
              // disabled={!fetchDone}
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={crFilterButtons.discount ? "contained" : "outlined"}
              color={crFilterButtons.discount ? "secondary" : "default"}
              onClick={handleFilters('discount')}
            >
              Demande rabais volume{contactRequestCount.discount ? ` (${contactRequestCount.discount})` : ''}
            </Button>
            <Button
              // disabled={!fetchDone}
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={crFilterButtons.pallet ? "contained" : "outlined"}
              color={crFilterButtons.pallet ? "secondary" : "default"}
              onClick={handleFilters('pallet')}
            >
              Demande paiement par facture{contactRequestCount.pallet ? ` (${contactRequestCount.pallet})` : ''}
            </Button>
            <Button
              // disabled={!fetchDone}
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={crFilterButtons.estimate ? "contained" : "outlined"}
              color={crFilterButtons.estimate ? "secondary" : "default"}
              onClick={handleFilters('estimate')}
            >
              Devis{contactRequestCount.estimate ? ` (${contactRequestCount.estimate})` : ''}
            </Button>
            <Button
              // disabled={!fetchDone}
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant="outlined"
              onClick={handleFilters('all')}
            >
              Tout
            </Button>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={2}>
            <form noValidate autoComplete="off">
              <TextField 
                size="small" 
                id="outlined-basic" 
                label="Rechercher…" 
                variant="outlined" 
                className={classes.search}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    handleDateFilter();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                    {crSearch.length > 0 ? (<IconButton onClick={() => setCRSearch('')}>
                      <HighlightOffIcon size="small" />
                    </IconButton>) : ''}
                    </InputAdornment>
                  ),
                }}
                value={crSearch}
                onChange={handleSearch}
              />
            </form>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}
          className={classes.secondLine}
          container
          justifyContent="space-between"
        >
          <div className={classes.filterByDates}>
            <Button
              // disabled={!fetchDone}
              className={classes.buttonLowerCase}
              size="small"
              variant={crFilterButtons.untreated ? "contained" : "outlined"}
              color={crFilterButtons.untreated ? "secondary" : "default"}
              onClick={handleFilters('untreated')}
            >
              Non traités
            </Button>
            <Button
              // disabled={!fetchDone}
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={crFilterButtons.inProgress ? "contained" : "outlined"}
              color={crFilterButtons.inProgress ? "secondary" : "default"}
              onClick={handleFilters('inProgress')}
            >
              En cours
            </Button>
            <Button
              // disabled={!fetchDone}
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant={crFilterButtons.ended ? "contained" : "outlined"}
              color={crFilterButtons.ended ? "secondary" : "default"}
              onClick={handleFilters('ended')}
            >
              Terminé
            </Button>
            <div className={classes.gridDatePicker}>
              <form className={classes.formDatePicker} noValidate>
                <TextField
                  id="dateFrom"
                  className={classes.textFieldDatePicker}
                  label="Entre"
                  type="date"
                  value={crDate.from}
                  inputProps={{ max: crDate.to }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setCRDate({ ...crDate, from: e.target.value })}
                />
              </form>
              <form className={classes.formDatePicker} noValidate>
                <TextField
                  id="dateTo"
                  className={classes.textFieldDatePicker}
                  label="et"
                  type="date"
                  value={crDate.to}
                  inputProps={{ min: crDate.from }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setCRDate({ ...crDate, to: e.target.value })}
                />
              </form>
            </div>
            <Button
              className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
              size="small"
              variant="outlined"
              color="primary"
              // disabled={!crDate.from || !crDate.to || !fetchDone}
              disabled={!crDate.from || !crDate.to}
              onClick={handleDateFilter}
            >
              Valider
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    crFilterButtons: state.crFilterButtons,
    crSearch: state.crSearch,
    crDate: state.crDate,
    contactRequestCount: state.contactRequestCount,
  };
};

export default connect(mapStateToProps, {
  setCRFilterButtons,
  setCRSearch,
  setCRDate,
  setContactRequest,
})(ContactRequestHeader);
