import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteIcon from '@material-ui/icons/Delete';
import { logOut } from '../common/Frame';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
    root: {
      margin: 'auto',
    },
    dialogTitle: {
        marginTop: theme.spacing(1),
    },
    formGroup: {
        margin: 'auto',
        padding: theme.spacing(2),
        width: 'fit-content',
    },
    binIconBtn: {
      padding: theme.spacing(0),
    },
    binBtn: {
      color: 'gray',
      padding: theme.spacing(0.25),
      paddingBottom: theme.spacing(0),
      '&:hover': {
          color: 'red',
      },
    },
    formControl: {
      margin: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(1, 1, 0, 0),
    },
    dialogActions: {
      placeContent: 'center',
    },
    dialogContent: {
      textAlign: 'center',
      marginTop: theme.spacing(1),
      '& p': {
        marginBottom: theme.spacing(0),
        color: '#000',
        fontSize: '1.3em',
      },
      fontStyle: 'italic',
    },
    percentage: {
      fontStyle: 'normal',
      fontWeight: '700',
    },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function AdjustmentPopUpDelete(props) {
  const classes = useStyles();
  const { adjustmentID,
    deleteAdjustment,
    adjustmentCompanyName,
    adjustmentAmount
  } = props;
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  function handleClose() {
    setOpen(false);
  };

  const username = localStorage.getItem('username');
  if(!username) logOut();

  return (
    <div>
        <IconButton
          onClick={handleClickOpen}
          className={classes.binIconBtn}
          size="small"
        >
          <DeleteIcon className={classes.binBtn} />
        </IconButton>
        <Dialog fullWidth={fullWidth} maxWidth="sm" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className={classes.root}>
              <DialogTitle id="customized-dialog-title" className={classes.dialogTitle} onClose={() => handleClose()}>
                Voulez-vous supprimer ceci, {username}?
              </DialogTitle>
              <Divider />
              <DialogContent className={classes.dialogContent}>
                <DialogContentText>
                  {`${adjustmentCompanyName}:`}&nbsp;
                  <span className={classes.percentage}>
                    {`${adjustmentAmount}%`}
                  </span>
                </DialogContentText>
                {/* <DialogContentText id="alert-dialog-description">
                  {`${adjustmentCompanyName}: ${adjustmentAmount}%`}
                </DialogContentText> */}
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    deleteAdjustment(adjustmentID);
                    handleClose();
                  }}
                >
                    Valider
                </Button>
              </DialogActions>
            </div>
        </Dialog>
    </div>
  );
}
