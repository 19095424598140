import { DRAWER_OPEN } from '../actions/types';

const drawerOpen = (state = false, action) => {
  switch(action.type) {
    case DRAWER_OPEN:
      return action.payload;
    default:
      return state;
  }
};

export default drawerOpen;
