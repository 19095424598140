import React, { useState, useEffect } from 'react';
import { URL_All } from '../common/Constants';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import StatusForm from './StatusForm';
import StatusEditingForm from './StatusEditingForm';
import { dateTimeFrench, logOut } from '../common/Frame';
import StatusPopUpDelete from './StatusPopUpDelete';


const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        whiteSpace: 'nowrap',
    },
    tableRow: {
        '& .MuiTableCell-head': {
            fontWeight: 700,
        },
    },
    stickyHead: {
        position: 'sticky',
        top: theme.spacing(0),
        zIndex: 1,
        background: '#fafafa',
        padding: theme.spacing(1),
        '& .borderNone': {
            border: 0,
        },
    },
}));

function StatusListRow(prop) {
    const { status,
        currentUser,
        deleteStatus,
        setRefresh
    } = prop;
    const [userCreated,setUserCreated] = useState({});
    const [userUpdated,setUserUpdated] = useState({});

    useEffect(() => {
        fetch(`${URL_All.Users}/${status.created_by}`)
            .then(res => res.json())
            .then(json => setUserCreated(json))
            .catch(err => console.error(err))
    }, [status.created_by]);
    
    useEffect(() => {
        fetch(`${URL_All.Users}/${status.updated_by}`)
            .then(res => res.json())
            .then(json => setUserUpdated(json))
            .catch(err => console.error(err))
    }, [status]);

    function editStatus(name) {
        fetch(`${URL_All.Statuslists}/${status.id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name: name, updated_by: currentUser})
        })
            .then(res => setRefresh(oldKey => oldKey +1))
            .catch(err => console.error(err))
    }

    return(<React.Fragment>
        <TableRow>
            <TableCell>{status.name}</TableCell>
            <TableCell>{userCreated.username}</TableCell>
            <TableCell>{dateTimeFrench(status.created_at)}</TableCell>
            <TableCell>{status.updated_at === status.created_at ? '' : userUpdated.username}</TableCell>
            <TableCell>{status.updated_at === status.created_at ? '' : (dateTimeFrench(status.updated_at))}</TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={6}>
                        <StatusEditingForm
                            statusName={status.name}
                            editStatus={editStatus}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {status.id !== 1 ? <StatusPopUpDelete
                            statusName={status.name}
                            deleteStatus={deleteStatus}
                            statusID={status.id}
                        /> : null}
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    </React.Fragment>);
}

function StatusManagement(props) {
    const { users } = props;
    const classes = useStyles();
    const [statusList,setStatusList] = useState([]);
    const [refresh,setRefresh] = useState(0);

    const username = localStorage.getItem('username');
    if(!username) logOut();
    const currentUser = users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0] === undefined ? 0 : users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0].id;

    useEffect(() => {
        fetch(URL_All.Statuslists)
            .then(res => res.json())
            .then(json => setStatusList(json))
            .catch(err => console.error(err))
    }, [refresh]);

    function addStatus(name) {
        fetch(URL_All.Statuslists, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name: name, created_by: currentUser})
        })
            .then(res => setRefresh(oldKey => oldKey + 1))
            .catch(err => console.error(err))
    }

    function deleteStatus(id) {
        fetch(`${URL_All.Statuslists}/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => setRefresh(oldKey => oldKey + 1))
            .catch(err => console.error(err))
    }
    
    return(
        <div>
            <div className={classes.stickyHead}>
                <TableContainer>
                    <Table>
                        <colgroup>
                            <col span="1" style={{width: "42%"}} />
                            <col span="1" style={{width: "12%"}} />
                            <col span="1" style={{width: "12%"}} />
                            <col span="1" style={{width: "12%"}} />
                            <col span="1" style={{width: "12%"}} />
                            <col span="1" style={{width: "10%"}} />
                        </colgroup>
                        <TableHead>
                            <TableRow colSpan={7}>
                                <TableCell className='borderNone'>
                                    <StatusForm addStatus={addStatus} />
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.tableRow}>
                                <TableCell>Name</TableCell>
                                <TableCell>Created by</TableCell>
                                <TableCell>Created at</TableCell>
                                <TableCell>Updated by</TableCell>
                                <TableCell>Updated at</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </div>
            <TableContainer className={classes.root}>
                <Table>
                    <colgroup>
                        <col span="1" style={{width: "42%"}} />
                        <col span="1" style={{width: "12%"}} />
                        <col span="1" style={{width: "12%"}} />
                        <col span="1" style={{width: "12%"}} />
                        <col span="1" style={{width: "12%"}} />
                        <col span="1" style={{width: "10%"}} />
                    </colgroup>
                    <TableBody>
                        {statusList.map(status => {
                            return <StatusListRow
                                key={status.id}
                                status={status}
                                currentUser={currentUser}
                                deleteStatus={deleteStatus}
                                setRefresh={setRefresh}
                            />;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default StatusManagement;
