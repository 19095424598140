import { GET_TASK_COMMENT } from '../actions/types';

const taskComment = (state = [], action) => {
    switch (action.type) {
        case GET_TASK_COMMENT:
            return action.payload;
        default:
            return state;
    }
};

export default taskComment;
