import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import ActivityFlowShowSelected from './ActivityFlowShowSelected';


const useStyles = makeStyles((theme) => ({
  cardContent: {
    border: '1px solid #ddd',
    overflow: 'hidden',
    paddingTop: 0,
    
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  contentHeader: {
    justifyContent: 'center',
    alignItems: 'center',

    '& .MuiGrid-item:first-child': {
      textAlign: 'start',
    },
    '& .MuiGrid-item:last-child': {
      textAlign: 'end',
    },
  },
  contentBody: {
    overflowY: 'scroll',
    height: 250,
  },
  addEventButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableBody: {
    '& .MuiTableRow-root': {
      '&:nth-child(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },

    '& .MuiTableCell-body': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      
      '&:nth-child(2)': {
        textAlign: 'center',
      },
      '&:last-child': {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',

        '& span:last-child': {
          display: 'flex',
          color: '#555',
        },
      },
    },
  },
  subtitleCenter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textTransformNone: {
    textTransform: 'none',
  },
  notTreatedPast: {
    lineHeight: '1',

    [theme.breakpoints.up(1586)]: {
      lineHeight: '1.75',
    }, 
  },
  notTreatedFuture: {
    lineHeight: '2',
    
    [theme.breakpoints.up(1586)]: {
      lineHeight: '1.75',
    },
  },
}));

const someData = [
  {
    invoice: 'Paiement par facture',
    customer: 'Qoqa.ch',
    time: '08:42',
  },
  {
    invoice: 'Northrop Grumman SA',
    customer: 'CIGP SA',
    time: '10:17',
  },
  {
    invoice: 'Contact request',
    customer: 'pierre.bellemard@gmail.com',
    time: '11:22',
  },
  {
    invoice: 'Déclaration de sinistre',
    customer: 'Kolly Gallery',
    time: '11:41',
  },
  {
    invoice: 'Quote sans booking',
    customer: 'Isabelle.ret@hotmail.com',
    time: '13:01',
  },
];

function ActivityFlow() {
  const classes = useStyles();

  return (
    <Card className="dashboard-box">
      <Grid container>
        <Grid item xs={12}>
          <Typography
            className="box-title"
            gutterBottom
            variant="h5"
            component="h2"
          >
            Flux d’activité
          </Typography>
        </Grid>
      </Grid>
      <CardContent className={classes.cardContent}>
        {/* <CardActions className={classes.contentHeader}>
          <IconButton size="small">
            <ChevronLeftIcon />
          </IconButton>
          <Typography
          >
            Aujourd’hui
          </Typography>
          <IconButton size="small">
            <ChevronRightIcon />
          </IconButton>
        </CardActions> */}

        <CardActions className={classes.contentHeader}>
          <Grid container>
            <Grid item xs={3}>
              <Button
                className={`${classes.textTransformNone} ${classes.notTreatedPast}`}
                variant="outlined"
                color="primary"
              >
                10 non traitées
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.subtitleCenter}>
              <IconButton size="small">
                <ChevronLeftIcon />
              </IconButton>
              <Typography
              >
                Aujourd’hui
              </Typography>
              <IconButton size="small">
                <ChevronRightIcon />
              </IconButton>
            </Grid>
            <Grid item xs={3}>
              <Button
                className={`${classes.textTransformNone} ${classes.notTreatedFuture}`}
                variant="outlined"
                color="primary"
              >
                8 à faire
              </Button>
            </Grid>
          </Grid>
        </CardActions>
        <div className={classes.contentBody}>
          <Table>
            <colgroup>
              <col span="1" style={{width: "35%"}} />
              <col span="1" style={{width: "35%"}} />
              <col span="1" style={{width: "30%"}} />
            </colgroup>
            <TableBody className={classes.tableBody}>
              {someData.map((item, i) => (
                // <TableRow key={i}>
                //   <TableCell>{item.invoice}</TableCell>
                //   <TableCell>{item.customer}</TableCell>
                //   <TableCell><span>{item.time}</span><span><RefreshIcon fontSize="small" /></span></TableCell>
                // </TableRow>
                <ActivityFlowShowSelected key={i} item={item} />
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
}

export default ActivityFlow;
