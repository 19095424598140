import { INVOICE_CURRENT } from '../actions/types';


const invoiceCurrent = (state = {}, action) => {
  switch(action.type) {
    case INVOICE_CURRENT:
      return action.payload;
    default:
      return state;
  }
};


export default invoiceCurrent;
