import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { convertDateToWithDash } from '../common/functions';
import PriceCorrection from './PriceCorrection';
import ItemForm from './ItemForm';
import { setInvoicesBilling,
  setInvoicePackageResponse,
  getInvoiceByShipId,
  setInvoiceByShipId,
  postCreateInvoice,
  setInvoiceCreateSuccess,
} from '../actions';
import { invoicesExpedismartPath } from '../common/paths';
import { numberTwoDecimal } from '../common/functions';
import useToken from '../login/useToken';


const useStyles = makeStyles((theme) => ({
  overcharging: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    background: '#F7F7F7',
    minHeight: '60vh',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: 4,
  },
  headerMain: {
    color: 'gray',
  },
  headerInvoice: {
    marginBottom: theme.spacing(2),
    display: 'inline-flex',
    gap: 5,
  },
  gridDatePicker: {
    marginTop: theme.spacing(2),
  },
  textFieldDatePicker: {
    width: 200,
    textAlign: 'center',
  },
  dropDownMenu: {
    width: 300,
  },
  gridInvoices: {
    marginTop: theme.spacing(4),
  },
  itemGroup: {
    marginBottom: theme.spacing(4),
  },
  form: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  description: {
    width: 400,
  },
  addItem: {
    display: 'flex',
  },
  addInvoiceTitle: {
    marginBottom: theme.spacing(1),
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  gridActions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(2),
  },
  priceCorrection: {
    padding: theme.spacing(2),
    border: '1px solid #e5d2d2',
    width: 530,
    
    [theme.breakpoints.up(1700)]: {
      width: 640,
    },
  },
  priceCorrectionTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  correctionAction: {
    textAlign: 'center',
  },
  deleteLater: {
    marginBottom: theme.spacing(5),
  },
  fontItalic: {
    fontStyle: 'italic',
  },
}));

const TypographyBody = withStyles((theme) => ({
  root: {
    fontSize: '1em',
  },
}))(Typography);

export const createOverbillingKeys = [
  'article',
  'description',
  'amount',
  'transporter_amount',
];

function Overcharging(props) {
  const { token } = useToken();
  const classes = useStyles();
  const history = useHistory();
  const { setInvoicesBilling,
    invoicesBilling,
    invoiceOverchargingLoading,
    invoiceCurrent,
    invoicePackageResponse,
    setInvoicePackageResponse,
    invoiceByShipId,
    invoiceShipIdLoading,
    getInvoiceByShipId,
    setInvoiceByShipId,
    invoiceCreateLoading,
    postCreateInvoice,
    invoiceCreateSuccess,
    setInvoiceCreateSuccess,
  } = props;
  const newDate = new Date();
  const dateWithDash = convertDateToWithDash(newDate);
  const valuesKeys = ['date', 'discountDesc', 'discount'];
  const [values, setValues] = useState({
    [valuesKeys[0]]: dateWithDash,

    [valuesKeys[1]]: '',
    [valuesKeys[2]]: '',
  });
  const [prices, setPrices] = useState({
    sellingCalc: '-,-',
    sellingDiff: '-,-',

    purchaseCalc: '-,-',
    purchaseDiff: '-,-',
  });
  const [itemsArr, setItemsArr] = useState([1]);
  const defaultCreateOverbilling = {
    [createOverbillingKeys[0]]: 1,
    [createOverbillingKeys[1]]: '',
    [createOverbillingKeys[2]]: '',
    [createOverbillingKeys[3]]: '',

  };
  const [createOverbilling, setCreateOverbilling] = useState({
    [itemsArr[0]]: defaultCreateOverbilling,
  });

  useEffect(() => {
    if(!invoicesBilling.tax) {
      history.push(invoicesExpedismartPath);
    }

    return () => {
      setInvoicesBilling({ overcharging: false, tax: false });
    };
  }, [setInvoicesBilling]);

  useEffect(() => {
    getInvoiceByShipId(token, invoiceCurrent.shipment_id);

    setInvoicePackageResponse([]);

    return () => {
      setInvoiceByShipId({});
    };
  }, [token, invoiceCurrent]);

  const handleValues = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  const handleAddItem = () => {
    const newNum = itemsArr.length ? (itemsArr.slice(-1)[0] + 1) : 1;

    setItemsArr([...itemsArr, newNum]);
    setCreateOverbilling({
      ...createOverbilling,
      [newNum]: defaultCreateOverbilling,
    });
  };

  const handleRemove = ind => () => {
    const removeFromArr = itemsArr.filter(flt => (flt !== ind));
    setItemsArr(removeFromArr);
    
    delete createOverbilling[ind];
  };

  const handleCancel = () => {    
    history.push(invoicesExpedismartPath);
  };

  useEffect(() => {    
    if(invoicePackageResponse.length) {
      const sellingCalc = Number(invoicePackageResponse[0].ikompar_quote[0].amount);
      const sellingDiff = Number(invoicePackageResponse[0].ikompar_quote[0].amount) - Number(invoiceCurrent.shipment_amount);

      const purchaseCalc = Number(invoicePackageResponse[0].transporter_quote[0].amount_ikompar);
      const purchaseDiff = Number(invoicePackageResponse[0].transporter_quote[0].amount_ikompar) - Number(invoiceCurrent.transporter_amount);

      setPrices({
        sellingCalc: numberTwoDecimal(sellingCalc),
        sellingDiff: numberTwoDecimal(sellingDiff),
    
        purchaseCalc: numberTwoDecimal(purchaseCalc),
        purchaseDiff: numberTwoDecimal(purchaseDiff),
      });

      if(purchaseDiff < 0 || sellingDiff < 0) {
        setCreateOverbilling({ ...createOverbilling,
          1: { ...createOverbilling[1],
          [createOverbillingKeys[3]]: purchaseDiff.toString(),
          [createOverbillingKeys[2]]: sellingDiff.toString()
        }});
      }

    } else {
      setPrices({
        sellingCalc: '-,-',
        sellingDiff: '-,-',
    
        purchaseCalc: '-,-',
        purchaseDiff: '-,-',
      });
    }
  }, [invoicePackageResponse, invoiceCurrent]);

  const handleChangeCreate = arrIndex => (e) => {    
    setCreateOverbilling({ ...createOverbilling,
      [arrIndex]: {
        ...createOverbilling[arrIndex],
        [e.target.name]: (e.target.name === createOverbillingKeys[0]) ? Number(e.target.value) : e.target.value
    }});
  };

  const handleCreateInvoice = () => {
    const discountAmount = values[valuesKeys[2]].length ? Number(values[valuesKeys[2]]) : 0;
    const articles = Object.keys(createOverbilling).length ? Object.values(createOverbilling) : [];

    const body = {
      type: 'S',
      date: values[valuesKeys[0]],
      discount_description: values[valuesKeys[1]],
      discount_amount: discountAmount,
      
      articles: articles,
    };

    postCreateInvoice(token, invoiceCurrent.shipment_id, body);
  };

  useEffect(() => {
    if(invoiceCreateSuccess) {
      handleCancel();
      setInvoiceCreateSuccess(false);
    }
  }, [invoiceCreateSuccess]);

  return (
    <div className={classes.overcharging}>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={7} container>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.headerMain}>Facturation surfacturation</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.headerInvoice}>
              Invoice n° : {invoiceShipIdLoading ? <div className={classes.fontItalic}><CircularProgress size="1em" /> Loading...</div> : <strong>S-{invoiceCurrent._uuid}-….</strong>}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TypographyBody>
              Client (n°client): <strong>{invoiceByShipId.customer_nr}</strong>
            </TypographyBody>
            <TypographyBody>
              Entreprise: <strong>{invoiceByShipId.customer_company}</strong>
            </TypographyBody>
            <TypographyBody>
              Prénom Nom: <strong>{invoiceByShipId.delivery_contact_name}</strong>
            </TypographyBody>
            <TypographyBody>
              Adresse: <strong>{invoiceByShipId.customer_address}</strong>
            </TypographyBody>
            <TypographyBody>
              NPA Localité: <strong>{invoiceByShipId.delivery_zip_code}</strong>
            </TypographyBody>
            <TypographyBody>
              Nom d’utilisateur: <strong>{invoiceByShipId.customer_username}</strong>
            </TypographyBody>
          </Grid>
          <Grid item xs={6}>
            <TypographyBody>
              N° tracking: <strong>{invoiceByShipId.tracking_number}</strong>
            </TypographyBody>
            <TypographyBody>
              Service utilisé: <strong>{invoiceByShipId.transporter_name} ({invoiceByShipId.transporter_name_service})</strong>
            </TypographyBody>
            <TypographyBody>
              Entreprise destinataire: <strong>{invoiceByShipId.delivery_company}</strong>
            </TypographyBody>
            <TypographyBody>
              NPA Localité: <strong>{invoiceByShipId.delivery_zip_code}</strong>
            </TypographyBody>
            <TypographyBody>
              Pays: <strong>{invoiceByShipId.customer_country}</strong>
            </TypographyBody>
          </Grid>
          <Grid item xs={12} className={classes.gridDatePicker}>
            <TextField
              className={classes.textFieldDatePicker}
              type="date"
              name={valuesKeys[0]}
              InputLabelProps={{
                shrink: true,
              }}
              value={values[valuesKeys[0]]}
              onChange={handleValues}
            />
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <div className={classes.priceCorrection}>
            <Typography
              className={classes.priceCorrectionTitle}
              variant="h5"
            >
              Corrections du tarifs
            </Typography>
            <Grid container spacing={2} className={classes.gridCorrection}>
              <Grid item xs={4}>
                <Typography>Prix de vente original:</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{numberTwoDecimal(invoiceCurrent.shipment_amount)}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>Prix d'achat original:</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{numberTwoDecimal(invoiceCurrent.transporter_amount)}</Typography>
              </Grid>
            </Grid>
            
            <Grid container spacing={2} className={classes.gridCorrection}>
              <Grid item xs={4}>
                <Typography>Prix de vente audité:</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{prices.sellingCalc}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>Prix d'achat audité:</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{prices.purchaseCalc}</Typography>
              </Grid>
            </Grid>
            
            <Grid container spacing={2} className={classes.gridCorrection}>
              <Grid item xs={4}>
                <Typography>Difflérence prix de vente:</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{prices.sellingDiff}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>Différence prix d'achat:</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{prices.purchaseDiff}</Typography>
              </Grid>
            </Grid>

            <div className={classes.correctionAction}>
              <PriceCorrection />
            </div>
          </div>

        </Grid>
        
        <Grid item xs={1} />

        <Grid item xs={12} className={classes.gridInvoices}>
          <div className={classes.itemGroup}>
            {itemsArr.map((_, i) => <ItemForm
              key={_}
              orderNumber={_}
              index={i}
              selectOptions={Object.keys(invoiceByShipId).length ? invoiceByShipId.articles_ref : []}
              handleChangeCreate={handleChangeCreate}
              selectValue={createOverbilling[_][createOverbillingKeys[0]]}
              inputValues={createOverbilling[_]}
              handleRemove={handleRemove}
            />)}

            <div className={classes.addItem}>
              <IconButton onClick={handleAddItem}>
                <AddCircleIcon />
              </IconButton>
              <p>Ajouter un article</p>
            </div>
          </div>

          <div className={classes.addInvoiceGroup}>
            <Typography className={classes.addInvoiceTitle}>Ajouter un rabais</Typography>
            <form className={classes.form} noValidate autoComplete="off">
              <TextField
                size="small"
                className={classes.description}
                variant="outlined"
                label="Description pour client"
                name={valuesKeys[1]}
                value={values[valuesKeys[1]]}
                onChange={handleValues}
              />

              <TextField
                size="small"
                variant="outlined"
                label="Rabais (Ex. : 32.20)"
                type="number"
                name={valuesKeys[2]}
                value={values[valuesKeys[2]]}
                onChange={handleValues}
              />
            </form>
          </div>
        </Grid>
        
        <Grid item xs={12} className={classes.gridActions}>
          <Button
            disabled={invoiceOverchargingLoading || invoiceShipIdLoading || invoiceCreateLoading || !Object.keys(invoiceByShipId).length}
            className={classes.buttonLowerCase}
            variant="contained"
            color="primary"
            onClick={handleCreateInvoice}
          >
            {(invoiceOverchargingLoading || invoiceShipIdLoading || invoiceCreateLoading) ? <CircularProgress size="2em" /> : 'Créer facture'}
          </Button>
          <Button
            disabled={invoiceOverchargingLoading || invoiceCreateLoading}
            className={classes.buttonLowerCase}
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
          >
            {(invoiceOverchargingLoading || invoiceCreateLoading) ? <CircularProgress color="secondary" size="2em" /> : 'Cancel and Go Back'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    invoicesBilling: state.invoicesBilling,
    invoiceOverchargingLoading: state.invoiceOverchargingLoading,
    invoiceCurrent: state.invoiceCurrent,
    invoicePackageResponse: state.invoicePackageResponse,
    invoiceByShipId: state.invoiceByShipId,
    invoiceShipIdLoading: state.invoiceShipIdLoading,
    invoiceCreateLoading: state.invoiceCreateLoading,
    invoiceCreateSuccess: state.invoiceCreateSuccess,
  };
};

export default connect(mapStateToProps, {
  setInvoicesBilling,
  setInvoicePackageResponse,
  getInvoiceByShipId,
  setInvoiceByShipId,
  postCreateInvoice,
  setInvoiceCreateSuccess,
})(Overcharging);
