import { INVOICES_DATE_ERROR } from '../actions/types';


const invoicesDateError = (state = false, action) => {
  switch(action.type) {
    case INVOICES_DATE_ERROR:
      return action.payload;
    default:
      return state;
  }
};


export default invoicesDateError;
